import { getTokenOrganizationId } from "@/utils/token";

export const MANAGER_APOLLO_CONTEXT = {
  headers: {
    "x-hasura-role": "manager",
  },
};

export const getOrganizationHeader = (rootGetters) => {
  const activeOrganizationId =
    rootGetters["accountOrganizations/activeOrganization"]?.id;
  return {
    "x-hasura-organization-id":
      activeOrganizationId || getTokenOrganizationId(),
  };
};
