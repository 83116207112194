// todo create default links pages for preview
// static
import L2qrWebsiteIcon from "@/assets/img/qr-types/website.svg?sprite";
import L2qrEnvelopeIcon from "@/assets/img/qr-types/envelope.svg?sprite";
import L2qrVcardIcon from "@/assets/img/qr-types/vcard.svg?sprite?sprite";
import L2qrTextIcon from "@/assets/img/qr-types/text.svg?sprite";
import L2qrChatWithDotsIcon from "@/assets/img/qr-types/chat-with-dots.svg?sprite";
import L2qrWifiIcon from "@/assets/img/qr-types/wi-fi.svg?sprite";

// dynamic
import L2qrSocMediaIcon from "@/assets/img/qr-types/social-media.svg?sprite";
import L2qrCouponsIcon from "@/assets/img/qr-types/coupons.svg?sprite";
import L2qrBusinessPageIcon from "@/assets/img/qr-types/business-page.svg?sprite";
import L2qrFeedbackIcon from "@/assets/img/qr-types/feedback.svg?sprite";
import L2qrRatingIcon from "@/assets/img/qr-types/rating.svg?sprite";
import L2qrVideoIcon from "@/assets/img/qr-types/video.svg?sprite";
import L2qrEventIcon from "@/assets/img/qr-types/event.svg?sprite";
import L2qrPdfIcon from "@/assets/img/qr-types/pdf.svg?sprite";
// import L2qrFacebookIcon from "@/assets/img/qr-types/facebook.svg?sprite";
import L2qrAppIcon from "@/assets/img/qr-types/app.svg?sprite";
// import L2qrMp3Icon from '@/assets/img/qr-types/mp3.svg?sprite'
import L2qrImagesIcon from "@/assets/img/qr-types/images.svg?sprite";
import L2qrMenuIcon from "@/assets/img/qr-types/menu.svg?sprite";
import L2qrInstagramIcon from "@/assets/img/qr-types/instagram.svg?sprite";
import { getFileUrl } from "@/utils/files";

export const staticQrCodes = [
  {
    name: "qr_manage:static_qr_formats:url:title",
    data_format: "URL",
    type: "static",
    description: "qr_manage:static_qr_formats:url:description",
    spriteIcon: L2qrWebsiteIcon,
    configsComponent: "qr-account-qr-configs-static-url",
    previewImage: getFileUrl("pages_previews/stat_website.png"),
  },
  {
    name: "qr_manage:static_qr_formats:vcard:title",
    data_format: "vcard",
    type: "static",
    description: "qr_manage:static_qr_formats:vcard:description",
    spriteIcon: L2qrVcardIcon,
    configsComponent: "qr-account-qr-configs-static-vcard",
    previewImage: getFileUrl("pages_previews/stat_vcard.png"),
  },
  {
    name: "qr_manage:static_qr_formats:email:title",
    data_format: "email",
    type: "static",
    description: "qr_manage:static_qr_formats:email:description",
    spriteIcon: L2qrEnvelopeIcon,
    configsComponent: "qr-account-qr-configs-static-email",
    previewImage: getFileUrl("pages_previews/stat_email.png"),
  },
  {
    name: "qr_manage:static_qr_formats:sms:title",
    data_format: "sms",
    type: "static",
    description: "qr_manage:static_qr_formats:sms:description",
    spriteIcon: L2qrChatWithDotsIcon,
    configsComponent: "qr-account-qr-configs-static-sms",
    previewImage: getFileUrl("pages_previews/stat_sms.png"),
  },

  {
    name: "qr_manage:static_qr_formats:text:title",
    data_format: "text",
    type: "static",
    description: "qr_manage:static_qr_formats:text:description",
    spriteIcon: L2qrTextIcon,
    configsComponent: "qr-account-qr-configs-static-text",
    previewImage: getFileUrl("pages_previews/stat_text.png"),
  },
  {
    name: "qr_manage:static_qr_formats:wifi:title",
    data_format: "wifi",
    type: "static",
    description: "qr_manage:static_qr_formats:wifi:description",
    spriteIcon: L2qrWifiIcon,
    configsComponent: "qr-account-qr-configs-static-wifi",
    previewImage: getFileUrl("pages_previews/stat_wifi.png"),
  },
];

export const creationQrCodeSteps = [
  "qr-type",
  "page-template",
  "page-configs",
  // "customize-qr"
];

export const updateQrCodeWalletPassSteps = [
  "page-configs",
  // "customize-qr"
];

export const dynamicQrCodeWebsite = {
  name: "qr_manage:dynamic_qr_formats:url:title",
  data_format: "URL",
  type: "dynamic",
  description: "qr_manage:dynamic_qr_formats:url:description",
  spriteIcon: L2qrWebsiteIcon,
  configsComponent: "qr-account-qr-configs-dynamic-url",
  previewImage: getFileUrl("pages_previews/dyn_website.png"),
};

export const dynamicQrCodes = [
  dynamicQrCodeWebsite,
  {
    name: "qr_manage:dynamic_qr_formats:menu:title",
    data_format: "menu",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:menu:description",
    spriteIcon: L2qrMenuIcon,
    configsComponent: "qr-account-qr-configs-dynamic-menu",
    previewImage: getFileUrl("pages_previews/dyn_menu.png"),
  },
  {
    // name: 'vCard Plus',
    name: "qr_manage:dynamic_qr_formats:vcard_plus:title",
    data_format: "vcard_plus",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:vcard_plus:description",
    spriteIcon: L2qrVcardIcon,
    configsComponent: "qr-account-qr-configs-dynamic-vcard-plus",
    previewImage: getFileUrl("pages_previews/dyn_vcard_plus.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:business_page:title",
    data_format: "business_page",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:business_page:description",
    spriteIcon: L2qrBusinessPageIcon,
    configsComponent: "qr-account-qr-configs-dynamic-business-page",
    previewImage: getFileUrl("pages_previews/dyn_business_page.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:soc_media:title",
    data_format: "soc_media",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:soc_media:description",
    spriteIcon: L2qrSocMediaIcon,
    configsComponent: "qr-account-qr-configs-dynamic-social-medial",
    previewImage: getFileUrl("pages_previews/dyn_soc_media.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:coupon:title",
    data_format: "coupons",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:coupon:description",
    spriteIcon: L2qrCouponsIcon,
    configsComponent: "qr-account-qr-configs-dynamic-coupons",
    previewImage: getFileUrl("pages_previews/dyn_coupons.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:feedback:title",
    data_format: "feedback",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:feedback:description",
    spriteIcon: L2qrFeedbackIcon,
    configsComponent: "qr-account-qr-configs-dynamic-feedback",
    previewImage: getFileUrl("pages_previews/dyn_feedback.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:rating:title",
    data_format: "rating",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:rating:description",
    spriteIcon: L2qrRatingIcon,
    configsComponent: "qr-account-qr-configs-dynamic-rating",
    previewImage: getFileUrl("pages_previews/dyn_rating.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:video:title",
    data_format: "video",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:video:description",
    spriteIcon: L2qrVideoIcon,
    configsComponent: "qr-account-qr-configs-dynamic-video",
    previewImage: getFileUrl("pages_previews/dyn_video.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:event:title",
    data_format: "event",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:event:description",
    spriteIcon: L2qrEventIcon,
    configsComponent: "qr-account-qr-configs-dynamic-event",
    previewImage: getFileUrl("pages_previews/dyn_event.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:pdf:title",
    data_format: "pdf",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:pdf:description",
    spriteIcon: L2qrPdfIcon,
    configsComponent: "qr-account-qr-configs-dynamic-pdf",
    previewImage: getFileUrl("pages_previews/dyn_pdf.png"),
  },
  // {
  //   name: 'Facebook',
  //   data_format: 'facebook',
  //   type: 'dynamic',
  //   description: 'Get more Likes for your page',
  //   spriteIcon: L2qrFacebookIcon,
  //   configsComponent: 'qr-account-qr-configs-dynamic-facebook',
  //   previewImage: getFileUrl('pages_previews/dyn_facebook.png'),
  // },
  {
    name: "qr_manage:dynamic_qr_formats:app:title",
    data_format: "app",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:app:description",
    spriteIcon: L2qrAppIcon,
    configsComponent: "qr-account-qr-configs-dynamic-app",
    previewImage: getFileUrl("pages_previews/dyn_app.png"),
  },
  // {
  //   name: 'MP3',
  //   data_format: 'mp3',
  //   type: 'dynamic',
  //   description: 'Play an audio file',
  //   spriteIcon: L2qrMp3Icon,
  //   configsComponent: 'qr-account-qr-configs-dynamic-mp3',
  //   previewImage: getFileUrl('pages_previews/dyn_mp3.png'),
  // },
  {
    name: "qr_manage:dynamic_qr_formats:images:title",
    data_format: "images",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:images:description",
    spriteIcon: L2qrImagesIcon,
    configsComponent: "qr-account-qr-configs-dynamic-images",
    previewImage: getFileUrl("pages_previews/dyn_images.png"),
  },
  {
    name: "qr_manage:dynamic_qr_formats:instagram:title",
    data_format: "instagram",
    type: "dynamic",
    description: "qr_manage:dynamic_qr_formats:instagram:description",
    spriteIcon: L2qrInstagramIcon,
    configsComponent: "qr-account-qr-configs-dynamic-instagram",
    previewImage: getFileUrl("pages_previews/dyn_instagram.png"),
  },
];

export const staticQrCodesComponents = {
  "qr-account-qr-configs-static-url": () =>
    import(
      /* webpackChunkName: "Static Url" */ "@/components/account/qr/configs/static/Url"
    ),
  "qr-account-qr-configs-static-vcard": () =>
    import(
      /* webpackChunkName: "Static Vcard" */ "@/components/account/qr/configs/static/Vcard"
    ),
  "qr-account-qr-configs-static-email": () =>
    import(
      /* webpackChunkName: "Static Email" */ "@/components/account/qr/configs/static/Email"
    ),
  "qr-account-qr-configs-static-sms": () =>
    import(
      /* webpackChunkName: "Static Sms" */ "@/components/account/qr/configs/static/Sms"
    ),
  "qr-account-qr-configs-static-text": () =>
    import(
      /* webpackChunkName: "Static Text" */ "@/components/account/qr/configs/static/Text"
    ),
  "qr-account-qr-configs-static-wifi": () =>
    import(
      /* webpackChunkName: "Static Wifi" */ "@/components/account/qr/configs/static/Wifi"
    ),
};
export const dynamicQrCodesComponents = {
  "qr-account-qr-configs-dynamic-url": () =>
    import(
      /* webpackChunkName: "Dynamic Url" */ "@/components/account/qr/configs/dynamic/Url"
    ),
  "qr-account-qr-configs-dynamic-menu": () =>
    import(
      /* webpackChunkName: "Dynamic Menu" */ "@/components/account/qr/configs/dynamic/Menu"
    ),
  "qr-account-qr-configs-dynamic-vcard-plus": () =>
    import(
      /* webpackChunkName: "Dynamic VcardPlus" */ "@/components/account/qr/configs/dynamic/VcardPlus"
    ),
  "qr-account-qr-configs-dynamic-business-page": () =>
    import(
      /* webpackChunkName: "Dynamic BusinessPage" */ "@/components/account/qr/configs/dynamic/BusinessPage"
    ),
  "qr-account-qr-configs-dynamic-social-medial": () =>
    import(
      /* webpackChunkName: "Dynamic SocialMedial" */ "@/components/account/qr/configs/dynamic/SocialMedial"
    ),
  "qr-account-qr-configs-dynamic-coupons": () =>
    import(
      /* webpackChunkName: "Dynamic Coupons" */ "@/components/account/qr/configs/dynamic/Coupons"
    ),
  "qr-account-qr-configs-dynamic-feedback": () =>
    import(
      /* webpackChunkName: "Dynamic Feedback" */ "@/components/account/qr/configs/dynamic/Feedback"
    ),
  "qr-account-qr-configs-dynamic-rating": () =>
    import(
      /* webpackChunkName: "Dynamic Rating" */ "@/components/account/qr/configs/dynamic/Rating"
    ),
  "qr-account-qr-configs-dynamic-video": () =>
    import(
      /* webpackChunkName: "Dynamic Video" */ "@/components/account/qr/configs/dynamic/Video"
    ),
  "qr-account-qr-configs-dynamic-event": () =>
    import(
      /* webpackChunkName: "Dynamic Event" */ "@/components/account/qr/configs/dynamic/Event"
    ),
  "qr-account-qr-configs-dynamic-pdf": () =>
    import(
      /* webpackChunkName: "Dynamic Pdf" */ "@/components/account/qr/configs/dynamic/Pdf"
    ),
  "qr-account-qr-configs-dynamic-facebook": () =>
    import(
      /* webpackChunkName: "Dynamic Facebook" */ "@/components/account/qr/configs/dynamic/Facebook"
    ),
  "qr-account-qr-configs-dynamic-app": () =>
    import(
      /* webpackChunkName: "Dynamic App" */ "@/components/account/qr/configs/dynamic/App"
    ),
  "qr-account-qr-configs-dynamic-mp3": () =>
    import(
      /* webpackChunkName: "Dynamic Mp3" */ "@/components/account/qr/configs/dynamic/Mp3"
    ),
  "qr-account-qr-configs-dynamic-images": () =>
    import(
      /* webpackChunkName: "Dynamic Images" */ "@/components/account/qr/configs/dynamic/Images"
    ),
  "qr-account-qr-configs-dynamic-instagram": () =>
    import(
      /* webpackChunkName: "Dynamic Instagram" */ "@/components/account/qr/configs/dynamic/Instagram"
    ),
};
