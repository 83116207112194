import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import {
  GET_CHARTS_DATA,
  GET_QR_CODE_SCANS_RATING,
  GET_QR_CODE_DETAILS,
} from "@/services/backoffice/dashboard";
import moment from "moment/moment";

const serializeChartData = (data) => {
  const users = data.users;
  const qrCodes = data.qr_codes;
  const scans = data.qr_code_scans;
  return { users, qrCodes, scans };
};

const serializeQrCodesScansRating = (data) => {
  return (
    data.qr_codes?.map((qr) => ({
      email: qr.account?.owner?.email,
      type: qr.data_format,
      scans: qr.scans_aggregate?.aggregate?.count,
      shortId: qr.key,
    })) ?? []
  );
};

const serializeChartDataFilter = (getters) => {
  return {
    created_at: {
      _gte: getters.filters.start,
      _lte: getters.filters.end,
    },
  };
};

const initState = () => ({
  filters: {
    start: moment().subtract(14, "days").format("MM-DD-YYYY"),
    end: moment().add(1, "day").format("MM-DD-YYYY"),
    periodType: "day",
  },
  users: [],
  qrCodes: [],
  scans: [],
  chartsDataLoading: false,
  chartsDataError: null,

  qrCodesScansRating: null,
  qrCodesScansRatingLoading: false,
  qrCodesScansRatingError: null,
  qrCodeDetails: null,
  qrCodeDetailsLoading: false,
  qrCodeDetailsError: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    filters: ({ filters }) => filters,
    users: ({ users }) => users,
    qrCodes: ({ qrCodes }) => qrCodes,
    scans: ({ scans }) => scans,
    chartsDataLoading: ({ chartsDataLoading }) => chartsDataLoading,
    chartsDataError: ({ chartsDataError }) => chartsDataError,
    qrCodesScansRating: ({ qrCodesScansRating }) => qrCodesScansRating,
    qrCodesScansRatingLoading: ({ qrCodesScansRatingLoading }) =>
      qrCodesScansRatingLoading,
    qrCodesScansRatingError: ({ qrCodesScansRatingError }) =>
      qrCodesScansRatingError,
    qrCodeDetails: ({ qrCodeDetails }) => qrCodeDetails,
    qrCodeDetailsLoading: ({ qrCodeDetailsLoading }) => qrCodeDetailsLoading,
    qrCodeDetailsError: ({ qrCodeDetailsError }) => qrCodeDetailsError,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    setFilters(state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
    getChartsDataStart(state) {
      state.users = [];
      state.qrCodes = [];
      state.scans = [];
      state.chartsDataLoading = true;
      state.chartsDataError = null;
    },
    getChartsDataFailure(state, error) {
      state.users = [];
      state.qrCodes = [];
      state.scans = [];
      state.chartsDataLoading = false;
      state.chartsDataError = error;
    },
    getChartsDataSuccess(state, payload) {
      state.users = payload.users ?? [];
      state.qrCodes = payload.qrCodes ?? [];
      state.scans = payload.scans ?? [];
      state.chartsDataLoading = false;
      state.chartsDataError = null;
    },
    // getQrCodesScansRating
    getQrCodesScansRatingStart(state) {
      state.qrCodesScansRating = [];
      state.qrCodesScansRatingLoading = true;
      state.qrCodesScansRatingError = null;
    },
    getQrCodesScansRatingFailure(state, error) {
      state.qrCodesScansRating = [];
      state.qrCodesScansRatingLoading = false;
      state.qrCodesScansRatingError = error;
    },
    getQrCodesScansRatingSuccess(state, payload) {
      state.qrCodesScansRating = payload;
      state.qrCodesScansRatingLoading = false;
      state.qrCodesScansRatingError = null;
    },
    // getQrCodesDetails
    getQrCodesDetailsStart(state) {
      state.qrCodeDetails = null;
      state.qrCodeDetailsLoading = true;
      state.qrCodeDetailsError = null;
    },
    getQrCodesDetailsFailure(state, error) {
      state.qrCodeDetails = null;
      state.qrCodeDetailsLoading = false;
      state.qrCodeDetailsError = error;
    },
    getQrCodesDetailsSuccess(state, payload) {
      console.log("payload", payload);
      state.qrCodeDetails = payload;
      state.qrCodeDetailsLoading = false;
      state.qrCodeDetailsError = null;
    },
  },
  actions: {
    async getChartsData({ commit, getters }) {
      try {
        commit("getChartsDataStart");
        const filters = serializeChartDataFilter(getters);

        const { data } = await apollo.query({
          query: GET_CHARTS_DATA,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            usersFilters: filters,
            qrCodesFilters: { type: { _eq: "dynamic" }, ...filters },
            scansFilters: { qr_code: { type: { _eq: "dynamic" } }, ...filters },
          },
        });

        commit("getChartsDataSuccess", serializeChartData(data));
      } catch (e) {
        console.log(e);
        commit("getChartsDataFailure", e);
      }
    },
    async getQrCodesScansRating({ commit, getters }) {
      try {
        commit("getQrCodesScansRatingStart");
        const filters = serializeChartDataFilter(getters);

        const { data } = await apollo.query({
          query: GET_QR_CODE_SCANS_RATING,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { filters },
        });

        commit(
          "getQrCodesScansRatingSuccess",
          serializeQrCodesScansRating(data)
        );
      } catch (e) {
        console.log(e);
        commit("getQrCodesScansRatingFailure", e);
      }
    },
    async getQrCodesDetails({ commit, getters }, shortId) {
      try {
        commit("getQrCodesDetailsStart");
        const filters = serializeChartDataFilter(getters);

        const { data } = await apollo.query({
          query: GET_QR_CODE_DETAILS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            filters: {
              key: { _eq: shortId },
              ...filters,
            },
          },
        });

        commit("getQrCodesDetailsSuccess", data.qr_codes?.[0]);
      } catch (e) {
        console.log(e);
        commit("getQrCodesDetailsFailure", e);
      }
    },
  },
};
