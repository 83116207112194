import gql from "graphql-tag";

export const GET_QR_CODE_DETAILS = gql`
  query getQrCodeDetails($key: String!, $scansFilter: qr_code_scans_bool_exp!) {
    qr_codes(where: { key: { _eq: $key } }) {
      id
      name
      created_at
      type
      key
      data_format
      status
      content
      folder {
        id
        name
      }
      preview {
        key
      }
      # scans counters
      unique_scans: scans_aggregate(distinct_on: [ip_address, user_agent]) {
        aggregate {
          count
        }
      }
      all_scans: scans_aggregate {
        aggregate {
          count
        }
      }
      # campaign
      campaign_medium
      campaign_start
      campaign_end
      print_run
      # scans
      scans(where: $scansFilter) {
        created_at
        city
        country
        user_agent
      }
    }
  }
`;

export const UPDATE_QR_CAMPAIGN_INFO = gql`
  mutation updateQrCampaignInfo(
    $id: uuid!
    $campaign_medium: String
    $print_run: Int
    $campaign_start: timestamptz
    $campaign_end: timestamptz
  ) {
    update_qr_codes(
      where: { id: { _eq: $id } }
      _set: {
        campaign_medium: $campaign_medium
        print_run: $print_run
        campaign_start: $campaign_start
        campaign_end: $campaign_end
      }
    ) {
      affected_rows
    }
  }
`;

export const RESET_QR_CODE_SCANS = gql`
  mutation resetQrCodeScans($qrCodeId: uuid!) {
    delete_qr_code_scans(where: { qr_code_id: { _eq: $qrCodeId } }) {
      affected_rows
    }
  }
`;
export const GET_QR_CODE_SCANS = gql`
  query getQrCodeScans($scansFilter: qr_code_scans_bool_exp!) {
    qr_code_scans(where: $scansFilter) {
      created_at
      city
      country
      user_agent
    }
  }
`;
