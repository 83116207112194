import helpCenterService from "@/services/help-center";

const initState = () => ({
  email: "",
  name: "",
  type: null,
  subject: "",
  description: "",

  error: null,
  loading: false,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    email: ({ email }) => email,
    name: ({ name }) => name,
    type: ({ type }) => type,
    subject: ({ subject }) => subject,
    description: ({ description }) => description,

    error: ({ error }) => error,
    loading: ({ loading }) => loading,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },

    setEmail(state, email) {
      state.email = email;
    },
    setName(state, name) {
      state.name = name;
    },
    setType(state, type) {
      state.type = type;
    },
    setSubject(state, subject) {
      state.subject = subject;
    },
    setDescription(state, description) {
      state.description = description;
    },

    sendHelpCenterRequestStart(state) {
      state.loading = true;
      state.error = null;
    },
    sendHelpCenterRequestSuccess(state) {
      state.loading = true;
    },
    sendHelpCenterRequestFailure(state, e) {
      state.loading = false;
      state.error = e;
    },
  },
  actions: {
    async sendHelpCenterRequest(
      { commit, getters, rootGetters },
      recaptchaToken
    ) {
      try {
        commit("sendHelpCenterRequestStart");
        const { email, name, type, subject, description } = getters;
        await helpCenterService.sendHelpCenterRequest({
          helpCenterRequest: { email, name, type, subject, description },
          language: rootGetters["language"],
          recaptchaToken,
        });
        commit("sendHelpCenterRequestSuccess");
      } catch (e) {
        commit("sendHelpCenterRequestFailure", e);
        return Promise.reject(e);
      }
    },
  },
};
