export default {
  namespaced: true,
  state: {
    MOBILE_WIDTH: 990,
    isMobile: false,
  },
  getters: {
    MOBILE_WIDTH: ({ MOBILE_WIDTH }) => MOBILE_WIDTH,
    isMobile: ({ isMobile }) => isMobile,
  },
  mutations: {
    toggleIsMobile(state, bool) {
      state.isMobile = bool;
    },
  },
  // actions: {}
};
