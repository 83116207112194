import cookie from "cookie-universal";
import i18n from "@/plugins/i18n";
import store from "@/store";
import moment from "moment";
import enLang from "element-ui/lib/locale/lang/en"; // ? імпорт мови для element-ui
import elementUiLocale from "element-ui/lib/locale"; // ? імпорт обєкта для підключення мови

export const FAILBACK_LANGUAGE = "en";
export const getFirstLanguage = () => {
  const queryLocale = new URLSearchParams(window.location.search).get("locale");
  const queryLang = queryLocale?.split?.("-")?.[0];
  const cookieLang = cookie().get("lang");
  const lang = queryLang || cookieLang || FAILBACK_LANGUAGE;

  // Забрати locale query з URL
  if (queryLocale) {
    const url = new URL(window.location.href);
    url.searchParams.delete("locale");
    window.history.replaceState({}, "", url);
  }

  return lang;
};

export const setLanguage = (lang) => {
  cookie().set("lang", lang);
  // Збереження локалі в сторі
  store.commit("SET_LANGUAGE", lang);
  i18n.locales = [...(i18n.locales || []), { code: lang }];
  i18n.defaultLocale = lang;
  i18n.locale = lang;
  // realize language for date

  // todo check moment and element ui codes for langs
  moment.locale(lang);
  elementUiLocale.use(enLang);
};
