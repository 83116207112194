import { GET_NEWS, MARK_AS_VIEWED_NEWS } from "@/services/news";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const initState = () => ({
  news: [],
  loadedAll: false,
  newsLoading: false,
  newsMoreLoading: false,
  newsError: null,
  offset: 0,
  limit: 10,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    news: ({ news }) => news,
    loadedAll: ({ loadedAll }) => loadedAll,
    newsLoading: ({ newsLoading }) => newsLoading,
    newsMoreLoading: ({ newsMoreLoading }) => newsMoreLoading,
    newsError: ({ newsError }) => newsError,
    offset: ({ offset }) => offset,
    limit: ({ limit }) => limit,
    notViewedNewsCount: ({ news }) =>
      news.reduce((acc, n) => {
        if (!n?.is_public && !n?.viewed_at) acc += 1;
        return acc;
      }, 0),
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    // get news list
    getNewsStart(state) {
      state.loadedAll = false;
      state.offset = 0;

      state.newsLoading = true;
      state.newsError = null;
      state.news = [];
    },
    getNewsSuccess(state, news) {
      state.newsLoading = false;
      state.news = news;
    },
    getNewsFailure(state, e) {
      state.newsLoading = false;
      state.newsError = e;
    },
    // get more news list
    getMoreNewsStart(state) {
      state.newsMoreLoading = true;
      state.newsError = null;
      state.offset += state.limit;
    },
    getMoreNewsSuccess(state, news) {
      state.newsMoreLoading = false;
      state.news = [...state.news, ...(news ?? [])];
      if (news.length < state.limit) state.loadedAll = true;
    },
    getMoreNewsFailure(state, e) {
      state.offset = Math.max(0, state.offset - state.limit);
      state.newsMoreLoading = false;
      state.newsError = e;
    },
    markAsViewed(state) {
      const now = new Date();
      state.news.forEach((n) => (n.viewed_at = now));
    },
  },
  actions: {
    async getNews({ commit, rootGetters, getters }) {
      try {
        commit("getNewsStart");
        const accountId = rootGetters["account/account"]?.id;
        const { data } = await apollo.query({
          query: GET_NEWS,
          variables: {
            accountId: accountId,
            limit: getters["limit"],
            offset: getters["offset"],
          },
        });
        commit("getNewsSuccess", data.news);
      } catch (e) {
        console.log(e);
        commit("getNewsFailure", e);
      }
    },
    async getMoreNews({ commit, rootGetters, getters }) {
      try {
        if (getters["loadedAll"]) return;
        commit("getMoreNewsStart");
        const accountId = rootGetters["account/account"]?.id;
        const { data } = await apollo.query({
          query: GET_NEWS,
          variables: {
            accountId: accountId,
            limit: getters["limit"],
            offset: getters["offset"],
          },
        });

        commit("getMoreNewsSuccess", data.news);
      } catch (e) {
        commit("getMoreNewsFailure", e);
      }
    },
    async markAsViewed({ getters, commit }) {
      try {
        if (!getters["notViewedNewsCount"]) return;

        await apollo.query({ query: MARK_AS_VIEWED_NEWS });
        commit("markAsViewed");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
