import { GET_ACCOUNTS } from "@/services/account";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { PLANS_WITH_MULTI_USERS } from "@/constants/plans";
import { getTokenUserId } from "@/utils/token";
import { FREE_PLAN_ID } from "@/constants/plans";

const serializeMyAccount = (data) => {
  const account = data?.accounts?.[0];
  const user = data?.users?.[0] ?? {};
  if (!account) throw Error("Account is not fount");

  return {
    ...account,
    ...(account.contacts?.[0] ?? {}),
    ...user,
  };
};

export default {
  namespaced: true,
  state: {
    account: null,
    error: null,
    loading: false,

    accountPlan: null,

    headerSearch: "",
    shownHeaderSearch: false,
  },
  getters: {
    headerSearch: ({ headerSearch }) => headerSearch,
    shownHeaderSearch: ({ shownHeaderSearch }) => shownHeaderSearch,

    account: ({ account }) => account,
    errors: ({ errors }) => errors,
    loading: ({ loading }) => loading,
    accountPlan: ({ accountPlan }) => accountPlan,
    allowedMultiUsers: ({ accountPlan }) =>
      PLANS_WITH_MULTI_USERS?.includes?.(accountPlan?.plan?.id),
    accountName: ({ account }) =>
      account?.company ||
      (account?.first_name && `${account?.first_name} ${account?.last_name}`) ||
      account?.email,
    usedDynamicQrCodes: ({ account }) =>
      account?.used_resources?.used_dynamic_qr_codes,
    usedStaticQrCodes: ({ account }) =>
      account?.used_resources?.used_static_qr_codes,
    usedBusinessCards: ({ account }) =>
      account?.used_resources?.used_business_cards,
    usedScans: ({ account }) => account?.used_resources?.used_scans,
    availableDynamicQrCodes: ({ accountPlan }) =>
      accountPlan?.plan?.dynamic_qr_codes,
    availableBusinessCards: ({ accountPlan }) =>
      accountPlan?.plan?.dynamic_business_card,
    availableStaticQrCodes: ({ accountPlan }) =>
      accountPlan?.plan?.static_qr_codes,
    availableScans: ({ accountPlan }) => accountPlan?.plan?.scans,
    userLanguage: ({ account }) => account?.software_preferences?.language,
    isOwner: ({ account }) => account.owner_id === getTokenUserId(),
    isFreePlan: ({ accountPlan }) => accountPlan?.plan?.id === FREE_PLAN_ID,
    isPaid: ({ accountPlan }, { isFreePlan }) =>
      isFreePlan || accountPlan?.is_paid,
    isTrial: ({ accountPlan }, { isFreePlan }) =>
      isFreePlan || accountPlan?.is_trial,
    reachedDynamicQrCodesLimit: (
      state,
      { usedDynamicQrCodes, availableDynamicQrCodes }
    ) => {
      const unlimitedDynamicQrCodes = availableDynamicQrCodes === null;
      return (
        !unlimitedDynamicQrCodes &&
        usedDynamicQrCodes >= availableDynamicQrCodes
      );
    },
    reachedBusinessCardsLimit: (
      state,
      { usedBusinessCards, availableBusinessCards }
    ) => {
      const unlimitedBusinessCards = availableBusinessCards === null;
      return (
        !unlimitedBusinessCards && usedBusinessCards >= availableBusinessCards
      );
    },
    reachedStaticQrCodesLimit: (
      state,
      { usedStaticQrCodes, availableStaticQrCodes }
    ) => {
      const unlimitedStaticQrCodes = availableStaticQrCodes === null;
      return (
        !unlimitedStaticQrCodes && usedStaticQrCodes >= availableStaticQrCodes
      );
    },
    reachedScansLimit: (state, { usedScans, availableScans }) => {
      const unlimitedStaticQrCodes = availableScans === null;
      return !unlimitedStaticQrCodes && usedScans >= availableScans;
    },
    advertising: ({ accountPlan }) => accountPlan?.plan?.advertising,
  },
  mutations: {
    showHeaderSearch(state, bool) {
      if (!bool) state.headerSearch = "";
      state.shownHeaderSearch = bool;
    },
    updateHeaderSearch(state, value) {
      state.headerSearch = value;
    },
    getAccountsStart(state) {
      state.account = null;
      state.error = null;
      state.loading = true;
    },
    getAccountsSuccess(state, payload) {
      state.account = payload.account;
      state.accountPlan = payload.account.account_plan;
      state.loading = false;
    },
    getAccountsFailure(state, e) {
      state.account = null;
      state.error = e;
      state.loading = false;
    },
  },
  actions: {
    async getAccounts({ commit }) {
      try {
        commit("getAccountsStart");

        const { data } = await apollo.query({
          query: GET_ACCOUNTS,
          variables: { userId: getTokenUserId() },
        });

        const account = serializeMyAccount(data);

        if (!account) throw Error("The account is not found");
        commit("getAccountsSuccess", { account });
      } catch (e) {
        commit("getAccountsFailure", e);
        return Promise.reject(e);
      }
    },
    async getUpdatedAccount({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_ACCOUNTS,
          variables: { userId: getTokenUserId() },
        });
        const account = serializeMyAccount(data);
        commit("getAccountsSuccess", { account });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
