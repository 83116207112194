import {
  GET_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_CONTACTS,
} from "@/services/settings/account";
import { getDefaultAccountContact } from "@/constants/account";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeAccountSettings = (account) => {
  if (!account) throw Error("Oops... something went wrong!");
  const info = {
    id: account.id,
    created_at: account.created_at,
    customer_id: account.customer_id,
    expiry_date: account.account_plan?.expiry_date,
    plan_name: account.account_plan?.plan?.label,
    email: account.owner?.email,
  };
  const usage = {
    limits: account.account_plan?.plan,
    used: {
      dynamic_qr_codes_used: account.used_resources?.used_dynamic_qr_codes || 0,
      static_qr_codes_used: account.used_resources?.used_static_qr_codes || 0,
      scans_used: account.used_resources?.used_scans || 0,
      storage_used: account.used_resources?.used_storage || 0,
      used_business_cards: account.used_resources?.used_business_cards || 0,
      used_api_requests: account.used_resources?.used_api_requests || 0,
      // api_requests_used: account.static_qr_codes_aggregate?.aggregate?.count || 0,
    },
  };
  const contact = account.contacts?.[0] ?? getDefaultAccountContact();
  delete contact.__typename;

  return { info, usage, contact };
};

const initState = () => ({
  info: null,
  usage: null,
  contact: null,

  contactLoading: null,
  errorLoading: null,
  loading: false,
  error: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    info: ({ info }) => info,
    usage: ({ usage }) => usage,
    contact: ({ contact }) => contact,
    loading: ({ loading }) => loading,
    error: ({ error }) => error,
    contactLoading: ({ contactLoading }) => contactLoading,
    errorLoading: ({ errorLoading }) => errorLoading,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },

    setContactField(state, { prop, value }) {
      state.contact[prop] = value;
    },

    getAccountSettingsStart(state) {
      state.loading = true;
      state.error = null;
      state.info = null;
      state.usage = null;
      state.contact = null;
    },
    getAccountSettingsSuccess(state, payload) {
      state.loading = false;
      state.info = payload.info;
      state.usage = payload.usage;
      state.contact = payload.contact;
    },
    getAccountSettingsFailure(state, e) {
      state.loading = true;
      state.error = e;
    },
    updateAccountContactStart(state) {
      state.contactLoading = true;
      state.errorLoading = null;
    },
    updateAccountContactSuccess(state) {
      state.contactLoading = false;
    },
    updateAccountContactFailure(state, e) {
      state.contactLoading = false;
      state.errorLoading = e;
    },
  },
  actions: {
    async getAccountSettings({ commit }) {
      try {
        commit("getAccountSettingsStart");
        const { data } = await apollo.query({ query: GET_ACCOUNT_SETTINGS });
        commit(
          "getAccountSettingsSuccess",
          serializeAccountSettings(data.accounts?.[0])
        );
      } catch (e) {
        commit("getAccountSettingsFailure", e);
      }
    },
    async updateAccountContact({ commit, getters, dispatch }) {
      try {
        commit("updateAccountContactStart");
        const contact = getters["contact"];
        await apollo.query({
          query: UPDATE_ACCOUNT_CONTACTS,
          variables: contact,
        });
        commit("updateAccountContactSuccess");
      } catch (e) {
        commit("updateAccountContactFailure", e);
        dispatch("getAccountSettings");
      }
    },
  },
};
