import {
  UPDATE_BILLING_ADDRESS,
  GET_BILLING_SETTINGS,
  SUBSCRIBE_PLAN,
  GET_ORDERS,
  verifyCard,
  GET_PAID_ORDER_BY_ID,
  // GET_CURRENT_PLAN_ID,
} from "@/services/billing";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeOrderVariables = (rootGetters, getters) => {
  const accountId = rootGetters["account/account"]?.id;
  const limit = getters["ordersLimit"];
  const offset = getters["ordersOffset"];

  return { accountId, limit, offset };
};

const initState = () => ({
  orders: [],
  accountPlan: null,
  billingAddress: null,
  loading: false,
  errors: null,
  // pagination
  ordersOffset: 0,
  ordersLimit: 50,
  ordersLoading: false,
  ordersMoreLoading: false,
  ordersError: false,
  loadedAll: false,
  paidOrder: null,
  // currentPlanId: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    orders: ({ orders }) => orders,
    accountPlan: ({ accountPlan }) => accountPlan,
    billingAddress: ({ billingAddress }) => billingAddress,
    loading: ({ loading }) => loading,
    errors: ({ errors }) => errors,
    ordersOffset: ({ ordersOffset }) => ordersOffset,
    ordersMoreLoading: ({ ordersMoreLoading }) => ordersMoreLoading,
    ordersLimit: ({ ordersLimit }) => ordersLimit,
    loadedAll: ({ loadedAll }) => loadedAll,
    paidOrder: ({ paidOrder }) => paidOrder,
    // currentPlanId: ({ currentPlanId }) => currentPlanId,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    // get billing settings
    getBillingSettingsStart(state) {
      state.loading = true;
      state.errors = null;
      state.accountPlan = null;
      state.billingAddress = null;
    },
    getBillingSettingsSuccess(state, payload) {
      state.loading = false;
      state.accountPlan = payload.accounts_plans?.[0];
      state.billingAddress = payload.account_billing_address?.[0];
    },
    getBillingSettingsFailure(state, e) {
      state.loading = false;
      state.ordersError = e;
      state.accountPlan = null;
      state.billingAddress = null;
    },
    // get orders
    getOrdersStart(state) {
      state.loadedAll = false;
      state.ordersOffset = 0;

      state.ordersLoading = true;
      state.ordersError = null;
      state.orders = [];
    },
    getOrdersSuccess(state, orders) {
      state.ordersLoading = false;
      state.orders = orders;
    },
    getOrdersFailure(state, e) {
      state.ordersLoading = false;
      state.ordersError = e;
    },
    // get more orders
    getMoreOrdersStart(state) {
      state.ordersMoreLoading = true;
      state.ordersError = null;
      state.ordersOffset += state.ordersLimit;
    },
    getMoreOrdersSuccess(state, orders) {
      state.ordersMoreLoading = false;
      state.orders = [...state.orders, ...(orders ?? [])];
      if (orders.length < state.ordersLimit) state.loadedAll = true;
    },
    getMoreOrdersFailure(state, e) {
      state.ordersOffset = Math.max(0, state.ordersOffset - state.ordersLimit);
      state.ordersMoreLoading = false;
      state.ordersError = e;
    },
    subscribePlan(state, isSubscribed) {
      state.accountPlan.is_subscribed = isSubscribed;
    },
    updateBillingAddress(state, billingAddress) {
      Object.assign(state.billingAddress, billingAddress);
    },
    updateAccountPlan(state, accountPlan) {
      Object.assign(state.accountPlan, accountPlan);
    },
    getPaidOrderByIdSuccess(state, paidOrder) {
      state.paidOrder = paidOrder;
    },
    getPaidOrderByIdStart(state) {
      state.paidOrder = null;
    },
    // setCurrentPlanId(state, currentPlanId) {
    //   state.currentPlanId = currentPlanId;
    // },
  },
  actions: {
    async getPaidOrderById({ commit }, orderId) {
      try {
        commit("getPaidOrderByIdStart");

        const { data } = await apollo.query({
          query: GET_PAID_ORDER_BY_ID,
          variables: { orderId },
        });
        commit("getPaidOrderByIdSuccess", data.orders[0]);
      } catch (e) {
        console.log(e);
        commit("getPaidOrderByIdStart");
      }
    },
    async getBillingSettings({ commit, rootGetters }) {
      try {
        commit("getBillingSettingsStart");
        const accountId = rootGetters["account/account"]?.id;

        const { data } = await apollo.query({
          query: GET_BILLING_SETTINGS,
          variables: { accountId },
        });
        commit("getBillingSettingsSuccess", data);
      } catch (e) {
        console.log(e);
        commit("getBillingSettingsFailure", e);
      }
    },
    async getOrders({ commit, rootGetters, getters }) {
      try {
        commit("getOrdersStart");
        const { data } = await apollo.query({
          query: GET_ORDERS,
          variables: serializeOrderVariables(rootGetters, getters),
        });
        commit("getOrdersSuccess", data.orders);
      } catch (e) {
        console.log(e);
        commit("getOrdersFailure", e);
      }
    },
    async getMoreOrders({ commit, rootGetters, getters }) {
      try {
        if (getters["loadedAll"]) return;
        commit("getMoreOrdersStart");
        const { data } = await apollo.query({
          query: GET_ORDERS,
          variables: serializeOrderVariables(rootGetters, getters),
        });
        commit("getMoreOrdersSuccess", data.orders);
      } catch (e) {
        commit("getMoreOrdersFailure", e);
      }
    },
    async subscribePlan({ commit, rootGetters }, isSubscribed) {
      try {
        const accountId = rootGetters["account/account"]?.id;
        await apollo.mutate({
          mutation: SUBSCRIBE_PLAN,
          variables: { isSubscribed, accountId },
        });
        commit("subscribePlan", isSubscribed);
      } catch (e) {
        console.log(e);
      }
    },
    async updateBillingAddress({ commit, rootGetters }, billingAddress) {
      try {
        const accountId = rootGetters["account/account"]?.id;
        await apollo.mutate({
          mutation: UPDATE_BILLING_ADDRESS,
          variables: { billingAddress, accountId },
        });
        commit("updateBillingAddress", billingAddress);
      } catch (e) {
        console.log(e);
      }
    },
    async verifyCary(context, variables) {
      try {
        await verifyCard(variables);
      } catch (e) {
        console.log(e);
      }
    },
    // async getCurrentPlanId({ commit, rootGetters }) {
    //   try {
    //     const accountId = rootGetters["account/account"]?.id;
    //
    //     const { data } = await apollo.query({
    //       query: GET_CURRENT_PLAN_ID,
    //       variables: { accountId },
    //     });
    //
    //     commit("setCurrentPlanId", data.account_plan?.[0]?.plan?.id || null);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
  },
};
