import { stringToElement } from "@/utils/string-to-element";

const DEFAULT_SVG_HEIGHT = 200;
const DEFAULT_SVG_WIDTH = 200;

const parseSvgSize = (svgText) => {
  const matchWidth = svgText.match(/width="(\d+)/m);
  const matchHeight = svgText.match(/height="(\d+)/m);

  const width = matchWidth?.[1] ? parseInt(matchWidth[1]) : DEFAULT_SVG_WIDTH;
  const height = matchHeight?.[1]
    ? parseInt(matchHeight[1])
    : DEFAULT_SVG_HEIGHT;
  return { width, height };
};

const updateSvgSize = (svgText, options) => {
  let { width, height } = parseSvgSize(svgText);

  let svgEl;
  if (options.width) {
    const scale = options.width / width;
    width = options.width;
    height = scale * height;
    // svgText.replace(/width=\"(\d+)/m, String(width));
    // svgText.replace(/height=\"(\d+)/m, String(height));
    svgEl = stringToElement(svgText, "svg");
    svgEl.setAttribute("width", String(width));
    svgEl.setAttribute("height", String(height));
  }
  return { width, height, svgText: svgEl?.outerHTML || svgText };
};

const removeSvgSize = (svgText) => {
  const svgEl = stringToElement(svgText, "svg");
  svgEl.removeAttribute("width");
  svgEl.removeAttribute("height");
  return svgEl?.outerHTML;
};

export const setWhiteBackground = (svgText) => {
  const { width, height } = parseSvgSize(svgText);
  const svgEl = stringToElement(svgText, "svg");
  const rect = document.createElement("rect");
  rect.setAttribute("width", String(width));
  rect.setAttribute("height", String(height));
  rect.setAttribute("fill", "#ffffff");
  svgEl.prepend(rect);
  return svgEl?.outerHTML;
};

/**
 * converts an svg string to base64 png using the domUrl
 * @param {string} svgText the svgtext
 * @param {Object} options width | mimetype
 * @return {Promise} a promise to the bas64 png image
 */
export const svgToRasterImageLink = (svgText, options = {}) => {
  return new Promise(function (resolve, reject) {
    try {
      const domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error("(browser doesnt support this)");
      }

      let {
        width,
        height,
        svgText: _svgText,
      } = updateSvgSize(svgText, options);
      if (options.mimetype === "image/jpeg")
        _svgText = setWhiteBackground(_svgText);

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      const svg = new Blob([_svgText], { type: "image/svg+xml;charset=utf-8" });

      const url = domUrl.createObjectURL(svg);
      const img = new Image();
      img.src = url;

      img.addEventListener("load", () => {
        setTimeout(() => {
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL(options.mimetype || "image/png"));
          // domUrl.revokeObjectURL(url);
        }, 100);
      });
    } catch (err) {
      reject("failed to convert svg to png " + err);
    }
  });
};

export const dataUrlToBlob = (dataURL, mimeType = "image/png") => {
  const blobBin = atob(dataURL.split(",")[1]);
  const array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mimeType });
};

export const svgToPngBlob = async (svgText, options = {}) => {
  const dataUrl = await svgToRasterImageLink(svgText, options);
  return dataUrlToBlob(dataUrl);
};

export const svgToBlob = async (svgText) => {
  const _svgText = removeSvgSize(svgText);
  return new Blob([_svgText]);
};
