import UserSettingsService, {
  GET_USER_SETTINGS,
  UPDATE_SOFTWARE_PREFERENCES,
  UPDATE_USER_INFO,
} from "@/services/settings/user";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { FAILBACK_LANGUAGE } from "@/plugins/locale/locales";
import { getTokenUserId } from "@/utils/token";

const serializeUserSettings = (data, languages) => {
  const account = data.accounts?.[0];
  const user = data.users?.[0];
  if (!account) throw Error("Oops... something went wrong!");
  const softwarePreferences = {
    time_zone: account.software_preferences.time_zone || null,
    language: account.software_preferences.language || FAILBACK_LANGUAGE,
  };

  const info = {
    email: user?.email,
    first_name: account.contacts?.[0]?.first_name || "",
    last_name: account.contacts?.[0]?.last_name || "",
  };

  const languagesOptions = languages.map(({ language_code, language }) => ({
    value: language_code,
    label: language,
  }));

  return { softwarePreferences, info, languages: languagesOptions };
};

const initState = () => ({
  loading: false,
  error: null,
  softwarePreferencesLoading: false,
  softwarePreferencesError: null,
  infoLoading: false,
  infoError: null,
  closingAccountLoading: false,
  closingAccountError: null,

  languages: [],
  softwarePreferences: null,
  info: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    loading: ({ loading }) => loading,
    error: ({ error }) => error,
    softwarePreferencesLoading: ({ softwarePreferencesLoading }) =>
      softwarePreferencesLoading,
    softwarePreferencesError: ({ softwarePreferencesError }) =>
      softwarePreferencesError,
    infoLoading: ({ infoLoading }) => infoLoading,
    infoError: ({ infoError }) => infoError,
    closingAccountLoading: ({ closingAccountLoading }) => closingAccountLoading,
    closingAccountError: ({ closingAccountError }) => closingAccountError,

    languages: ({ languages }) => languages,
    softwarePreferences: ({ softwarePreferences }) => softwarePreferences,
    info: ({ info }) => info,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },

    setPreferencesField(state, { prop, value }) {
      state.softwarePreferences[prop] = value;
    },
    setInfoField(state, { prop, value }) {
      state.info[prop] = value;
    },

    getUserSettingsStart(state) {
      state.loading = true;
      state.error = null;
      state.softwarePreferences = null;
      state.info = null;
      state.languages = null;
    },
    getUserSettingsSuccess(state, payload) {
      state.loading = false;
      state.softwarePreferences = payload.softwarePreferences;
      state.info = payload.info;
      state.languages = payload.languages;
    },
    getUserSettingsFailure(state, e) {
      state.loading = true;
      state.error = e;
    },

    changeUserPasswordStart(state) {
      state.loading = true;
      state.error = null;
    },
    changeUserPasswordSuccess(state) {
      state.loading = false;
    },
    changeUserPasswordFailure(state, e) {
      state.loading = false;
      state.error = e;
    },

    changeUserEmailStart(state) {
      state.loading = true;
      state.error = null;
    },
    changeUserEmailSuccess(state) {
      state.loading = false;
    },
    changeUserEmailFailure(state, e) {
      state.loading = false;
      state.error = e;
    },
    updateSoftwarePreferencesStart(state) {
      state.softwarePreferencesLoading = true;
      state.softwarePreferencesError = null;
    },
    updateSoftwarePreferencesSuccess(state) {
      state.softwarePreferencesLoading = false;
    },
    updateSoftwarePreferencesFailure(state, e) {
      state.softwarePreferencesLoading = false;
      state.softwarePreferencesError = e;
    },
    updateUserInfoStart(state) {
      state.infoLoading = true;
      state.infoError = null;
    },
    updateUserInfoSuccess(state) {
      state.infoLoading = false;
    },
    updateUserInfoFailure(state, e) {
      state.infoLoading = false;
      state.infoError = e;
    },
    closeAccountStart(state) {
      state.closingAccountLoading = true;
      state.closingAccountError = null;
    },
    closeAccountSuccess(state) {
      state.closingAccountLoading = false;
    },
    closeAccountFailure(state, e) {
      state.closingAccountLoading = false;
      state.closingAccountError = e;
    },
  },
  actions: {
    async updateSoftwarePreferences({ commit, getters, dispatch }) {
      try {
        commit("updateSoftwarePreferencesStart");
        const softwarePreferences = getters["softwarePreferences"];
        await apollo.query({
          query: UPDATE_SOFTWARE_PREFERENCES,
          variables: softwarePreferences,
        });
        commit("updateSoftwarePreferencesSuccess");
      } catch (e) {
        commit("updateSoftwarePreferencesFailure", e);
        dispatch("getUserSettings");
      }
    },
    async updateUserInfo({ commit, getters, dispatch }) {
      try {
        commit("updateUserInfoStart");
        const info = { ...getters["info"] };
        delete info.email;

        await apollo.query({
          query: UPDATE_USER_INFO,
          variables: info,
        });
        commit("updateUserInfoSuccess");
      } catch (e) {
        commit("updateUserInfoFailure", e);
        dispatch("getUserSettings");
      }
    },
    async getUserSettings({ commit }) {
      try {
        commit("getUserSettingsStart");
        const { data } = await apollo.query({
          query: GET_USER_SETTINGS,
          variables: { userId: getTokenUserId() },
        });

        commit(
          "getUserSettingsSuccess",
          serializeUserSettings(data, data.locales)
        );
      } catch (e) {
        commit("getUserSettingsFailure", e);
      }
    },
    async closeAccount({ commit }, credentials) {
      try {
        commit("closeAccountStart");
        await UserSettingsService.closeAccount(credentials);
        commit("closeAccountSuccess");
      } catch (e) {
        commit("closeAccountFailure", e);
        return Promise.reject(e);
      }
    },
    async changeUserPassword({ commit }, passwords) {
      try {
        commit("changeUserPasswordStart");
        await UserSettingsService.changePassword(passwords);
        commit("changeUserPasswordSuccess");
      } catch (e) {
        commit("changeUserPasswordFailure", e);
        return Promise.reject(e);
      }
    },
    async changeUserEmail({ commit }, credentials) {
      try {
        commit("changeUserEmailStart");
        await UserSettingsService.changeEmail(credentials);
        commit("changeUserEmailSuccess");
      } catch (e) {
        commit("changeUserEmailFailure", e);
        return Promise.reject(e);
      }
    },
  },
};
