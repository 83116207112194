import {
  GET_QR_CODE_DESIGN,
  UPDATE_QR_CODE_KEY,
  UPDATE_QR_CODE_DESIGN,
} from "@/services/qrDesign";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { serializeQrCodeDesign } from "@/store/mainNewGenerator";

const serializeUpdateQrCodeDesign = (qrCodeDesign, fileDetails) => {
  return {
    id: qrCodeDesign.id,
    oldPreviewId: qrCodeDesign.qr_code.preview_id,
    qrCodePreviewId: fileDetails.id,
    newDesign: {
      background_color: qrCodeDesign.background_color,
      foreground_color: qrCodeDesign.foreground_color,
      foreground_color_2: qrCodeDesign.foreground_color_2,
      is_gradient: qrCodeDesign.is_gradient,
      gradient_angle: qrCodeDesign.gradient_angle,
      is_radial_gradient: qrCodeDesign.is_radial_gradient,
      logo_url: qrCodeDesign.logo_url,
      version: qrCodeDesign.version,
      size: qrCodeDesign.size,
      frame_svg: qrCodeDesign.frame_svg,
      frame_name: qrCodeDesign.frame_name,
      frame_text: qrCodeDesign.frame_text,
      frame_color: qrCodeDesign.frame_color,
      frame_color_2: qrCodeDesign.frame_color_2,
      frame_font: qrCodeDesign.frame_font,
      custom_marker_color: qrCodeDesign.custom_marker_color,
      marker_left_inner_color: qrCodeDesign.marker_left_inner_color,
      marker_right_inner_color: qrCodeDesign.marker_right_inner_color,
      marker_bottom_inner_color: qrCodeDesign.marker_bottom_inner_color,
      marker_left_outer_color: qrCodeDesign.marker_left_outer_color,
      marker_right_outer_color: qrCodeDesign.marker_right_outer_color,
      marker_bottom_outer_color: qrCodeDesign.marker_bottom_outer_color,
      marker_left_template: qrCodeDesign.marker_left_template,
      marker_right_template: qrCodeDesign.marker_right_template,
      marker_bottom_template: qrCodeDesign.marker_bottom_template,
      marker_left_svg: qrCodeDesign.marker_left_svg,
      marker_right_svg: qrCodeDesign.marker_right_svg,
      marker_bottom_svg: qrCodeDesign.marker_bottom_svg,
      image_url: qrCodeDesign.image_url,
      background_image_url: qrCodeDesign.background_image_url,
      shapes: qrCodeDesign.shapes,
      logo_name: qrCodeDesign.logo_name,
      logo_type: qrCodeDesign.logo_type,
    },
  };
};

const initState = () => ({
  shownDesignPopup: false,
  qrCodeToDesignKey: null,
  loadingQrCodeDesign: false,
  qrCodeDesign: null,
  qrCodeDesignError: null,
  qrCodeDesignStep: "main",
  qrCodeInstance: null,
  qrCodeSvg: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    shownDesignPopup: ({ shownDesignPopup }) => shownDesignPopup,
    qrCodeToDesignKey: ({ qrCodeToDesignKey }) => qrCodeToDesignKey,
    loadingQrCodeDesign: ({ loadingQrCodeDesign }) => loadingQrCodeDesign,
    qrCodeDesign: ({ qrCodeDesign }) => qrCodeDesign,
    qrCodeDesignError: ({ qrCodeDesignError }) => qrCodeDesignError,
    qrCodeDesignStep: ({ qrCodeDesignStep }) => qrCodeDesignStep,
    qrCodeInstance: ({ qrCodeInstance }) => qrCodeInstance,
    qrCodeSvg: ({ qrCodeSvg }) => qrCodeSvg,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },

    designQrCode(state, qrCodeToDesignKey = null) {
      state.shownDesignPopup = Boolean(qrCodeToDesignKey);
      state.qrCodeToDesignKey = qrCodeToDesignKey;
      state.qrCodeDesignStep = "main";
      state.qrCodeInstance = null;
    },
    changeQrCodeDesignStep(state, step) {
      state.qrCodeDesignStep = step;
    },
    updateQrDesign(state, newDesign) {
      state.qrCodeDesign = { ...state.qrCodeDesign, ...newDesign };
    },
    // get qr code design
    getQrCodeDesignStart(state) {
      state.loadingQrCodeDesign = true;
      state.qrCodeDesign = null;
    },
    getQrCodeDesignSuccess(state, qrCodeDesign) {
      state.loadingQrCodeDesign = false;
      state.qrCodeDesign = qrCodeDesign;
    },
    getQrCodeDesignFailure(state, e) {
      state.loadingQrCodeDesign = false;
      state.qrCodeDesignError = e;
    },
    updateQrCodeKeySuccess(state, { key }) {
      state.qrCodeDesign.qr_code.key = key;
    },
    setQrCodeInstance(state, qrCodeInstance) {
      state.qrCodeInstance = qrCodeInstance;
    },
    setQrCodeSvg(state, qrCodeSvg) {
      state.qrCodeSvg = qrCodeSvg;
    },
  },
  actions: {
    async getQrCodeDesign({ commit, getters }) {
      if (!getters.qrCodeToDesignKey) return;
      try {
        commit("getQrCodeDesignStart");
        const { data } = await apollo.query({
          query: GET_QR_CODE_DESIGN,
          variables: { key: getters.qrCodeToDesignKey },
        });
        const qrCodeDesign = await serializeQrCodeDesign(
          data.qr_code_designs?.[0]
        );
        commit("getQrCodeDesignSuccess", qrCodeDesign);
      } catch (e) {
        console.log(e);
        commit("getQrCodeDesignFailure", e);
      }
    },
    async updateQrCodeKey({ commit }, variables) {
      try {
        await apollo.mutate({
          mutation: UPDATE_QR_CODE_KEY,
          variables,
        });
        commit("updateQrCodeKeySuccess", variables);
        commit("manage/updateQrCodeListItem", variables, { root: true });
      } catch (e) {
        console.log(e);
        return Promise.reject("popup:design_qr_code:error:already_taken");
      }
    },
    async updateQrCodeDesign({ getters, dispatch }) {
      // upload file
      const fileDetails = await dispatch(
        "qrCode/uploadQrPreview",
        {
          preview: getters.qrCodeSvg,
          filename: getters.qrCodeDesign.qr_code.key,
        },
        { root: true }
      );

      const variables = serializeUpdateQrCodeDesign(
        getters.qrCodeDesign,
        fileDetails
      );

      await apollo.mutate({
        mutation: UPDATE_QR_CODE_DESIGN,
        variables,
      });

      await dispatch(
        "files/updateFileParams",
        { qrCodeId: getters.qrCodeDesign.qr_code.id },
        { root: true }
      );

      return {
        previewKey: fileDetails.key,
        qrCodeId: getters.qrCodeDesign.qr_code.id,
      };
    },
  },
};
