import { isDataURI } from "validator";

export const getFileUrl = (key) => {
  try {
    return new URL(key, process.env.VUE_APP_STORAGE_CDN_URL).toString();
  } catch (e) {
    return "";
  }
};

export const normalizeFileSize = (bytes) => {
  if (!bytes) return "0 Bytes";
  const k = 1024;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const urlToDataUrl = (url) => {
  return new Promise((resolve, reject) => {
    const canModify = isDataURI(url) || url.startsWith("blob:");
    const urlToFetch = canModify ? url : `${url}?not-from-cache-please`;
    fetch(urlToFetch, {
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
