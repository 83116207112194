import { ApolloLink } from "apollo-link";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";

const link = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({ headers: {} }));
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_DB_URL,
});

export const anonymousClient = new ApolloClient({
  link: link.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // defaultOptions: {
  //   query: {
  //     fetchPolicy: "no-cache",
  //   },
  // },
});
