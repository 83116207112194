import axios from "@/plugins/axios";

const uploadFile = (formData, onUploadProgress, token) => {
  const configs = {
    headers: {
      "X-Wallet-Pass-Token": `Bearer ${token}`,
    },
  };
  if (onUploadProgress) configs.onUploadProgress = onUploadProgress;
  return axios.post("/wallet-pass-upload", formData, configs);
};

// const deleteFile = (variables) => {
//   return axios.post("/delete", variables);
// };

export default {
  uploadFile,
  // deleteFile,
};
