import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import walletService, { GET_WALLET_PASS_DETAILS } from "@/services/wallet";

const initState = () => ({
  walletType: null,
  cardDetails: null,
  cardDetailsLoading: false,
  cardDetailsError: null,
  shownWalletPassPopup: null,
  backgroundColor: null,
  textColor: null,
  email: "",
  token: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    walletType: ({ walletType }) => walletType,
    cardDetails: ({ cardDetails }) => cardDetails,
    cardDetailsLoading: ({ cardDetailsLoading }) => cardDetailsLoading,
    cardDetailsError: ({ cardDetailsError }) => cardDetailsError,
    shownWalletPassPopup: ({ shownWalletPassPopup }) => shownWalletPassPopup,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    textColor: ({ textColor }) => textColor,
    email: ({ email }) => email,
    token: ({ token }) => token,
    addToWalletUrl: ({
      walletType,
      cardDetails,
      backgroundColor,
      textColor,
    }) => {
      const qrCodeKey = cardDetails?.key;
      if (!walletType || !qrCodeKey) return "";
      const url = new URL(
        `wallet-pass/${walletType}/${qrCodeKey}`,
        process.env.VUE_APP_BASE_URL
      );
      if (backgroundColor)
        url.searchParams.set("background", backgroundColor.slice(1));
      if (textColor) url.searchParams.set("foreground", textColor.slice(1));
      return url.toString();
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    setWalletType(state, type) {
      state.walletType = type;
    },
    showWalletPassPopup(state, bool = true) {
      state.shownWalletPassPopup = bool;
    },
    setBackgroundColor(state, backgroundColor) {
      state.backgroundColor = backgroundColor;
    },
    setTextColor(state, textColor) {
      state.textColor = textColor;
    },
    setEmail(state, email) {
      state.email = email;
    },
    getCardDetailsStart(state) {
      state.cardDetails = null;
      state.cardDetailsLoading = true;
      state.cardDetailsError = null;
    },
    getCardDetailsSuccess(state, card) {
      state.cardDetails = card;
      state.cardDetailsLoading = false;
      state.cardDetailsError = null;
    },
    getCardDetailsFailure(state, error) {
      state.cardDetails = null;
      state.cardDetailsLoading = false;
      state.cardDetailsError = error;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    async getCardDetails({ commit }, key) {
      try {
        commit("getCardDetailsStart");
        const { data } = await apollo.query({
          query: GET_WALLET_PASS_DETAILS,
          variables: { key },
        });

        const walletPass = data.qr_codes[0];
        if (!walletPass) throw Error();
        commit("getCardDetailsSuccess", walletPass);
      } catch (e) {
        commit("getCardDetailsFailure", e.message);
        return Promise.reject(e.message);
      }
    },
    async sendWalletPassEmail({ getters }) {
      try {
        const variables = {
          email: getters["email"],
          backgroundColor: getters["backgroundColor"]?.slice?.(1),
          foregroundColor: getters["textColor"]?.slice?.(1),
          qrCodeKey: getters["cardDetails"]?.key,
        };
        const { data } = await walletService.sendWalletPassEmail(variables);
        if (!data.success) throw Error();
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
    async generateWalletPass(context, variables) {
      try {
        const { data } = await walletService.generateWalletPass(variables);
        return data?.addToWalletUrl;
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
    async getWalletPassDetails({ commit, getters }) {
      try {
        commit("getCardDetailsStart");
        commit("qrCode/getQrCodeDetailsStart", null, { root: true });
        const token = getters["token"];
        if (!token) throw Error();

        const { data } = await walletService.getWalletPassDetails(token);
        if (!data.success) throw Error();
        const qrCodeDetails = data.qrCodeDetails;
        commit(
          "qrCode/getQrCodeDetailsSuccess",
          { qrCode: qrCodeDetails.qr_codes?.[0], files: qrCodeDetails.files },
          { root: true }
        );
        commit("getCardDetailsSuccess", qrCodeDetails.qr_codes?.[0]);
      } catch (e) {
        commit("getCardDetailsFailure", e);
        commit("qrCode/getQrCodeDetailsFailure", e, { root: true });
        console.log(e);
        return Promise.reject(e);
      }
    },
    async updateWalletPassDetails({ commit, getters, rootGetters }) {
      try {
        const token = getters["token"];
        const filesKeysToAdd = rootGetters["walletPassFiles/filesKeysToAdd"];
        const filesKeysToRemove =
          rootGetters["walletPassFiles/filesKeysToRemove"];
        const newContent = rootGetters["qrCode/qrCode"]?.content;
        if (!token || !newContent) throw Error();

        console.log("filesKeysToAdd", filesKeysToAdd);
        console.log("filesKeysToRemove", filesKeysToRemove);

        const { data } = await walletService.updateWalletPassDetails(
          { newContent, filesKeysToAdd, filesKeysToRemove },
          token
        );
        commit("walletPassFiles/setFilesKeysToAdd", [], { root: true });
        commit("walletPassFiles/setFilesKeysToRemove", [], { root: true });
        if (!data.success) throw Error();
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
  },
};

// walletService.sendWalletPassEmail
// walletService.generateWalletPass
// walletService.getWalletPassDetails
// walletService.updateWalletPassDetails
