import qrDesignOptions from "l2qr-generator-api/src/constants/options";
import { qrCodeMarkers } from "l2qr-generator-api/src/constants/assets";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import {
  GET_QR_CODE_DESIGN,
  UPDATE_QR_CODE_DESIGN,
  UPDATE_QR_CODE_KEY,
} from "@/services/qrDesign";
import {
  getColorType,
  getFrameSvg,
  getMarkersSvg,
  getQrDesignImageUrl,
} from "@/utils/generator";

const getInitialState = () => ({
  shownDownloadPopup: false,
  shownVectorPopup: false,
  qrDataFormat: "URL",
  activeStep: 1,

  disabledToggles: false,

  isEditingSwitch: false,
  isScanTrackingSwitch: false,
  disabledIsEditingSwitch: false,
  disabledScanTrackingSwitch: false,

  svgText: null,
  isLoadingQr: false,
  hadInput: false,
  loadingTimerId: null,
  LOADING_DELAY: 1200,

  content: "",
  qrCodeInstance: null,
  qrCodeDesign: { ...qrDesignOptions },
  markers: qrCodeMarkers[0]?.value,
  logoName: null,
  frameName: null,
  colorType: "solid",
  // frame_name: null,
  // shapes: 'squares',
  // markers: 'square-square',
  // logo_name: null,
  // foreground_color: '#000000',
  qrCode: null,
});

export const serializeQrCodeDesign = async (qrCodeDesign) => {
  const [markerSvg, frameSvg, backgroundImageUrl, imageUrl, logoUrl] =
    await Promise.all([
      getMarkersSvg(qrCodeDesign.marker_left_template),
      getFrameSvg(qrCodeDesign.frame_name),
      getQrDesignImageUrl(qrCodeDesign.background_image_key),
      getQrDesignImageUrl(qrCodeDesign.image_key),
      getQrDesignImageUrl(qrCodeDesign.logo_key),
    ]);

  return {
    ...qrCodeDesign,
    frame_svg: frameSvg,
    marker_left_svg: markerSvg,
    marker_right_svg: markerSvg,
    marker_bottom_svg: markerSvg,
    background_image_url: backgroundImageUrl,
    image_url: imageUrl,
    logo_url: logoUrl,
  };
};

const serializeUpdateQrCodeDesign = (getters, fileDetails) => {
  const {
    qrCodeDesign,
    frameName,
    markers,
    backgroundImageKey,
    imageKey,
    logoKey,
  } = getters;

  return {
    id: qrCodeDesign.id,
    oldPreviewId: qrCodeDesign.qr_code.preview_id,
    qrCodePreviewId: fileDetails.id,
    newDesign: {
      background_color: qrCodeDesign.background_color,
      foreground_color: qrCodeDesign.foreground_color,
      foreground_color_2: qrCodeDesign.foreground_color_2,
      is_gradient: qrCodeDesign.is_gradient,
      gradient_angle: qrCodeDesign.gradient_angle,
      is_radial_gradient: qrCodeDesign.is_radial_gradient,
      version: qrCodeDesign.version,
      frame_svg: null,
      frame_name: frameName,
      frame_text: qrCodeDesign.frame_text,
      frame_color: qrCodeDesign.frame_color,
      frame_color_2: qrCodeDesign.frame_color_2,
      frame_font: qrCodeDesign.frame_font,
      custom_marker_color: qrCodeDesign.custom_marker_color,
      marker_left_inner_color: qrCodeDesign.marker_left_inner_color,
      marker_right_inner_color: qrCodeDesign.marker_right_inner_color,
      marker_bottom_inner_color: qrCodeDesign.marker_bottom_inner_color,
      marker_left_outer_color: qrCodeDesign.marker_left_outer_color,
      marker_right_outer_color: qrCodeDesign.marker_right_outer_color,
      marker_bottom_outer_color: qrCodeDesign.marker_bottom_outer_color,
      marker_left_template: markers,
      marker_right_template: markers,
      marker_bottom_template: markers,
      marker_left_svg: null,
      marker_right_svg: null,
      marker_bottom_svg: null,
      image_url: null,
      background_image_url: null,
      background_image_key: backgroundImageKey,
      image_key: imageKey,
      shapes: qrCodeDesign.shapes,
      // logo_name: qrCodeDesign.logo_name,
      logo_url: null,
      logo_type: qrCodeDesign.logo_type,
      logo_key: logoKey,
    },
  };
};

export default {
  namespaced: true,
  state: {
    shownDownloadPopup: false,
    shownVectorPopup: false,
    qrDataFormat: "URL",
    activeStep: 1,

    disabledToggles: false,

    isEditingSwitch: false,
    isScanTrackingSwitch: false,
    disabledIsEditingSwitch: false,
    disabledScanTrackingSwitch: false,

    qrCodeSvg: null,
    isLoadingQr: false,
    hadInput: false,
    loadingTimerId: null,
    LOADING_DELAY: 1200,

    content: "",
    qrCodeInstance: null,
    qrCodeDesign: { ...qrDesignOptions },

    markers: qrCodeMarkers[0]?.value,
    logoName: null,
    frameName: null,
    colorType: "solid",
    backgroundImageKey: null,
    imageKey: null,
    logoKey: null,

    // frame_name: null,
    // shapes: 'squares',
    // markers: 'square-square',
    // logo_name: null,
    // foreground_color: '#000000',
    qrCode: null,
  },
  getters: {
    translations: ({ translations }) => translations,
    shownDownloadPopup: ({ shownDownloadPopup }) => shownDownloadPopup,
    shownVectorPopup: ({ shownVectorPopup }) => shownVectorPopup,
    shownScanTrackingPopup: ({ shownScanTrackingPopup }) =>
      shownScanTrackingPopup,
    shownUploadFileMessage: ({ shownUploadFileMessage }) =>
      shownUploadFileMessage,
    activeStep: ({ activeStep }) => activeStep,

    isEditingSwitch: ({ isEditingSwitch }) => isEditingSwitch,
    isScanTrackingSwitch: ({ isScanTrackingSwitch }) => isScanTrackingSwitch,
    disabledIsEditingSwitch: ({ disabledIsEditingSwitch }) =>
      disabledIsEditingSwitch,
    disabledScanTrackingSwitch: ({ disabledScanTrackingSwitch }) =>
      disabledScanTrackingSwitch,
    isDynamicQrCode: ({ isScanTrackingSwitch, isEditingSwitch }) =>
      isScanTrackingSwitch || isEditingSwitch,

    isLoadingQr: ({ isLoadingQr }) => isLoadingQr,
    hadInput: ({ hadInput }) => hadInput,
    disabledPreview: ({ isLoadingQr, hadInput }) => !hadInput || isLoadingQr,
    loadingTimerId: ({ loadingTimerId }) => loadingTimerId,
    qrDataFormat: ({ qrDataFormat }) => qrDataFormat,
    qrCodeSvg: ({ qrCodeSvg }) => qrCodeSvg,

    LOADING_DELAY: ({ LOADING_DELAY }) => LOADING_DELAY,

    // frame_name: ({frame_name}) => frame_name,
    // shapes: ({shapes}) => shapes,
    // markers: ({markers}) => markers,
    // logo_name: ({logo_name}) => logo_name,
    // foreground_color: ({foreground_color}) => foreground_color,
    markers: ({ markers }) => markers,
    logoName: ({ logoName }) => logoName,
    frameName: ({ frameName }) => frameName,
    backgroundImageKey: ({ backgroundImageKey }) => backgroundImageKey,
    imageKey: ({ imageKey }) => imageKey,
    logoKey: ({ logoKey }) => logoKey,

    colorType: ({ colorType }) => colorType,
    getQrCodeToDesignKey: ({ qrCodeToDesignKey }) => qrCodeToDesignKey,

    qrCodeInstance: ({ qrCodeInstance }) => qrCodeInstance,
    qrCodeDesign: ({ qrCodeDesign }) => qrCodeDesign,
    qrCode: ({ qrCode }) => qrCode,
  },
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState());
    },

    showDownloadPopup(state, bool) {
      state.shownDownloadPopup = bool;
    },
    showVectorPopup(state, bool) {
      state.shownVectorPopup = bool;
    },
    showScanTrackingPopup(state, bool) {
      state.shownScanTrackingPopup = bool;
    },
    showUploadFileMessage(state, bool) {
      state.shownUploadFileMessage = bool;
    },

    generateQrStart(state, timerId) {
      state.isLoadingQr = true;
      state.loadingTimerId = timerId;
    },
    generateQrSuccess(state, qrCodeInstance) {
      state.isLoadingQr = false;
      state.loadingTimerId = null;
      state.qrCodeInstance = qrCodeInstance;
      state.hadInput = true;
    },
    generateQrFailure(state) {
      state.isLoadingQr = false;
      state.loadingTimerId = null;
    },
    setSvgText(state, svgText) {
      state.svgText = svgText;
    },

    setQrDataFormat(state, qrDataFormat = "URL") {
      state.qrDataFormat = qrDataFormat;
    },

    setContent(state, { content = "", type } = {}) {
      state[type || state.qrDataFormat] = content;
    },
    setFrameName(state, frame) {
      state.frameName = frame;
    },
    setBackgroundImageKey(state, backgroundImageKey) {
      state.backgroundImageKey = backgroundImageKey;
    },
    setImageKey(state, imageKey) {
      state.imageKey = imageKey;
    },
    setLogoKey(state, logoKey) {
      state.logoKey = logoKey;
    },
    // setShapes(state, shapes) {
    //   state.shapes = shapes
    // },
    setMarkers(state, markers) {
      state.markers = markers;
    },
    setForegroundColor(state, color) {
      state.foreground_color = color;
    },
    setLogoName(state, logo) {
      state.logoName = logo;
    },
    setIsDynamicQrCode(state, bool) {
      state.isDynamicQrCode = bool;
    },
    setColorType(state, color) {
      state.colorType = color;
    },
    setIsEditingSwitch(state, bool) {
      state.isEditingSwitch = bool;
    },
    setIsScanTrackingSwitch(state, bool) {
      state.isScanTrackingSwitch = bool;
    },
    setDisabledIsEditingSwitch(state, bool) {
      state.disabledIsEditingSwitch = bool;
    },
    setDisabledScanTrackingSwitch(state, bool) {
      state.disabledScanTrackingSwitch = bool;
    },
    setActiveStep(state, step) {
      state.activeStep = step;
    },
    updateQrCode(state, qrCode) {
      state.qrCode = qrCode;
    },
    setDisabledToggles(state, bool) {
      state.disabledToggles = bool;
    },
    setQrCodeDesign(state, qrCodeDesign = { ...qrDesignOptions }) {
      const newDesign = {};
      for (const prop in qrCodeDesign) {
        // if (!(prop in state.qrCodeDesign)) continue;
        newDesign[prop] = qrCodeDesign[prop];
      }
      Object.assign(state.qrCodeDesign, newDesign);
    },
    setQrCodeDesignKey(state, qrCodeToDesignKey = null) {
      state.shownDesignPopup = Boolean(qrCodeToDesignKey);
      state.qrCodeToDesignKey = qrCodeToDesignKey;
      state.qrCodeDesignStep = "main";
      state.setQrCodeInstance = null;
    },
    setQrCodeInstance(state, qrCodeInstance) {
      state.qrCodeInstance = qrCodeInstance;
    },
    setQrCodeSvg(state, qrCodeSvg) {
      state.qrCodeSvg = qrCodeSvg;
    },
    getQrCodeDesignStart(state) {
      state.loadingQrCodeDesign = true;
      state.qrCodeDesign = null;
    },
    getQrCodeDesignSuccess(state, qrCodeDesign) {
      state.loadingQrCodeDesign = false;
      state.qrCodeDesign = qrCodeDesign;
      state.frameName = qrCodeDesign.frame_name;
      state.markers = qrCodeDesign.marker_left_template;
      state.backgroundImageKey = qrCodeDesign.background_image_key;
      state.imageKey = qrCodeDesign.image_key;
      state.logoKey = qrCodeDesign.logo_key;
      state.colorType = getColorType(qrCodeDesign);
    },
    getQrCodeDesignFailure(state, e) {
      state.loadingQrCodeDesign = false;
      state.qrCodeDesignError = e;
    },
  },
  actions: {
    async getQrCodeDesign({ commit }, key) {
      try {
        commit("getQrCodeDesignStart");
        const { data } = await apollo.query({
          query: GET_QR_CODE_DESIGN,
          variables: { key },
        });
        const qrCodeDesign = await serializeQrCodeDesign(
          data.qr_code_designs?.[0]
        );
        commit("getQrCodeDesignSuccess", qrCodeDesign);
      } catch (e) {
        commit("getQrCodeDesignFailure", e);
      }
    },
    async updateQrCodeKey({ commit }, variables) {
      try {
        await apollo.mutate({
          mutation: UPDATE_QR_CODE_KEY,
          variables,
        });
        commit("updateQrCodeKeySuccess", variables);
        commit("manage/updateQrCodeListItem", variables, { root: true });
      } catch (e) {
        console.log(e);
        return Promise.reject("popup:design_qr_code:error:already_taken");
      }
    },
    async updateQrCodeDesign({ getters, dispatch }) {
      // upload file
      const fileDetails = await dispatch(
        "qrCode/uploadQrPreview",
        {
          preview: getters.qrCodeSvg,
          filename: getters.qrCodeDesign.qr_code.key,
        },
        { root: true }
      );

      const variables = serializeUpdateQrCodeDesign(getters, fileDetails);

      await apollo.mutate({
        mutation: UPDATE_QR_CODE_DESIGN,
        variables,
      });

      await dispatch(
        "files/updateFileParams",
        { qrCodeId: getters.qrCodeDesign.qr_code.id },
        { root: true }
      );

      return {
        previewKey: fileDetails.key,
        qrCodeId: getters.qrCodeDesign.qr_code.id,
      };
    },
  },
};
