<template>
  <div id="app">
    <transition name="fade">
      <component :is="layout">
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script>
// todo transition for views

export default {
  name: "App",
  components: {
    AuthLayout: () => import("@/layouts/AuthLayout.vue"),
    BackofficeLayout: () => import("@/layouts/BackofficeLayout.vue"),
    AccountLayout: () => import("@/layouts/AccountLayout.vue"),
    WalletLayout: () => import("@/layouts/WalletLayout.vue"),
    EmptyLayout: () => import("@/layouts/EmptyLayout.vue"),
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout;
      return layout ? `${layout}Layout` : "div";
    },
  },
};
</script>
