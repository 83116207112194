import gql from "graphql-tag";

export const GET_FOLDERS = gql`
  query getFolders($accountId: uuid!) {
    all_qr_codes: qr_codes_aggregate(
      where: { account_id: { _eq: $accountId } }
    ) {
      aggregate {
        count
      }
    }
    active_qr_codes: qr_codes_aggregate(
      where: { account_id: { _eq: $accountId }, status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    paused_qr_codes: qr_codes_aggregate(
      where: { account_id: { _eq: $accountId }, status: { _eq: "paused" } }
    ) {
      aggregate {
        count
      }
    }
    folders(
      where: { account_id: { _eq: $accountId } }
      order_by: { created_at: asc }
    ) {
      id
      name
      qr_codes_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_QR_CODES_LIST = gql`
  query getQrCodesList(
    $where: qr_codes_bool_exp!
    $orderBy: [qr_codes_order_by!]!
    $limit: Int = 20
    $offset: Int = 0
  ) {
    qr_codes_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    qr_codes(
      # account_id
      # folder_id?
      # status?
      where: $where
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      name
      created_at
      type
      key
      data_format
      status
      templateId: content(path: "template")
      organization {
        id
        name
      }
      content(path: "url")
      scans_aggregate {
        aggregate {
          count
        }
      }
      folder {
        id
        name
      }
      preview {
        key
      }
    }
  }
`;

export const CREATE_FOLDER = gql`
  mutation createFolder($id: uuid!, $name: String!, $accountId: uuid!) {
    insert_folders(objects: { id: $id, name: $name, account_id: $accountId }) {
      affected_rows
    }
  }
`;

export const RENAME_FOLDER = gql`
  mutation renameFolder($id: uuid!, $name: String!) {
    update_folders(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation deleteFolder($id: uuid!) {
    delete_folders(where: { id: { _eq: $id } }) {
      affected_rows
    }
    update_qr_codes(
      where: { folder_id: { _eq: $id } }
      _set: { folder_id: null }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_QR_CODE_NAME = gql`
  mutation updateQrCodeName($id: uuid!, $name: String!) {
    update_qr_codes(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const CHANGE_QR_CODES_STATUS = gql`
  mutation changeQrCodesStatus($ids: [uuid!]!, $status: String!) {
    update_qr_codes(
      where: { id: { _in: $ids }, type: { _eq: "dynamic" } }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`;

export const MOVE_QR_CODES_TO_FOLDER = gql`
  mutation moveQrCodesToFolder($ids: [uuid!]!, $folderId: uuid = null) {
    update_qr_codes(
      where: { id: { _in: $ids } }
      _set: { folder_id: $folderId }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_QR_CODES = gql`
  mutation removeQrCodes($ids: [uuid!]!) {
    update_files(
      where: { qr_code_id: { _in: $ids } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    delete_qr_code_scans(where: { qr_code_id: { _in: $ids } }) {
      affected_rows
    }
    delete_qr_code_designs(where: { qr_code: { id: { _in: $ids } } }) {
      affected_rows
    }
    delete_qr_codes(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
