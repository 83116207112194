import Vue from "vue";
import Vuex from "vuex";

import notification from "@/store/modules/notification";
import helpCenter from "@/store/modules/help-center";
import account from "@/store/modules/account";
import manage from "@/store/modules/manage";
import news from "@/store/modules/news";
import auth from "@/store/modules/auth";
import files from "@/store/modules/files";
import qrCode from "@/store/modules/qrCode";
import device from "@/store/modules/device";
import qrCodeDetails from "@/store/modules/qrCodeDetails";
import qrDesign from "@/store/modules/qrDesign";
import payment from "@/store/modules/payment";
import generator from "@/store/modules/generator";
import organizations from "@/store/modules/organizations";
import accountOrganizations from "@/store/modules/accountOrganizations";

import accountSettings from "@/store/modules/settings/account";
import userSettings from "@/store/modules/settings/user";
import apiSettings from "@/store/modules/settings/api";
import billingSettings from "@/store/modules/settings/billing";

// backoffice
import backofficePanel from "@/store/modules/backoffice/panel";
import backofficeAccount from "@/store/modules/backoffice/account";
import backofficeHelpdesk from "@/store/modules/backoffice/helpdesk";
import backofficeManager from "@/store/modules/backoffice/manager";
import backofficeFiles from "@/store/modules/backoffice/files";
import backofficeInternationalization from "@/store/modules/backoffice/internationalization";
import backofficeArticles from "@/store/modules/backoffice/articles";
import backofficeDashboard from "@/store/modules/backoffice/dashboard";

//
import mainNewGenerator from "@/store/mainNewGenerator";

// walletPass
import wallet from "@/store/modules/wallet";
import walletPassFiles from "@/store/modules/wallet-pass/files";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notification,
    helpCenter,
    account,
    manage,
    news,
    auth,
    files,
    device,
    qrCode,
    qrDesign,
    qrCodeDetails,
    accountSettings,
    userSettings,
    apiSettings,
    billingSettings,
    payment,
    generator,
    organizations,
    accountOrganizations,
    // backoffice
    backofficePanel,
    backofficeAccount,
    backofficeHelpdesk,
    backofficeManager,
    backofficeFiles,
    backofficeInternationalization,
    mainNewGenerator,
    backofficeArticles,
    backofficeDashboard,
    // walletPass
    wallet,
    walletPassFiles,
  },
  state: {
    language: "en",
  },
  getters: {
    language: ({ language }) => language,
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
  },
});
