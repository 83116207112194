import { getFirstLanguage, setLanguage } from "@/plugins/locale/locales";
import { addRoutesLocaleHook } from "@/plugins/locale/messages";

export default async () => {
  // 1. Отримання локалі з query або cookie
  const locale = getFirstLanguage();
  // 2. Збереження локалі в cookie та store
  setLanguage(locale);
  // 3. Router Hook - beforeEach
  addRoutesLocaleHook();
};
