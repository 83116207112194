import L2QrLogoIcon from "@/assets/img/logo.svg?raw";

export const createLoader = () => {
  const loader = document.createElement("div");
  const loaderLink = document.createElement("div");

  loaderLink.innerHTML = L2QrLogoIcon;

  loaderLink.classList.add("l2qr-loader__link");
  loader.classList.add("l2qr-loader");

  loader.append(loaderLink);
  return loader;
};
