import gql from "graphql-tag";

export const GET_ACCOUNTS = gql`
  query getManagerAccount($ownerId: uuid!) {
    accounts(where: { owner_id: { _eq: $ownerId } }) {
      id
      contacts {
        first_name
        last_name
        company
      }
      owner {
        id
        email
        avatar_url
        manager_roles {
          role {
            id
            label
          }
        }
      }
    }
  }
`;
