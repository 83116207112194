import gql from "graphql-tag";

export const GET_ACCOUNT_SETTINGS = gql`
  query getAccountSettings {
    accounts {
      # account info
      id
      created_at
      customer_id
      account_plan {
        expiry_date
        plan {
          label
          # usage
          dynamic_qr_codes
          static_qr_codes
          dynamic_business_card
          limit_space
          scans
          api_requests
        }
      }
      used_resources {
        used_scans
        used_storage
        used_static_qr_codes
        used_business_cards
        used_dynamic_qr_codes
        used_api_requests
      }

      # account contact
      owner {
        email
      }
      contacts {
        phone
        first_name
        last_name
        company
        country
        street
        zip
        city
        website
      }
    }
  }
`;

export const UPDATE_ACCOUNT_CONTACTS = gql`
  mutation updateAccountContacts(
    $first_name: String = ""
    $last_name: String = ""
    $company: String = ""
    $website: String = ""
    $country: String = ""
    $phone: String = ""
    $street: String = ""
    $city: String = ""
    $zip: String = ""
  ) {
    update_account_contacts(
      where: {}
      _set: {
        first_name: $first_name
        last_name: $last_name
        company: $company
        website: $website
        country: $country
        phone: $phone
        street: $street
        city: $city
        zip: $zip
      }
    ) {
      affected_rows
    }
  }
`;
