import router from "@/router";
import i18n from "@/plugins/i18n";
import store from "@/store";
import { GET_TRANSLATIONS } from "@/services/locales";
import { anonymousClient } from "@/plugins/apollo/apollo-anonymous";
import { setLanguage } from "@/plugins/locale/locales";

export const fetchMessages = async (language, routeName) => {
  const { data } = await anonymousClient.query({
    query: GET_TRANSLATIONS,
    variables: {
      page: routeName || "error",
      language,
    },
  });
  const translations = data?.translations || [];
  return translations.reduce((acc, cur) => {
    acc[cur.key] = cur.value;
    return acc;
  }, {});
};

export const addRoutesLocaleHook = () => {
  router.beforeEach(async (to, from, next) => {
    const language = store.getters["language"];

    const messages = await fetchMessages(language, to.name); // Передача route.name
    i18n.mergeLocaleMessage(language, messages);
    // Продовження навігації
    next();
  });
};

export const updateLocale = async (language, routerName) => {
  setLanguage(language);
  const messages = await fetchMessages(language, routerName);
  i18n.mergeLocaleMessage(language, messages);
};
