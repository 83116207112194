import axios from "@/plugins/axios";
import gql from "graphql-tag";

const changePassword = (passwords) => {
  return axios.post("/settings-change-password", passwords);
};
const changeEmail = (credentials) => {
  return axios.post("/settings-change-email", credentials);
};
const closeAccount = (credentials) => {
  return axios.post("/close-account", credentials);
};

export const GET_USER_SETTINGS = gql`
  query getUserSettings($userId: uuid!) {
    users(where: { id: { _eq: $userId } }) {
      email
    }
    accounts {
      software_preferences {
        time_zone
        language
      }
      contacts {
        first_name
        last_name
      }
    }
    locales(distinct_on: language_code) {
      language
      language_code
    }
  }
`;

export const UPDATE_SOFTWARE_PREFERENCES = gql`
  mutation updateSoftwarePreferences($time_zone: String!, $language: String!) {
    update_software_preferences(
      where: {}
      _set: { time_zone: $time_zone, language: $language }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($first_name: String = "", $last_name: String = "") {
    update_account_contacts(
      where: {}
      _set: { first_name: $first_name, last_name: $last_name }
    ) {
      affected_rows
    }
  }
`;

export default {
  changePassword,
  changeEmail,
  closeAccount,
};
