import axios from "axios";
const TOKEN_ERRORS_MESSAGE = "Token expired!";
const REFRESH_TOKEN_URL = "/refresh_tokens";
import cookies from "cookie-universal";

const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_SERVER_URL,
});

instance.interceptors.request.use((configs) => {
  const accessToken = cookies().get("access-token");
  configs.headers["Authorization"] = `Bearer ${accessToken}`;
  return configs;
});

instance.interceptors.response.use(
  (config) => config,
  async (error) => {
    const store = require("../store").default;
    const originalRequest = error.config;

    // todo single error handler
    if (
      error.response?.data?.message === TOKEN_ERRORS_MESSAGE &&
      !originalRequest._retry &&
      originalRequest.url !== REFRESH_TOKEN_URL
    ) {
      originalRequest._retry = true;
      await store.dispatch("auth/refreshToken");
      return instance(originalRequest);
    }

    const serverErrorMessage =
      error.response?.data?.message || error.response?.data?.error;
    if (serverErrorMessage)
      return Promise.reject(new Error(serverErrorMessage));
    return Promise.reject(error);
  }
);

export default instance;
