import { GET_ORGANIZATIONS } from "@/services/accountOrganizations";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { getTokenOrganizationId } from "@/utils/token";
import { ownerRole } from "@/constants/organizations";

const serializeOrganizations = (data) => {
  const organizations = data.organizations || [];
  const defaultOrganizationId = organizations?.findIndex((o) => o.is_default);
  if (defaultOrganizationId !== -1) {
    const defaultOrganization = organizations.splice(defaultOrganizationId, 1);
    organizations.splice(0, 0, defaultOrganization[0]);
  }
  return {
    organizations,
    activeOrganization: data.organizations_by_pk,
    organizationsRoles: data.organization_roles || [],
  };
};

const initState = () => ({
  organizations: [],
  organizationsLoading: false,
  organizationsErrors: null,

  activeOrganization: null,
  organizationsRoles: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    organizations: ({ organizations }) => organizations,
    organizationsLoading: ({ organizationsLoading }) => organizationsLoading,
    organizationsErrors: ({ organizationsErrors }) => organizationsErrors,

    activeOrganization: ({ activeOrganization }) => activeOrganization,
    organizationsRoles: ({ organizationsRoles }) => organizationsRoles,

    // permissions
    activeRoleId: ({ activeOrganization }, getters, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return ownerRole.value;
      return activeOrganization?.roles_users?.[0]?.role_id;
    },
    activeRole: ({ organizationsRoles }, { activeRoleId }) => {
      return organizationsRoles?.find?.((r) => r.id === activeRoleId);
    },
    canCreateQr: (state, { activeRole }, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return true;
      return activeRole?.create_qr;
    },
    canReadQr: (state, { activeRole }, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return true;
      return activeRole?.read_qr;
    },
    canUpdateQr: (state, { activeRole }, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return true;
      return activeRole?.update_qr;
    },
    canDeleteQr: (state, { activeRole }, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return true;
      return activeRole?.delete_qr;
    },
    canManageOrganizations: (state, { activeRole }, rootState, rootGetters) => {
      if (rootGetters["account/isOwner"]) return true;
      return activeRole?.manage_organization;
    },
    isDefaultOrganization: (state, { activeOrganization }) =>
      activeOrganization?.is_default,
    // limits
    availableOrganizationQrCodes: (state, { activeOrganization }) =>
      activeOrganization?.organization_resources?.available_qr_codes || 0,
    availableOrganizationVCards: (state, { activeOrganization }) =>
      activeOrganization?.organization_resources?.available_vcards || 0,
    availableOrganizationUsers: (state, getters, rootState, rootGetters) =>
      rootGetters?.["account/accountPlan"]?.organization_users_limit || 0,
    usedOrganizationQrCodes: (state, { activeOrganization }) =>
      activeOrganization?.organization_resources?.used_qr_codes || 0,
    usedOrganizationVCards: (state, { activeOrganization }) =>
      activeOrganization?.organization_resources?.used_vcards || 0,
    reachedOrganizationQrCodesLimit: (state, getters) => {
      console.log("1234", getters.isDefaultOrganization);
      if (!getters.activeOrganization || getters.isDefaultOrganization)
        return false;
      return (
        getters.availableOrganizationQrCodes <= getters.usedOrganizationQrCodes
      );
    },
    reachedOrganizationVCardsLimit: (state, getters) => {
      if (!getters.activeOrganization || getters.isDefaultOrganization)
        return false;
      return (
        getters.availableOrganizationVCards <= getters.usedOrganizationVCards
      );
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state);
    },
    getOrganizationsStart(state) {
      state.organizations = [];
      state.organizationsLoading = true;
      state.organizationsErrors = null;

      state.activeOrganization = null;
      state.organizationsRoles = [];
    },
    getOrganizationsSuccess(state, payload) {
      state.organizations = payload.organizations || [];
      state.organizationsLoading = false;
      state.organizationsErrors = null;

      state.activeOrganization = payload.activeOrganization;
      state.organizationsRoles = payload.organizationsRoles;
    },
    getOrganizationsFailure(state, errors) {
      state.organizations = [];
      state.organizationsLoading = false;
      state.organizationsErrors = errors;

      state.activeOrganization = null;
      state.organizationsRoles = [];
    },
    setActiveOrganization(state, organization = null) {
      state.activeOrganization = organization;
    },
  },
  actions: {
    async getOrganizations({ commit }) {
      try {
        commit("getOrganizationsStart");
        const { data, error } = await apollo.query({
          query: GET_ORGANIZATIONS,
          variables: { organizationId: getTokenOrganizationId() },
        });
        if (error) throw Error(error);
        commit("getOrganizationsSuccess", serializeOrganizations(data));
      } catch (e) {
        commit("getOrganizationsFailure", e);
      }
    },
    async getUpdatedOrganizations({ commit }) {
      try {
        const { data, error } = await apollo.query({
          query: GET_ORGANIZATIONS,
          variables: { organizationId: getTokenOrganizationId() },
        });
        if (error) throw Error(error);
        commit("getOrganizationsSuccess", serializeOrganizations(data));
      } catch (e) {
        console.log(e);
      }
    },
  },
};
