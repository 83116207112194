import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "manage" },
    // component: () =>
    //   import(/* webpackChunkName: "home view" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "Auth" },
    component: () =>
      import(/* webpackChunkName: "login view" */ "../views/LoginView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    meta: { layout: "Auth" },
    component: () =>
      import(/* webpackChunkName: "signup view" */ "../views/SignupView.vue"),
  },
  {
    path: "/auth/callback",
    name: "auth-callback",
    meta: { layout: "Empty" },
    component: () =>
      import(
        /* webpackChunkName: "auth callback view" */ "../views/auth/callback"
      ),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { layout: "Auth" },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword view" */ "../views/reset-password/index.vue"
      ),
  },
  {
    path: "/reset-password/change",
    name: "reset-password-change",
    meta: { layout: "Auth" },
    component: () =>
      import(
        /* webpackChunkName: "ResetPasswordChange view" */ "../views/reset-password/change.vue"
      ),
  },
  {
    path: "/manage",
    name: "manage",
    meta: { layout: "Account" },
    component: () =>
      import(/* webpackChunkName: "Manage view" */ "../views/manage/index.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { layout: "Account" },
    component: () =>
      import(/* webpackChunkName: "Settings view" */ "../views/settings"),
    children: [
      {
        path: "account",
        name: "settings-account",
        meta: { layout: "Account" },
        component: () =>
          import(
            /* webpackChunkName: "SettingsAccount view" */ "../views/settings/account"
          ),
      },
      {
        path: "user",
        name: "settings-user",
        meta: { layout: "Account" },
        component: () =>
          import(
            /* webpackChunkName: "SettingsUser view" */ "../views/settings/user"
          ),
      },
      {
        path: "api",
        name: "settings-api",
        meta: { layout: "Account" },
        component: () =>
          import(/* webpackChunkName: "SettingsApi" */ "../views/settings/api"),
      },
      {
        path: "api/:id",
        name: "settings-api-key",
        meta: { layout: "Account" },
        component: () =>
          import(
            /* webpackChunkName: "SettingsApiKey" */ "../views/settings/apiKey"
          ),
      },
      {
        path: "billing",
        name: "settings-billing",
        meta: { layout: "Account" },
        component: () =>
          import(
            /* webpackChunkName: "SettingsBilling view" */ "../views/settings/billing"
          ),
      },
    ],
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "changePassword view" */ "../views/change-password"
      ),
  },
  {
    path: "/change-email",
    name: "change-email",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "changeEmail view" */ "../views/change-email"
      ),
  },
  {
    path: "/close-account",
    name: "close-account",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "SettingsBilling view" */ "../views/close-account"
      ),
  },
  {
    path: "/features-and-pricing",
    name: "features-and-pricing",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "FeaturesAndPricing view" */ "../views/features-and-pricing"
      ),
  },
  {
    path: "/payment",
    name: "payment",
    meta: { layout: "Empty" },
    component: () =>
      import(/* webpackChunkName: "Payment view" */ "../views/payment"),
  },
  {
    path: "/payment-upgrade",
    name: "paymentUpgrade",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "Payment upgrade view" */ "../views/PaymentUpgrade"
      ),
  },
  {
    path: "/payment-success",
    name: "paymentSuccess",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "Payment success view" */ "../views/PaymentSuccess"
      ),
  },
  {
    path: "/payment-fail",
    name: "paymentFail",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "Payment fail view" */ "../views/PaymentFail"
      ),
  },
  {
    path: "/qr/create",
    name: "qr-create",
    meta: { layout: "Account" },
    component: () =>
      import(/* webpackChunkName: "QrCreate view" */ "../views/qr/create"),
  },
  {
    path: "/qr/edit/:key",
    name: "qr-edit-key",
    meta: { layout: "Account" },
    component: () =>
      import(/* webpackChunkName: "QrEdit view" */ "../views/qr/edit/_key"),
  },
  {
    path: "/qr/design/:key",
    name: "qr-design-key",
    meta: { layout: "Account" },
    component: () =>
      import(/* webpackChunkName: "QrDesign view" */ "../views/qr/design/_key"),
  },
  {
    path: "/qr/details/:key",
    name: "qr-details-key",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "QrDetails view" */ "../views/qr/details/_key"
      ),
  },
  {
    path: "/backoffice",
    name: "backoffice",
    meta: { layout: "Auth" },
    component: () =>
      import(
        /* webpackChunkName: "Backoffice view" */ "../views/backoffice/index.vue"
      ),
  },
  {
    path: "/backoffice/panel",
    name: "backoffice-panel",
    meta: { layout: "Backoffice" },
    redirect: { name: "backoffice-panel-dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "Backoffice Panel view" */ "../views/backoffice/panel.vue"
      ),
    children: [
      {
        path: "dashboard",
        name: "backoffice-panel-dashboard",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice dashboard view" */ "../views/backoffice/panel/dashboard.vue"
          ),
      },
      {
        path: "internationalization",
        name: "backoffice-panel-internationalization",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice internationalization view" */ "../views/backoffice/panel/internationalization.vue"
          ),
      },
      {
        path: "helpdesk",
        name: "backoffice-panel-helpdesk",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice help center view" */ "../views/backoffice/panel/helpdesk/index.vue"
          ),
      },
      {
        path: "helpdesk/:ticket",
        name: "backoffice-panel-helpdesk-ticket",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice help center ticket view" */ "../views/backoffice/panel/helpdesk/_ticket.vue"
          ),
      },
      {
        path: "articles",
        name: "backoffice-panel-articles",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice articles view" */ "../views/backoffice/panel/articles/index.vue"
          ),
      },
      {
        path: "articles/create",
        name: "backoffice-panel-articles-create",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice articles create view" */ "../views/backoffice/panel/articles/create.vue"
          ),
      },
      {
        path: "articles/:id",
        name: "backoffice-panel-articles-id",
        meta: { layout: "Backoffice" },
        component: () =>
          import(
            /* webpackChunkName: "Backoffice articles id view" */ "../views/backoffice/panel/articles/_id.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/test-generator",
  //   name: "test",
  //   meta: { layout: "Auth" },
  //   component: () =>
  //     import(/* webpackChunkName: "test view" */ "../views/Test.vue"),
  // },
  //     ORGANIZATIONS
  {
    path: "/organizations",
    name: "organizations",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "organizations view" */ "../views/Organizations.vue"
      ),
  },
  {
    path: "/organizations/:id/users",
    name: "organization-id-users",
    meta: { layout: "Account" },
    component: () =>
      import(
        /* webpackChunkName: "organization users view" */ "../views/OrganizationUsers.vue"
      ),
  },
  {
    path: "/organization-invitation",
    name: "organization-invitation",
    meta: { layout: "Auth" },
    component: () =>
      import(
        /* webpackChunkName: "Organization Invitation view" */ "../views/OrganizationInvitation.vue"
      ),
  },
  // wallet pass
  {
    path: "/wallet-pass/:token",
    name: "wallet-pass-token",
    meta: { layout: "Wallet" },
    component: () =>
      import(
        /* webpackChunkName: "Wallet pass token view" */ "../views/walletPass/_token.vue"
      ),
  },
  {
    path: "/wallet-pass/:type/:qrCodeKey",
    name: "wallet-pass-generate",
    meta: { layout: "empty" },
    component: () =>
      import(
        /* webpackChunkName: "Wallet pass generate view" */ "../views/walletPass/_generate.vue"
      ),
  },
  {
    path: "*",
    redirect: { name: "manage" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
