import {
  GET_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  GET_ORGANIZATION_USERS,
  UPDATE_ORGANIZATION_USER,
  DELETE_ORGANIZATION_USER,
  inviteOrganizationUser,
  setOrganizationUserPassword,
} from "@/services/organizations";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import i18n from "@/plugins/i18n";

const initState = () => ({
  organizationsSearch: "",
  organizationUsersSearch: "",
  userRolesFilter: null,

  organizations: [],
  organizationsLoading: false,
  organizationsError: null,

  organizationUsers: [],
  organizationOwner: null,
  organizationUsersLoading: false,
  organizationUsersError: null,
  // loadedAll: false,
  // organizationsMoreLoading: false,
  // offset: 0,
  // limit: 10,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    organizationsSearch: ({ organizationsSearch }) => organizationsSearch,
    organizationUsersSearch: ({ organizationUsersSearch }) =>
      organizationUsersSearch,
    userRolesFilter: ({ userRolesFilter }) => userRolesFilter,

    organizations: ({ organizations }) => organizations,
    organizationsLoading: ({ organizationsLoading }) => organizationsLoading,
    organizationsError: ({ organizationsError }) => organizationsError,
    organizationUsers: ({ organizationUsers }) => organizationUsers,
    organizationOwner: ({ organizationOwner }) => organizationOwner,
    organizationUsersLoading: ({ organizationUsersLoading }) =>
      organizationUsersLoading,
    organizationUsersError: ({ organizationUsersError }) =>
      organizationUsersError,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    searchOrganizations(state, value = "") {
      state.organizationsSearch = value;
    },
    searchOrganizationUsers(state, value = "") {
      state.organizationUsersSearch = value;
    },
    filterUserRoles(state, value) {
      state.userRolesFilter = value;
    },
    // get organization list
    getOrganizationsStart(state) {
      // state.loadedAll = false;
      // state.offset = 0;

      state.organizationsLoading = true;
      state.organizationsError = null;
      state.organizations = [];
    },
    getOrganizationsSuccess(state, organizations) {
      state.organizationsLoading = false;
      state.organizations = organizations;
    },
    getOrganizationsFailure(state, e) {
      state.organizationsLoading = false;
      state.organizationsError = e;
      state.organizations = [];
    },
    // get more organization list
    // getMoreOrganizationStart(state) {
    //     state.organizationMoreLoading = true;
    //     state.organizationError = null;
    //     state.offset += state.limit;
    // },
    // getMoreOrganizationSuccess(state, organization) {
    //     state.organizationMoreLoading = false;
    //     state.organization = [...state.organization, ...(organization ?? [])];
    //     if (organization.length < state.limit) state.loadedAll = true;
    // },
    // getMoreOrganizationFailure(state, e) {
    //     state.offset = Math.max(0, state.offset - state.limit);
    //     state.organizationMoreLoading = false;
    //     state.organizationError = e;
    // },
    createOrganizationsSuccess(state, payload) {
      if (!payload) return;
      state.organizations = [payload, ...state.organizations];
    },
    updateOrganizationsSuccess(state, payload) {
      if (!payload) return;
      const idx = state.organizations.findIndex((o) => o.id === payload.id);
      if (idx === -1) return;
      state.organizations.splice(idx, 1, payload);
    },
    deleteOrganizationsSuccess(state, payload) {
      if (!payload) return;
      const idx = state.organizations.findIndex(
        (o) => o.id === payload.organizationId
      );
      if (idx === -1) return;
      state.organizations.splice(idx, 1);
    },
    // users
    // get organization list
    getOrganizationUsersStart(state) {
      // state.loadedAll = false;
      // state.offset = 0;

      state.organizationUsersLoading = true;
      state.organizationUsersError = null;
      state.organizationUsers = [];
      state.organizationOwner = null;
    },
    getOrganizationUsersSuccess(state, { owner, organizationUsers }) {
      state.organizationUsersLoading = false;
      state.organizationUsers = organizationUsers;
      state.organizationOwner = owner;
    },
    getOrganizationUsersFailure(state, e) {
      state.organizationUsersLoading = false;
      state.organizationUsersError = e;
      state.organizationUsers = [];
      state.organizationOwner = null;
    },
    createOrganizationUsersSuccess(state, payload) {
      if (!payload) return;
      state.organizationUsers = [payload, ...state.organizationUsers];
    },
    updateOrganizationUsersSuccess(state, payload) {
      if (!payload) return;
      const idx = state.organizationUsers.findIndex((o) => o.id === payload.id);
      if (idx === -1) return;
      state.organizationUsers.splice(idx, 1, payload);
    },
    deleteOrganizationUsersSuccess(state, payload) {
      if (!payload) return;
      const idx = state.organizationUsers.findIndex((o) => o.id === payload.id);
      if (idx === -1) return;
      state.organizationUsers.splice(idx, 1);
    },
  },
  actions: {
    async getOrganizations({ commit }) {
      try {
        commit("getOrganizationsStart");
        const { data } = await apollo.query({
          query: GET_ORGANIZATIONS,
        });
        commit("getOrganizationsSuccess", data.organizations);
      } catch (e) {
        console.log(e);
        commit("getOrganizationsFailure", e);
      }
    },
    // async getMoreOrganizations({ commit, rootGetters, getters }) {
    //     try {
    //         // if (getters["loadedAll"]) return;
    //         commit("getMoreOrganizationsStart");
    //         const { data } = await apollo.query({
    //             query: GET_ORGANIZATIONS,
    //             // variables: {
    //             //     accountId,
    //             //     limit: getters["limit"],
    //             //     offset: getters["offset"],
    //             // },
    //         });
    //
    //         commit("getMoreOrganizationsSuccess", data.organizations);
    //     } catch (e) {
    //         commit("getMoreOrganizationsFailure", e);
    //     }
    // },
    async createOrganizations({ commit, rootGetters }, variables) {
      const accountId = rootGetters["account/account"]?.id;
      try {
        const { data } = await apollo.query({
          query: CREATE_ORGANIZATION,
          variables: { accountId, ...variables },
        });
        commit(
          "createOrganizationsSuccess",
          data.insert_organizations?.returning?.[0]
        );
      } catch (e) {
        console.log(e);
      }
    },
    async updateOrganization({ commit }, variables) {
      try {
        const { data } = await apollo.query({
          query: UPDATE_ORGANIZATION,
          variables,
        });
        commit(
          "updateOrganizationsSuccess",
          data.update_organizations?.returning?.[0]
        );
      } catch (e) {
        console.log(e);
      }
    },
    async deleteOrganizations({ commit }, variables) {
      try {
        await apollo.query({
          query: DELETE_ORGANIZATION,
          variables,
        });
        commit("deleteOrganizationsSuccess", variables);
      } catch (e) {
        console.log(e);
      }
    },
    // users
    async getOrganizationUsers({ commit }, variables) {
      try {
        commit("getOrganizationUsersStart");
        const { data } = await apollo.query({
          query: GET_ORGANIZATION_USERS,
          variables,
        });
        let owner = { ...(data?.accounts[0]?.owner || {}), isOwner: true };
        if (owner) owner = { user: owner, isOwner: true };
        const organizationUsers = data.organizations_roles_users;
        commit("getOrganizationUsersSuccess", { owner, organizationUsers });
      } catch (e) {
        console.log(e);
        commit("getOrganizationUsersFailure", e);
      }
    },
    async createOrganizationUser({ commit, rootGetters }, variables) {
      const accountId = rootGetters["account/account"]?.id;
      try {
        const { data } = await inviteOrganizationUser({
          accountId,
          ...variables,
        });
        commit("createOrganizationUsersSuccess", data);
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
    async updateOrganizationUser({ commit }, variables) {
      try {
        const { data } = await apollo.query({
          query: UPDATE_ORGANIZATION_USER,
          variables,
        });
        commit(
          "updateOrganizationUsersSuccess",
          data.update_organizations_roles_users?.returning?.[0]
        );
      } catch (e) {
        console.log(e);
      }
    },
    async deleteOrganizationUser({ commit }, variables) {
      try {
        await apollo.query({
          query: DELETE_ORGANIZATION_USER,
          variables,
        });
        commit("deleteOrganizationUsersSuccess", variables);
      } catch (e) {
        console.log(e);
      }
    },
    async setOrganizationUserPassword({ dispatch }, credentials) {
      try {
        await setOrganizationUserPassword(credentials);
      } catch (e) {
        dispatch(
          "notification/showNotification",
          {
            message: i18n.t(e.message || "notifications:error:something_wrong"),
            type: "error",
          },
          { root: true }
        );
        return Promise.reject(e);
      }
    },
  },
};
