import {
  creationQrCodeSteps,
  dynamicQrCodeWebsite,
} from "@/constants/qr-codes";
import { defaultQrCodeName, getPageUrl } from "@/utils/qr-code-templates";
import { generateQrCodeSvg } from "l2qr-generator-api/dist/client/index";
import QrCodeService, {
  INSERT_QR_CODE,
  GET_QR_CODE_DETAILS,
  UPDATE_QR_CODE,
  GET_ALL_QR_CODE_TYPES,
} from "@/services/qrCode";
import { svgToPngBlob } from "@/utils/images-formats";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { getOrganizationHeader } from "@/constants/backoffice/apollo-client";

const serializeNewQrCode = (rootGetters, getters, variables) => {
  const account_id = rootGetters["account/account"]?.id;
  const organization_id =
    rootGetters["accountOrganizations/activeOrganization"]?.id || null;

  const newQrCode = {
    ...getters.qrCode,
    name: getters.qrCode?.name || defaultQrCodeName(getters.qrCode),
    account_id,
    organization_id,
    ...variables,
  };

  delete newQrCode.__typename;

  return newQrCode;
};
const serializeUpdatedQrCode = (getters) => ({
  id: getters.qrCode.id,
  content: getters.qrCode.content,
  name: getters.qrCode?.name || defaultQrCodeName(getters.qrCode),
  // status: getters.qrCode?.status || "paused",
});

const initState = () => ({
  PREVIEW_WIDTH: 300,
  // qr-type | page-configs
  creationQrCodeStep: creationQrCodeSteps[0],
  activeQrCodeTemplate: dynamicQrCodeWebsite,
  qrCode: null,
  loading: false,
  error: null,
  shownPreviewLoader: false,
  shownPreviewMobile: false,
  shownQrCodeMobile: false,
  qrCodeConfigsRules: {},
  validationErrors: null,
  qrCodeForm: null,
  qrPreview: null,
  qrCodeFiles: [],
  qrCodeTypes: [],
  qrCodeTemplates: [],
  activeQrCodeConfig: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    PREVIEW_WIDTH: ({ PREVIEW_WIDTH }) => PREVIEW_WIDTH,
    activeQrCodeTemplate: ({ activeQrCodeTemplate }) => activeQrCodeTemplate,
    qrCode: ({ qrCode }) => qrCode,
    qrCodeConfigsRules: ({ qrCodeConfigsRules }) => qrCodeConfigsRules,
    creationQrCodeStep: ({ creationQrCodeStep }) => creationQrCodeStep,
    loading: ({ loading }) => loading,
    shownPreviewMobile: ({ shownPreviewMobile }) => shownPreviewMobile,
    shownQrCodeMobile: ({ shownQrCodeMobile }) => shownQrCodeMobile,
    validationErrors: ({ validationErrors }) => validationErrors,
    qrCodeForm: ({ qrCodeForm }) => qrCodeForm,
    qrCodeContent: ({ qrCode }) => qrCode?.content,
    qrPreview: ({ qrPreview }) => qrPreview,
    qrCodeFiles: ({ qrCodeFiles }) => qrCodeFiles,
    shownPreviewLoader: ({ shownPreviewLoader }) => shownPreviewLoader,
    qrCodeTypes: ({ qrCodeTypes }) => qrCodeTypes,
    qrCodeTemplates: ({ qrCodeTemplates }) => qrCodeTemplates,
    activeQrCodeConfig: ({ activeQrCodeConfig }) => activeQrCodeConfig,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    showPreviewLoader(state, bool) {
      state.shownPreviewLoader = bool;
    },
    setActiveQrCodeTemplate(state, qrCode) {
      state.activeQrCodeTemplate = qrCode;
    },
    setQrCodeConfigsRules(state, configsRules) {
      state.qrCodeConfigsRules = configsRules;
    },
    setQrCode(state, qrCode) {
      state.qrCode = qrCode;
    },
    updateQrCodeProps(state, { prop, value } = {}) {
      state.qrCode[prop] = value;
    },
    updateQrCodeConfigs(state, { prop, value } = {}) {
      state.activeQrCodeConfig[prop] = value;
    },
    updateCreationQrCodeStep(state, step) {
      state.creationQrCodeStep = step;
    },
    showPreviewMobile(state, bool = false) {
      state.shownPreviewMobile = bool;
    },
    showQrCodeMobile(state, bool = false) {
      state.shownQrCodeMobile = bool;
    },
    setValidationErrors(state, errors = null) {
      state.validationErrors = errors;
    },
    setQrCodeForm(state, form = null) {
      state.qrCodeForm = form;
    },
    generateQrPreview(state, qrPreview) {
      state.qrPreview = qrPreview;
    },
    createQrCodeStart(state) {
      state.loading = true;
      state.error = null;
    },
    createQrCodeSuccess(state) {
      state.loading = false;
    },
    createQrCodeFailure(state, e) {
      state.loading = false;
      state.error = e;
    },
    setQrCodeFiles(state, files = []) {
      state.qrCodeFiles = files;
    },
    addQrCodeFiles(state, file) {
      state.qrCodeFiles.push(file);
    },
    removeQrCodeFiles(state, file) {
      state.qrCodeFiles = state.qrCodeFiles.filter((f) => f.key !== file.key);
    },
    getQrCodeDetailsStart(state) {
      state.loading = true;
      state.error = null;
      state.qrCode = null;
      state.qrCodeFiles = null;
    },
    getQrCodeDetailsSuccess(state, { files, qrCode }) {
      state.loading = false;
      state.qrCode = qrCode;
      state.qrCodeFiles = files;
    },
    getQrCodeDetailsFailure(state, e) {
      state.loading = false;
      state.error = e;
      state.qrCode = null;
      state.qrCodeFiles = null;
    },
    setQrCodeTypes(state, types) {
      state.qrCodeTypes = types;
    },
    setQrCodeTemplates(state, templates = []) {
      state.qrCodeTemplates = templates;
    },
    setActiveQrCodeConfig(state, activeQrCodeConfig) {
      state.activeQrCodeConfig = activeQrCodeConfig;
    },
  },
  actions: {
    generateQrPreview({ getters, commit }) {
      let content = getters.qrCodeContent;
      let format = getters.qrCode.data_format;
      if (getters.qrCode.type === "dynamic") {
        content = getPageUrl(getters.qrCode.key);
        format = "URL";
      }
      if (
        getters.qrCode.type === "static" &&
        getters.qrCode.data_format === "URL"
      )
        content = getters.qrCodeContent.url;
      if (
        getters.qrCode.type === "static" &&
        getters.qrCode.data_format === "text"
      )
        content = getters.qrCodeContent.text;
      if (getters.validationErrors) {
        format = "URL";
        content = "";
      }

      const qrPreview = generateQrCodeSvg(content, {
        size: "100%",
        format,
      });

      commit("generateQrPreview", qrPreview);
    },
    async createQrCode(
      { getters, dispatch, rootGetters, commit },
      variables = {}
    ) {
      try {
        commit("createQrCodeStart");

        const qrCode = serializeNewQrCode(rootGetters, getters, variables);
        await apollo.mutate({
          mutation: INSERT_QR_CODE,
          context: {
            headers: {
              ...getOrganizationHeader(rootGetters),
            },
          },
          variables: qrCode,
        });
        await dispatch(
          "files/updateFileParams",
          { qrCodeId: qrCode.id },
          { root: true }
        );

        commit("createQrCodeSuccess");
        commit("setQrCodeFiles");
      } catch (e) {
        commit("createQrCodeFailure", e);
        console.log(e);
        return Promise.reject(e);
      }
    },
    async updateQrCode({ getters, dispatch, commit }) {
      try {
        commit("createQrCodeStart");

        const qrCode = serializeUpdatedQrCode(getters);
        await apollo.mutate({
          mutation: UPDATE_QR_CODE,
          variables: qrCode,
        });
        await dispatch(
          "files/updateFileParams",
          { qrCodeId: qrCode.id },
          { root: true }
        );

        commit("createQrCodeSuccess");
        commit("setQrCodeFiles");
      } catch (e) {
        commit("createQrCodeFailure", e);
        console.log(e);
        return Promise.reject(e);
      }
    },
    async uploadQrPreview({ getters, dispatch }, { preview, filename } = {}) {
      // create file
      const previewBlob = await svgToPngBlob(preview || getters.qrPreview, {
        width: getters.PREVIEW_WIDTH,
      });
      const previewFile = new File(
        [previewBlob],
        `${filename || getters.qrCode.key}.png`,
        {
          type: "image/png",
        }
      );

      // upload file
      return dispatch(
        "files/uploadFile",
        {
          directory: "preview",
          file: previewFile,
        },
        { root: true }
      );
    },
    async getQrCodeDetails({ commit }, key) {
      try {
        commit("getQrCodeDetailsStart");
        const { data } = await apollo.query({
          query: GET_QR_CODE_DETAILS,
          variables: { key },
        });

        const qrCode = data.qr_codes?.[0];
        if (!qrCode) throw Error("Qr code is not found");

        commit("getQrCodeDetailsSuccess", { qrCode, files: data.files });
      } catch (e) {
        commit("getQrCodeDetailsFailure", e);
        console.log(e);
        return Promise.reject(e);
      }
    },
    async getAllQrCodeTypes({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_ALL_QR_CODE_TYPES,
        });

        const types = data.qr_codes.map((qrCode) => qrCode.type);
        commit("setQrCodeTypes", types);
      } catch (error) {
        console.error(error);
      }
    },
    async getYouTubeVideoDetails(context, videoId) {
      try {
        const { data } = await QrCodeService.getYouTubeVideoDetails(videoId);
        return data;
      } catch (e) {
        console.log(e);
        return new Error("Oops... Something went wrong.");
      }
    },
  },
};
