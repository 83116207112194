import FilesService from "@/services/walletPass/files";

const initState = () => ({
  uploadingFilesCount: 0,
  loading: false,
  filesKeysToAdd: [],
  filesKeysToRemove: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    filesKeysToAdd: ({ filesKeysToAdd }) => filesKeysToAdd,
    filesKeysToRemove: ({ filesKeysToRemove }) => filesKeysToRemove,
    uploading: ({ uploadingFilesCount }) => Boolean(uploadingFilesCount),
    loading: ({ loading }) => loading,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    uploadFileStart(state) {
      state.uploadingFilesCount += 1;
    },
    uploadFileSuccess(state) {
      state.uploadingFilesCount = Math.max(0, state.uploadingFilesCount + 1);
    },
    uploadFileFailure(state) {
      state.uploadingFilesCount = Math.max(0, state.uploadingFilesCount - 1);
    },
    // deleteFilesStart(state) {
    //   state.loading = true;
    // },
    // deleteFilesSuccess(state) {
    //   state.loading = false;
    // },
    // deleteFilesFailure(state) {
    //   state.loading = false;
    // },
    setFilesKeysToAdd(state, key = []) {
      state.filesKeysToAdd = key;
    },
    addFilesKeysToAdd(state, key = []) {
      state.filesKeysToAdd.push(key);
    },
    setFilesKeysToRemove(state, key = []) {
      state.filesKeysToRemove = key;
    },
    addFilesKeysToRemove(state, key) {
      state.filesKeysToRemove.push(key);
    },
  },
  actions: {
    // unsaved === true - file will be removed when the next files cron job runs
    // ? we have two ways
    // first - upload file and immediately save check it as used
    // second - upload file and check as unsaved until the customer clicks save button after the click dispatch "updateFileParams"
    // then "filesKeyToAdd" will be checked as used; "filesKeyToDelete" will be checked as unsaved
    async uploadFile({ commit, rootGetters }, variables = {}) {
      try {
        if (!variables.file) return;

        const token = rootGetters["wallet/token"];
        commit("uploadFileStart");
        const formData = new FormData();
        formData.append("directory", variables.directory);
        if (variables.name) formData.append("name", variables.name);
        if (variables.unsaved) formData.append("unsaved", variables.unsaved);
        formData.append("upload", variables.file, variables.file.name);

        const { data } = await FilesService.uploadFile(
          formData,
          variables.onUploadProgress,
          token
        );

        const isSaved =
          variables.unsaved !== false || variables.unsaved !== "false";
        if (isSaved) commit("addFilesKeysToAdd", data.key);
        commit("uploadFileSuccess");
        return data;
      } catch (e) {
        console.log(e);
        commit("uploadFileFailure");
        return Promise.reject(e);
      }
    },
    // async deleteFiles({ commit }, keys) {
    //   try {
    //     commit("deleteFilesStart");
    //     await FilesService.deleteFile({ keys });
    //     commit("deleteFilesSuccess");
    //   } catch (e) {
    //     console.log(e);
    //     commit("deleteFilesFailure");
    //     return Promise.reject(e);
    //   }
    // },
  },
};
