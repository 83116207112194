import Vue from "vue";
import { createLoader } from "@/utils/loader";

Vue.directive("loader", {
  update: (el, binding) => {
    const loading = binding.value;
    if (loading) {
      let oldLoader = el.querySelector(".l2qr-loader");
      if (oldLoader) return;
      el.style.position = "relative";

      const loader = createLoader();
      el.append(loader);
    } else {
      const loader = el.querySelector(".l2qr-loader");
      loader?.remove?.();
    }
  },
});

Vue.directive("intersection-observer", {
  bind: (el, binding) => {
    const callback = binding.value;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) callback();
        });
      },
      { threshold: 1 }
    );
    observer.observe(el);
  },
  // unbind: (el) => {
  //   console.log(el)
  // }
});
