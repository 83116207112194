import axios from "@/plugins/axios";
import gql from "graphql-tag";

export const GET_ALL_TRANSLATIONS = gql`
  query getTranslations {
    translations {
      key
      value
      language_code
    }
  }
`;

export const SET_ALL_TRANSITIONS = gql`
  mutation setTransitions($translations: [translations_insert_input!]!) {
    insert_translations(
      objects: $translations
      on_conflict: {
        constraint: translations_key_language_code_key
        update_columns: [value]
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages {
    locales(distinct_on: language_code) {
      language_code
    }
  }
`;

export const translateTexts = (credentials) => {
  return axios.post("/translations", credentials);
};
