import gql from "graphql-tag";
import axios from "@/plugins/axios";

export const GET_TICKETS_LIST = gql`
  query getTicketsList(
    $where: help_center_tickets_bool_exp!
    $limit: Int = 20
    $offset: Int = 0
  ) {
    help_center_tickets(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: $where
    ) {
      id
      ticket
      type
      email
      status
      assigned_to_id
    }
  }
`;

export const GET_TICKET_DETAILS = gql`
  query getTicketsDetails($ticket: Int!) {
    help_center_tickets(where: { ticket: { _eq: $ticket } }) {
      ticket
      created_at
      email
      name
      subject
      description
      type
      status
      assigned_to_id
    }
  }
`;

export const GET_TICKET_REPLIES = gql`
  query getTicketReplies($ticket: Int!) {
    help_center_messages(
      where: { ticket: { _eq: $ticket } }
      order_by: { created_at: asc }
    ) {
      id
      content
      manager_id
      created_at
      type
      attachments {
        file {
          key
          original_name
          mime_type
        }
      }
    }
  }
`;

export const SAVE_TICKET = gql`
  mutation saveTicket($ticket: Int!, $assigned_to_id: uuid!, $status: String!) {
    update_help_center_tickets(
      where: { ticket: { _eq: $ticket } }
      _set: { status: $status, assigned_to_id: $assigned_to_id }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_OLD_TICKET_REPLIES = gql`
  mutation deleteOldTicketReplies($repliesToDeleteIds: [uuid!]!) {
    update_files(
      where: {
        _or: [
          {
            attachments_help_center_messages: {
              message_id: { _in: $repliesToDeleteIds }
            }
          }
          {
            files_help_center_messages: {
              message_id: { _in: $repliesToDeleteIds }
            }
          }
        ]
      }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    delete_files_help_center_messages(
      where: { message_id: { _in: $repliesToDeleteIds } }
    ) {
      affected_rows
    }
    delete_attachments_help_center_messages(
      where: { message_id: { _in: $repliesToDeleteIds } }
    ) {
      affected_rows
    }
    delete_help_center_messages(where: { id: { _in: $repliesToDeleteIds } }) {
      affected_rows
    }
  }
`;

export const DELETE_TICKETS = gql`
  mutation deleteTickets($tickets: [Int!]!) {
    update_files(
      where: {
        _or: [
          {
            attachments_help_center_messages: {
              help_center_message: { ticket: { _in: $tickets } }
            }
          }
          {
            files_help_center_messages: {
              help_center_message: { ticket: { _in: $tickets } }
            }
          }
        ]
      }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    delete_files_help_center_messages(
      where: { help_center_message: { ticket: { _in: $tickets } } }
    ) {
      affected_rows
    }
    delete_attachments_help_center_messages(
      where: { help_center_message: { ticket: { _in: $tickets } } }
    ) {
      affected_rows
    }
    delete_help_center_messages(where: { ticket: { _in: $tickets } }) {
      affected_rows
    }
    delete_help_center_tickets(where: { ticket: { _in: $tickets } }) {
      affected_rows
    }
  }
`;

export const ASSIGN_TICKETS = gql`
  mutation assignRequests($tickets: [Int!]!, $assignedToId: uuid) {
    update_help_center_tickets(
      where: { ticket: { _in: $tickets } }
      _set: { assigned_to_id: $assignedToId }
    ) {
      affected_rows
    }
  }
`;
export const REPLY_TICKETS = gql`
  mutation replyTickets($replyObjects: [help_center_messages_insert_input!]!) {
    insert_help_center_messages(objects: $replyObjects) {
      affected_rows
    }
  }
`;

export const sendReplyTicketEmails = (repliesIds) => {
  return axios.post("/send-reply-tickets-emails", { ids: repliesIds });
};
