const initState = () => ({
  isOpenSidebar: false,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    isOpenSidebar: ({ isOpenSidebar }) => isOpenSidebar,
  },
  mutations: {
    toggleSidebar(state, bool = false) {
      state.isOpenSidebar = bool;
    },
  },
  actions: {},
};
