import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { ApolloClient, InMemoryCache, split } from "apollo-boost";
import { getMainDefinition } from "apollo-utilities";
import Cookie from "cookie-universal";

const cookies = Cookie();

const getHeaders = () => {
  const token = cookies.get("access-token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const EXPIRED_TOKEN_MESSAGE = "Could not verify JWT: JWTExpired";
const ERROR_TOKEN_MESSAGE = "Could not verify JWT: JWSError";
let refreshingPromise = null;

const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        refreshingPromise = null;
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
    return subscriber;
  });
};

const errorHandler = ({ graphQLErrors, networkError, operation, forward }) => {
  const store = require("@/store").default;
  // todo can add network errors or gql error handlers

  if (networkError) {
    if (process.env.NODE_ENV === "development")
      console.log(`[Network error]: ${networkError}`);
    return;
  }

  for (let { message, extensions } of graphQLErrors) {
    if (
      extensions.code === "invalid-jwt" &&
      (message === EXPIRED_TOKEN_MESSAGE ||
        message.includes(ERROR_TOKEN_MESSAGE))
    ) {
      if (!refreshingPromise)
        refreshingPromise = store.dispatch("auth/refreshToken");

      return promiseToObservable(refreshingPromise).flatMap(() => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            ...getHeaders(),
          },
        });

        return forward(operation);
      });
    }
  }
};

// HTTP link
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_DB_URL,
  headers: getHeaders(),
});

// WebSocket link
export const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_DB_URL.replace(/^http?s/g, "wss"),
  // headers: getHeaders(),
  options: {
    reconnect: true,
    connectionParams() {
      return {
        headers: getHeaders(),
      };
    },
  },
});

// export const reconnectWS = () => {
//   wsLink.subscriptionClient.close();
//   wsLink.subscriptionClient.connect();
// };

// handle reconnecting
// todo change use cases login/logout
wsLink.subscriptionClient.onReconnected(() => {
  wsLink.subscriptionClient.connectionParams = {
    ...wsLink.subscriptionClient.connectionParams,
    headers: getHeaders(),
  };
});

// Split link for HTTP and WebSocket
const splitLink = split(
  (options) => {
    const { kind, operation } = getMainDefinition(options.query);
    if (operation !== "subscription") {
      options.setContext({
        headers: { ...options.getContext().headers, ...getHeaders() },
      });
    }
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

// Error link
const errorLink = onError(errorHandler);

// Concatenate links
const link = ApolloLink.from([errorLink, splitLink]);

// Create the Apollo Client instance
export const defaultClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});
