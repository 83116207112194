import gql from "graphql-tag";
import axios from "@/plugins/axios";

export const GET_WALLET_PASS_DETAILS = gql`
  query getWalletPassDetails($key: String!) {
    qr_codes(where: { key: { _eq: $key } }) {
      key
      content
    }
  }
`;

const sendWalletPassEmail = (variables) => {
  return axios.post("/send-wallet-pass-email", variables);
};
const generateWalletPass = (variables) => {
  return axios.post("/wallet-pass", variables);
};
const getWalletPassDetails = (token) => {
  const config = {
    headers: {
      "X-Wallet-Pass-Token": `Bearer ${token}`,
    },
  };

  return axios.get("/wallet-pass-details", config);
};
const updateWalletPassDetails = (variables, token) => {
  const config = {
    headers: {
      "X-Wallet-Pass-Token": `Bearer ${token}`,
    },
  };
  return axios.post("/wallet-pass-details", variables, config);
};

export default {
  sendWalletPassEmail,
  generateWalletPass,
  getWalletPassDetails,
  updateWalletPassDetails,
};
