const divider = "<sep>";
const metaProps = [
  "meta_title",
  "meta_description",
  "card_title",
  "card_description",
  "content_header_title",
  "content_header_description",
  "content_header_button_text",
];

const addMeta = (article, result) => {
  metaProps.forEach((prop) => {
    result.push({
      path: prop,
      value: article[prop],
    });
  });
};

const getDeltaText = (delta) => {
  return delta.ops?.map((op) => op.insert)?.join(divider);
};

const addFaqBlock = (article, blockIdx, result) => {
  const content = article.blocks[blockIdx].content;
  if (!content) return;
  content.forEach((i, idx) => {
    const questionPath = `blocks[${blockIdx}].content[${idx}].question`;
    const answerPath = `blocks[${blockIdx}].content[${idx}].answer`;
    if (i.question)
      result.push({
        path: questionPath,
        value: eval(`article.${questionPath}`),
      });
    if (i.answer?.ops)
      result.push({
        path: answerPath,
        value: getDeltaText(eval(`article.${answerPath}`)),
        delta: true,
      });
  });
};
const addTextBlock = (article, blockIdx, result) => {
  const content = article.blocks[blockIdx].content;
  const textPath = `blocks[${blockIdx}].content`;
  if (!content) return;
  result.push({
    path: textPath,
    value: getDeltaText(eval(`article.${textPath}`)),
    delta: true,
  });
};
const addLogoAndTextBlock = (article, blockIdx, result) => {
  const content = article.blocks[blockIdx].content;
  const textPath = `blocks[${blockIdx}].content[0].content_text`;
  if (!content[0].content_text?.ops) return;
  result.push({
    path: textPath,
    value: getDeltaText(eval(`article.${textPath}`)),
    delta: true,
  });
};
const addContentAndImage = (article, blockIdx, result) => {
  const content = article.blocks[blockIdx].content;
  const textPath = `blocks[${blockIdx}].content[0].content_text`;
  const buttonTextPath = `blocks[${blockIdx}].content[0].button_text`;
  if (!content[0].content_text?.ops) return;
  result.push({
    path: textPath,
    value: getDeltaText(eval(`article.${textPath}`)),
    delta: true,
  });
  if (!content[0].button_text) return;
  result.push({
    path: buttonTextPath,
    value: eval(`article.${buttonTextPath}`),
  });
};

const addBlocks = (article, result) => {
  const blocks = article.blocks;
  blocks.forEach((b, idx) => {
    const anchorValuePath = `blocks[${idx}].anchor_value`;
    if (b.anchor_value)
      result.push({
        path: anchorValuePath,
        value: eval(`article.${anchorValuePath}`),
      });
    if (b.type === "faq") addFaqBlock(article, idx, result);
    if (b.type === "text") addTextBlock(article, idx, result);
    if (b.type === "logo_and_text") addLogoAndTextBlock(article, idx, result);
    if (b.type === "content_and_image")
      addContentAndImage(article, idx, result);
  });
};

export const serializeArticleToTranslate = (article) => {
  const result = [];
  addMeta(article, result);
  addBlocks(article, result);
  return result;
};

export const getTranslatedDelta = (content, value) => {
  const translatedInserts = value.split(divider);

  content.ops.forEach((i, idx) => {
    i.insert = translatedInserts[idx];
  });
};

export const getTranslatedArticle = (article, translations) => {
  // eslint-disable-next-line
  const cloneArticle = JSON.parse(JSON.stringify(article));

  translations.forEach((t) => {
    if (t.delta)
      return getTranslatedDelta(eval(`cloneArticle.${t.path}`), t.value);
    eval(`cloneArticle.${t.path} = '${t.value.replace(/'/g, "\\'")}'`);
  });
  return cloneArticle;
};

// cloned images
const metaImagesProps = [
  "meta_image_key",
  "card_image_key",
  "content_header_background_image_key",
];

const addMetaImages = (article, result) => {
  metaImagesProps.forEach((prop) => {
    if (!article[prop]) return;
    result.push({
      path: prop,
      value: article[prop],
    });
  });
};
const addBlocksImages = (article, result) => {
  const blocks = article.blocks;
  blocks.forEach((b, blockIdx) => {
    b.content.forEach((i, idx) => {
      const hasImageKey = article.blocks[blockIdx].content[idx]?.image_key;
      if (!hasImageKey) return;
      const imageKeyPath = `blocks[${blockIdx}].content[${idx}].image_key`;
      const imageKey = eval(`article.${imageKeyPath}`);
      result.push({
        path: imageKeyPath,
        value: imageKey,
      });
    });
  });
};
export const serializeArticleImages = (article) => {
  const result = [];
  addMetaImages(article, result);
  addBlocksImages(article, result);
  return result;
};

export const getCopiedArticleImages = (article, files) => {
  files.forEach((f) => {
    eval(`article.${f.path} = '${f.value}'`);
  });
};
