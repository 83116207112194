import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apolloProvider from "@/plugins/apollo/index";
import i18n from "@/plugins/i18n";
import initLocale from "@/plugins/locale/index";
import "@/plugins/screen-detection.client";
import "@/plugins/element-ui";
import "@/plugins/directives";
import "@/assets/css/main.css";
import "@/assets/icons-font/icons.css";

Vue.config.productionTip = false;

initLocale();

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
