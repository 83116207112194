import axios from "@/plugins/axios";
import gql from "graphql-tag";

const uploadFile = (formData, onUploadProgress) => {
  const configs = {};
  if (onUploadProgress) configs.onUploadProgress = onUploadProgress;
  return axios.post("/upload", formData, configs);
};

const deleteFile = (variables) => {
  return axios.post("/delete", variables);
};

export const UPDATE_FILES_PARAMS = gql`
  mutation updateFilesParams(
    $filesKeysToRemove: [String!] = []
    $filesKeysToAdd: [String!] = []
    $qrCodeId: uuid = null
  ) {
    saved_files: update_files(
      where: { key: { _in: $filesKeysToAdd } }
      _set: { qr_code_id: $qrCodeId, unsaved: false }
    ) {
      affected_rows
    }
    unsaved_files: update_files(
      where: { key: { _in: $filesKeysToRemove } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
  }
`;

export default {
  uploadFile,
  deleteFile,
};
