import Vue from "vue";
import lang from "element-ui/lib/locale/lang/en"; // ? імпорт мови для element-ui
import locale from "element-ui/lib/locale"; // ? імпорт обєкта для підключення мови
import {
  Drawer,
  Avatar,
  Autocomplete,
  Button,
  Checkbox,
  Col,
  Container,
  DatePicker,
  Form,
  FormItem,
  Switch,
  Input,
  InputNumber,
  Option,
  OptionGroup,
  Radio,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  // Tabs,
  // TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  TimeSelect,
  Dialog,
  Slider,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Tooltip,
  Collapse,
  Table,
  TableColumn,
  Popover,
  CollapseItem,
  Upload,
  Skeleton,
  SkeletonItem,
} from "element-ui";

const components = [
  Drawer,
  Avatar,
  Autocomplete,
  Button,
  Checkbox,
  Col,
  Container,
  DatePicker,
  Form,
  FormItem,
  Switch,
  Input,
  InputNumber,
  Option,
  OptionGroup,
  Radio,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  // Tabs,
  // TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  TimeSelect,
  Dialog,
  Slider,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Tooltip,
  Collapse,
  Table,
  TableColumn,
  Popover,
  CollapseItem,
  Upload,
  Skeleton,
  SkeletonItem,
];

locale.use(lang);
components.forEach((c) => {
  Vue.component(c.name, c);
});

// const components = {
//   ElDrawer: () =>
//     import(/* webpackChunkName: "ElDrawer" */ "element-ui/lib/drawer"),
//   ElAvatar: () =>
//     import(/* webpackChunkName: "ElAvatar" */ "element-ui/lib/avatar"),
//   ElAutocomplete: () =>
//     import(
//       /* webpackChunkName: "ElAutocomplete" */ "element-ui/lib/autocomplete"
//     ),
//   ElButton: () =>
//     import(/* webpackChunkName: "ElButton" */ "element-ui/lib/button"),
//   ElCheckbox: () =>
//     import(/* webpackChunkName: "ElCheckbox" */ "element-ui/lib/checkbox"),
//   ElCol: () => import(/* webpackChunkName: "ElCol" */ "element-ui/lib/col"),
//   ElContainer: () =>
//     import(/* webpackChunkName: "ElContainer" */ "element-ui/lib/container"),
//   ElDatePicker: () =>
//     import(/* webpackChunkName: "ElDatePicker" */ "element-ui/lib/date-picker"),
//   ElForm: () => import(/* webpackChunkName: "ElForm" */ "element-ui/lib/form"),
//   ElFormItem: () =>
//     import(/* webpackChunkName: "ElFormItem" */ "element-ui/lib/form-item"),
//   ElSwitch: () =>
//     import(/* webpackChunkName: "ElSwitch" */ "element-ui/lib/switch"),
//   ElInput: () =>
//     import(/* webpackChunkName: "ElInput" */ "element-ui/lib/input"),
//   ElInputNumber: () =>
//     import(
//       /* webpackChunkName: "ElInputNumber" */ "element-ui/lib/input-number"
//     ),
//   ElOption: () =>
//     import(/* webpackChunkName: "ElOption" */ "element-ui/lib/option"),
//   ElOptionGroup: () =>
//     import(
//       /* webpackChunkName: "ElOptionGroup" */ "element-ui/lib/option-group"
//     ),
//   ElRadio: () =>
//     import(/* webpackChunkName: "ElRadio" */ "element-ui/lib/radio"),
//   ElRadioButton: () =>
//     import(
//       /* webpackChunkName: "ElRadioButton" */ "element-ui/lib/radio-button"
//     ),
//   ElRadioGroup: () =>
//     import(/* webpackChunkName: "ElRadioGroup" */ "element-ui/lib/radio-group"),
//   ElRow: () => import(/* webpackChunkName: "ElRow" */ "element-ui/lib/row"),
//   ElSelect: () =>
//     import(/* webpackChunkName: "ElSelect" */ "element-ui/lib/select"),
//   ElTabs: () => import(/* webpackChunkName: "ElTabs" */ "element-ui/lib/tabs"),
//   ElTabPane: () =>
//     import(/* webpackChunkName: "ElTabPane" */ "element-ui/lib/tab-pane"),
//   ElDropdown: () =>
//     import(/* webpackChunkName: "ElDropdown" */ "element-ui/lib/dropdown"),
//   ElDropdownMenu: () =>
//     import(
//       /* webpackChunkName: "ElDropdownMenu" */ "element-ui/lib/dropdown-menu"
//     ),
//   ElDropdownItem: () =>
//     import(
//       /* webpackChunkName: "ElDropdownItem" */ "element-ui/lib/dropdown-item"
//     ),
//   ElTimeSelect: () =>
//     import(/* webpackChunkName: "ElTimeSelect" */ "element-ui/lib/time-select"),
//   ElDialog: () =>
//     import(/* webpackChunkName: "ElDialog" */ "element-ui/lib/dialog"),
//   ElSlider: () =>
//     import(/* webpackChunkName: "ElSlider" */ "element-ui/lib/slider"),
//   ElBreadcrumb: () =>
//     import(/* webpackChunkName: "ElBreadcrumb" */ "element-ui/lib/breadcrumb"),
//   ElBreadcrumbItem: () =>
//     import(
//       /* webpackChunkName: "ElBreadcrumbItem" */ "element-ui/lib/breadcrumb-item"
//     ),
//   ElBadge: () =>
//     import(/* webpackChunkName: "ElBadge" */ "element-ui/lib/badge"),
//   ElTooltip: () =>
//     import(/* webpackChunkName: "ElTooltip" */ "element-ui/lib/tooltip"),
//   ElCollapse: () =>
//     import(/* webpackChunkName: "ElCollapse" */ "element-ui/lib/collapse"),
//   // todo customize
//   ElTable: () =>
//     import(/* webpackChunkName: "ElTable" */ "element-ui/lib/table"),
//   ElTableColumn: () =>
//     import(
//       /* webpackChunkName: "ElTableColumn" */ "element-ui/lib/table-column"
//     ),
//   ElPopover: () =>
//     import(/* webpackChunkName: "ElPopover" */ "element-ui/lib/popover"),
//   ElCollapseItem: () =>
//     import(
//       /* webpackChunkName: "ElCollapseItem" */ "element-ui/lib/collapse-item"
//     ),
//   ElUpload: () =>
//     import(/* webpackChunkName: "ElUpload" */ "element-ui/lib/upload"),
//   ElSkeleton: () =>
//     import(/* webpackChunkName: "ElSkeleton" */ "element-ui/lib/skeleton"),
//   ElSkeletonItem: () =>
//     import(
//       /* webpackChunkName: "ElSkeletonItem" */ "element-ui/lib/skeleton-item"
//     ), // color / icons-font
// };

// locale.use(lang);
// Object.keys(components).forEach((key) => {
//   Vue.component(key, components[key]);
// });
