import gql from "graphql-tag";
import axios from "@/plugins/axios";

export const GET_BILLING_SETTINGS = gql`
  query getBillingSettings($accountId: uuid!) {
    accounts_plans(where: { account: { id: { _eq: $accountId } } }) {
      account {
        customer_id
      }
      is_subscribed
      expiry_date
      masked_card
      rectoken_lifetime
      plan {
        id
        label
      }
    }
    account_billing_address(where: { account_id: { _eq: $accountId } }) {
      address
      zip_code
      city
      country
      email
      first_name
      last_name
      company_name
      vat
    }
  }
`;
export const GET_ORDERS = gql`
  query getOrders(
    $accountId: uuid!
    $limit: Int = 20
    $offset: Int = 0
    $orderBy: [orders_order_by!]! = { created_at: desc }
  ) {
    orders(
      where: { account_id: { _eq: $accountId } }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      order_status
      payment_url
      period_start
      currency
      amount
      plan {
        id
        label
      }
      invoice_file {
        key
      }
    }
  }
`;

export const SUBSCRIBE_PLAN = gql`
  mutation subscribePlan($accountId: uuid!, $isSubscribed: Boolean!) {
    update_accounts_plans(
      where: { account: { id: { _eq: $accountId } } }
      _set: { is_subscribed: $isSubscribed }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = gql`
  mutation updateBillingAddress(
    $accountId: uuid!
    $billingAddress: account_billing_address_set_input!
  ) {
    update_account_billing_address(
      where: { account_id: { _eq: $accountId } }
      _set: $billingAddress
    ) {
      affected_rows
    }
  }
`;

export const GET_PAID_ORDER_BY_ID = gql`
  query getPaidOrderById($orderId: String!) {
    orders(
      where: { id: { _eq: $orderId }, order_status: { _eq: "approved" } }
    ) {
      id
      amount
      currency
      email
      masked_card
      payment_frequency
      plan {
        id
        label
      }
    }
  }
`;

export const verifyCard = (variables) => {
  return axios.post("/verify-card", variables);
};

// export const GET_CURRENT_PLAN_ID = gql`
//   query getCurrentPlanId($accountId: uuid!) {
//     account_plan: accounts_plans(
//       where: { account: { id: { _eq: $accountId } } }
//       limit: 1
//       order_by: { created_at: desc }
//     ) {
//       plan {
//         id
//       }
//     }
//   }
// `;
