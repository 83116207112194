import { createQrCode } from "l2qr-generator-api/dist/client/index";

const getInitialState = () => ({
  shownDownloadPopup: false,
  shownVectorPopup: false,
  shownScanTrackingPopup: false,
  shownUploadFileMessage: false,
  mobilePanel: "form",
  qrDataFormat: "URL",

  svgText: null,
  isLoadingQr: false,
  hadInput: false,
  loadingTimerId: null,
  LOADING_DELAY: 1200,

  content: "",
  qrMatrix: null,
  frame_name: null,
  shapes: "squares",
  logo_name: null,
  foreground_color: "#000000",

  URL: "",
  text: "",
  vcard: {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    mobilePhone: "",
    fax: "",
    email: "",
    url: "",
  },
  email: {
    email: "",
    subject: "",
    message: "",
  },
  sms: {
    phone: "",
    message: "",
  },
  wifi: {
    type: "WPA",
    network: "",
    password: "",
    hidden: false,
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    shownDownloadPopup: ({ shownDownloadPopup }) => shownDownloadPopup,
    shownVectorPopup: ({ shownVectorPopup }) => shownVectorPopup,
    shownScanTrackingPopup: ({ shownScanTrackingPopup }) =>
      shownScanTrackingPopup,
    shownUploadFileMessage: ({ shownUploadFileMessage }) =>
      shownUploadFileMessage,
    mobilePanel: ({ mobilePanel }) => mobilePanel,

    isLoadingQr: ({ isLoadingQr }) => isLoadingQr,
    hadInput: ({ hadInput }) => hadInput,
    disabledPreview: ({ isLoadingQr, hadInput }) => !hadInput || isLoadingQr,
    loadingTimerId: ({ loadingTimerId }) => loadingTimerId,
    qrDataFormat: ({ qrDataFormat }) => qrDataFormat,
    svgText: ({ svgText }) => svgText,

    LOADING_DELAY: ({ LOADING_DELAY }) => LOADING_DELAY,

    frame_name: ({ frame_name }) => frame_name,
    shapes: ({ shapes }) => shapes,
    logo_name: ({ logo_name }) => logo_name,
    foreground_color: ({ foreground_color }) => foreground_color,

    qrMatrix: ({ qrMatrix }) => qrMatrix,

    URL: ({ URL }) => URL,
    text: ({ text }) => text,
    vcard: ({ vcard }) => vcard,
    email: ({ email }) => email,
    sms: ({ sms }) => sms,
    wifi: ({ wifi }) => wifi,
  },
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState());
    },

    showDownloadPopup(state, bool) {
      state.shownDownloadPopup = bool;
    },
    showVectorPopup(state, bool) {
      state.shownVectorPopup = bool;
    },
    showScanTrackingPopup(state, bool) {
      state.shownScanTrackingPopup = bool;
    },
    showUploadFileMessage(state, bool) {
      state.shownUploadFileMessage = bool;
    },

    generateQrStart(state, timerId) {
      state.isLoadingQr = true;
      state.loadingTimerId = timerId;
    },
    generateQrSuccess(state, qrMatrix) {
      state.isLoadingQr = false;
      state.loadingTimerId = null;
      state.qrMatrix = qrMatrix;
      state.hadInput = true;
    },
    generateQrFailure(state) {
      state.isLoadingQr = false;
      state.loadingTimerId = null;
    },
    setSvgText(state, svgText) {
      state.svgText = svgText;
    },

    setQrDataFormat(state, qrDataFormat = "URL") {
      state.qrDataFormat = qrDataFormat;
    },

    setContent(state, { content = "", type } = {}) {
      state[type || state.qrDataFormat] = content;
    },
    setFrameName(state, frame) {
      state.frame_name = frame;
    },
    setShapes(state, shapes) {
      state.shapes = shapes;
    },
    setForegroundColor(state, color) {
      state.foreground_color = color;
    },
    setLogoName(state, logo) {
      state.logo_name = logo;
    },
    setMobilePanel(state, panel = "form") {
      state.mobilePanel = panel;
    },
  },
  actions: {
    generateQr({ commit, getters }, { content, type } = {}) {
      if (getters.loadingTimerId) clearTimeout(getters.loadingTimerId);

      const timerId = setTimeout(() => {
        try {
          const qrMatrix = createQrCode(content, type || getters.qrDataFormat);
          commit("generateQrSuccess", qrMatrix);
        } catch (e) {
          console.log(e);
          commit("generateQrFailure");
        }
      }, getters.LOADING_DELAY);

      commit("generateQrStart", timerId);
    },
  },
};
