import gql from "graphql-tag";

export const GET_MANAGERS_NAMES = gql`
  query getManagers {
    users(where: { manager_roles: {} }) {
      id
      email
      own_account {
        contacts {
          first_name
          last_name
          company
        }
      }
    }
  }
`;
