import {
  GET_ALL_TRANSLATIONS,
  SET_ALL_TRANSITIONS,
  translateTexts,
} from "@/services/backoffice/internationalization";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import { GET_LANGUAGES } from "@/services/backoffice/internationalization";

const initState = () => ({
  translations: [],
  languages: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    translations: ({ translations }) => translations,
    languages: ({ languages }) => languages,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    setTranslations(state, translations = []) {
      state.translations = translations;
    },
    setLanguages(state, languages = []) {
      state.languages = languages;
    },
  },
  actions: {
    async getTranslations({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_ALL_TRANSLATIONS,
          context: MANAGER_APOLLO_CONTEXT,
        });
        commit("setTranslations", data.translations);
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async setTranslations(context, translations) {
      try {
        await apollo.mutate({
          mutation: SET_ALL_TRANSITIONS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { translations },
        });
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async getLanguages({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_LANGUAGES,
          context: MANAGER_APOLLO_CONTEXT,
        });
        commit(
          "setLanguages",
          data.locales?.map((l) => ({ code: l.language_code }))
        );
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async translateTexts(context, { texts, languages } = {}) {
      const { data } = await translateTexts({ texts, languages });
      return data;
    },
  },
};
