import gql from "graphql-tag";

export const GET_CHARTS_DATA = gql`
  query getChartsData(
    $usersFilters: users_bool_exp!
    $qrCodesFilters: qr_codes_bool_exp!
    $scansFilters: qr_code_scans_bool_exp!
  ) {
    users(where: $usersFilters) {
      created_at
      google_id
      last_login_at
      own_account {
        account_plan {
          current_plan_id
        }
      }
    }
    qr_codes(where: $qrCodesFilters) {
      created_at
      data_format
    }
    qr_code_scans(where: $scansFilters) {
      created_at
      qr_code {
        data_format
      }
    }
  }
`;

export const GET_QR_CODE_SCANS_RATING = gql`
  query getQrCodeScansRating($filters: qr_codes_bool_exp!) {
    qr_codes(
      where: $filters
      order_by: { scans_aggregate: { count: desc } }
      limit: 50
    ) {
      account {
        owner {
          email
        }
      }
      data_format
      key
      scans_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_QR_CODE_DETAILS = gql`
  query getQrCodeDetails($filters: qr_codes_bool_exp!) {
    qr_codes(where: $filters) {
      account {
        owner {
          email
        }
      }
      key
      data_format
      scans {
        created_at
      }
    }
  }
`;
