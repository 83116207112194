import { GET_ACCOUNTS } from "@/services/backoffice/account";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import { getTokenUserId } from "@/utils/token";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeMyAccount = (account) => {
  if (!account) throw Error("Account is not fount");
  return {
    ...account,
    ...(account.contacts?.[0] ?? {}),
    ...(account.owner ?? {}),
  };
};

const initState = () => ({
  account: null,
  error: null,
  loading: false,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    account: ({ account }) => account,
    errors: ({ errors }) => errors,
    loading: ({ loading }) => loading,
    accountPlan: ({ accountPlan }) => accountPlan,
    accountName: ({ account }) =>
      account?.company ||
      (account?.first_name && `${account?.first_name} ${account?.last_name}`) ||
      account?.email,
  },
  mutations: {
    reset(state) {
      Object.assign(state);
    },
    getAccountsStart(state) {
      state.account = null;
      state.error = null;
      state.loading = true;
    },
    getAccountsSuccess(state, account) {
      state.account = account;
      state.loading = false;
    },
    getAccountsFailure(state, e) {
      state.account = null;
      state.error = e;
      state.loading = false;
    },
  },
  actions: {
    async getAccount({ commit }) {
      try {
        commit("getAccountsStart");

        const { data } = await apollo.query({
          context: MANAGER_APOLLO_CONTEXT,
          query: GET_ACCOUNTS,
          variables: { ownerId: getTokenUserId() },
        });
        const account = serializeMyAccount(data.accounts[0]);

        if (!account) throw Error("The account is not found");
        commit("getAccountsSuccess", account);
      } catch (e) {
        commit("getAccountsFailure", e);
        return Promise.reject(e);
      }
    },
    async getUpdatedAccount({ commit }) {
      try {
        const { data } = await apollo.query({
          context: MANAGER_APOLLO_CONTEXT,
          query: GET_ACCOUNTS,
        });
        const account = serializeMyAccount(data.accounts[0]);
        commit("getAccountsSuccess", account);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
