import gql from "graphql-tag";

export const GET_ACCOUNTS = gql`
  fragment planFields on plans {
    id
    label
    dynamic_qr_codes
    dynamic_business_card
    static_qr_codes
    scans
    qr_templates
    multiple_download_formats
    limit_space
    tracking_date
    tracking_device
    tracking_gps
    gdpr
    sso
    qr_design_customization
    page_templates
    knowledge_base
    email_support
    customer_manager
    advertising
  }

  query getAccounts($userId: uuid!) {
    users(where: { id: { _eq: $userId } }) {
      email
      avatar_url
    }
    accounts {
      id
      owner_id
      status
      customer_id
      used_resources {
        used_business_cards
        used_dynamic_qr_codes
        used_static_qr_codes
        used_scans
        used_storage
      }
      account_plan {
        expiry_date
        is_paid
        is_trial
        plan {
          ...planFields
        }
        organization_users_limit
        stripe_customer_id
        current_subscription {
          recurring_interval
          current_period_start
          current_period_end
        }
      }
      software_preferences {
        language
      }
      contacts {
        first_name
        last_name
        company
      }
    }
  }
`;
