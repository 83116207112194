import moment from "moment";
import cookies from "cookie-universal";

export const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log();
  }
};

export const isExpiredToken = (token) => {
  const payload = parseJwt(token);
  const expiredAtTimestamp = payload.exp * 1000;
  return moment(expiredAtTimestamp).isBefore(moment());
};

export const getTokenUserId = () => {
  const tokenData = parseJwt(cookies().get("access-token"));
  return tokenData[process.env.VUE_APP_HASURA_NAMESPACE]["x-hasura-user-id"];
};

export const getTokenAccountId = () => {
  const tokenData = parseJwt(cookies().get("access-token"));
  return tokenData[process.env.VUE_APP_HASURA_NAMESPACE]["x-hasura-account-id"];
};

export const getTokenOrganizationId = () => {
  const tokenData = parseJwt(cookies().get("access-token"));
  return tokenData[process.env.VUE_APP_HASURA_NAMESPACE][
    "x-hasura-organization-id"
  ];
};
