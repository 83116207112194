import store from "@/store";

const mobileWidth = store.getters["device/MOBILE_WIDTH"];
const toggleIsMobile = () => {
  const isMobile = window.matchMedia(`(max-width: ${mobileWidth}px)`).matches;
  store.commit("device/toggleIsMobile", isMobile);
};

toggleIsMobile();
window.addEventListener("resize", () => {
  toggleIsMobile();
});
