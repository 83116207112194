import { v4 as uuidv4 } from "uuid";
import shortid from "shortid";
import {
  dynamicTemplates,
  getStaticQrContent,
} from "@/constants/qr-codes-content";

export const getPageUrl = (key, domain) => {
  try {
    return new URL(key, domain || process.env.VUE_APP_PAGES_URL).toString();
  } catch (e) {
    return "";
  }
};

const getQrContentTemplates = (tmp, context) => {
  const contentTmp = (
    tmp.type === "static"
      ? getStaticQrContent(context)
      : dynamicTemplates(context)
  )?.[tmp.data_format];

  if (!contentTmp) throw Error("format is not found");
  return JSON.parse(JSON.stringify(contentTmp));
};

export const createQrFromTemplate = (tmp, context) => {
  const qr = {
    data_format: tmp.data_format,
    key: shortid.generate(),
    design_id: uuidv4(),
    preview_id: null,
    type: tmp.type,
    id: uuidv4(),
    name: "",
  };

  if (!qr.content) qr.content = getQrContentTemplates(tmp, context);
  return qr;
};

export const defaultQrCodeName = (qrCode) => {
  // static
  if (qrCode.data_format === "URL") return qrCode.content.url;
  if (qrCode.data_format === "vcard") {
    const { firstName, lastName, company } = qrCode.content;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    if (firstName || lastName) return firstName || lastName;
    return company;
  }
  if (qrCode.data_format === "email")
    return qrCode.content.subject || qrCode.content.email;
  if (qrCode.data_format === "sms") return qrCode.content.phone;
  if (qrCode.data_format === "text")
    return qrCode.content.text.length > 50
      ? qrCode.content.text.slice(0, 50) + "..."
      : qrCode.content.text;
  if (qrCode.data_format === "wifi") return qrCode.content.network;

  // dynamic
  if (qrCode.data_format === "soc_media") return "My Social Media Page";
  if (qrCode.data_format === "coupons") return "My Coupons Code";
  if (qrCode.data_format === "vcard_plus") return "My VCard Plus";
  if (qrCode.data_format === "business_page") return "My Business Page";
  if (qrCode.data_format === "feedback") return "My Feedback Code";
  if (qrCode.data_format === "rating") return "My Rating Code";
  if (qrCode.data_format === "video") return "My Video";
  if (qrCode.data_format === "event") return "My Event";
  if (qrCode.data_format === "pdf") return "My PDF Code";
  // if (qrCode.data_format === 'facebook') return 'My Facebook Code'
  if (qrCode.data_format === "app") return "My App";
  // if (qrCode.data_format === 'mp3') return 'My MP3 Code'
  if (qrCode.data_format === "images") return "My Gallery";
  if (qrCode.data_format === "instagram") return "My Instagram Code";
  if (qrCode.data_format === "menu") return "My Menu Code";
};
