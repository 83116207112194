import { GET_MANAGERS_NAMES } from "@/services/backoffice/manager";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeManagerNames = (users) => {
  return users.map((u) => {
    const id = u.id;
    const email = u.email;
    const firstName = u.own_account?.contacts?.[0]?.first_name;
    const lastName = u.own_account?.contacts?.[0]?.last_name;
    const company = u.own_account?.contacts?.[0]?.company;
    const fullName = firstName && lastName && `${firstName} ${lastName}`;
    return {
      name: fullName || firstName || lastName || company || email,
      id,
    };
  });
};

const initState = () => ({
  managerNames: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    managerNames: ({ managerNames }) => managerNames,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    getManagersNamesSuccess(state, payload) {
      state.managerNames = payload;
    },
  },
  actions: {
    async getManagersNames({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_MANAGERS_NAMES,
          context: MANAGER_APOLLO_CONTEXT,
        });
        commit("getManagersNamesSuccess", serializeManagerNames(data.users));
      } catch (e) {
        console.log(e);
      }
    },
  },
};
