import AuthService from "@/services/auth";
import cookies from "cookie-universal";
import i18n from "@/plugins/i18n";
// import {defaultClient as apollo} from "@/plugins/apollo/apollo-account";
import { wsLink } from "@/plugins/apollo/apollo-account";
let refreshingPromise = null;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async login({ dispatch }, { credentials, keepSignedIn } = {}) {
      try {
        const { data } = await AuthService.login(credentials);
        await dispatch("setTokens", { ...data, keepSignedIn });
        // await this.$apolloHelpers.onLogin(data.accessToken)
      } catch (e) {
        console.log(e);
        dispatch(
          "notification/showNotification",
          {
            message: i18n.t(e.message || "notifications:error:something_wrong"),
            type: "error",
          },
          { root: true }
        );
        return Promise.reject(e);
      }
    },
    logout({ dispatch }) {
      return Promise.all([dispatch("setTokens"), AuthService.logout()]);
    },
    signup(context, credentials) {
      try {
        return AuthService.signup(credentials);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async refreshToken({ dispatch }) {
      if (refreshingPromise) return refreshingPromise;
      // eslint-disable-next-line no-async-promise-executor
      refreshingPromise = new Promise(async (resolve) => {
        try {
          const refreshToken = cookies().get("refresh-token");
          const res = await AuthService.refreshTokens({ refreshToken });
          await dispatch("setTokens", { ...res.data, keepSignedIn: true });

          refreshingPromise = false;
          resolve(res.data?.accessToken);
          // todo need to refresh add http clients access tokens
        } catch (e) {
          console.log("e", e);
          await dispatch("setTokens");
          const url = new URL("login", process.env.VUE_APP_BASE_URL);
          url.searchParams.set(
            "redirect",
            location.href.replace(process.env.VUE_APP_BASE_URL, "")
          );
          location.href = url;
        }
      });

      return refreshingPromise;
    },
    async resetPassword({ dispatch }, credentials) {
      try {
        await AuthService.resetPassword(credentials);
      } catch (e) {
        dispatch(
          "notification/showNotification",
          {
            message: i18n.t(e.message || "notifications:error:something_wrong"),
            type: "error",
          },
          { root: true }
        );
        return Promise.reject(e);
      }
    },
    async changePassword({ dispatch }, credentials) {
      try {
        await AuthService.changePassword(credentials);
      } catch (e) {
        dispatch(
          "notification/showNotification",
          {
            message: i18n.t(e.message || "notifications:error:something_wrong"),
            type: "error",
          },
          { root: true }
        );
        return Promise.reject(e);
      }
    },
    async setTokens(context, tokens = {}) {
      const c = cookies();
      const options = {
        maxAge: 24 * 60 * 60 * 1000,
        path: "/",
      };
      const { accessToken, refreshToken } = tokens;
      if (!accessToken || !refreshToken) {
        c.remove("access-token");
        c.remove("refresh-token");
        await wsLink?.subscriptionClient?.tryReconnect?.();
        return;
      }

      c.set("access-token", accessToken, options);
      if (tokens.keepSignedIn) c.set("refresh-token", refreshToken, options);
      wsLink?.subscriptionClient?.tryReconnect?.();
    },
    loginWithSocialLink({ dispatch, rootGetters }, network) {
      return new Promise((resolve, reject) => {
        try {
          const authURL = new URL(network, process.env.VUE_APP_AUTH_SERVER_URL);
          authURL.searchParams.set("language", rootGetters["language"]);
          const windowSize = "width=700,height=700";

          window.open(authURL, network, windowSize);
          window.loginCallBack = async (result) => {
            await dispatch("loginWithSocialLinkCallback", result);
            // await this.$apolloHelpers.onLogin(result?.['access-token'])
            resolve(result);
          };
        } catch (error) {
          // todo catch errors in new window from server
          reject(new Error("Oops... Something went wrong!"));
        }
      });
    },
    async loginWithSocialLinkCallback({ dispatch }, result) {
      await dispatch("setTokens", {
        keepSignedIn: true,
        accessToken: result?.["access-token"],
        refreshToken: result?.["refresh-token"],
      });
    },
    loginWithSocialLinkSameWindow({ rootGetters }, network) {
      return new Promise((resolve, reject) => {
        try {
          const url = new URL(network, process.env.VUE_APP_AUTH_SERVER_URL);
          url.searchParams.set("language", rootGetters["language"]);
          window.location.href = url.toString();
        } catch (error) {
          // todo catch errors in new window from server
          reject(new Error("Oops... Something went wrong!"));
        }
      });
    },
  },
};
