import gql from "graphql-tag";

export const GET_TRANSLATIONS = gql`
  query GetTranslations($page: String!, $language: String!) {
    translations(
      where: {
        page_translations: { page: { name: { _eq: $page } } }
        language_code: { _eq: $language }
      }
    ) {
      key
      value
    }
  }
`;
