export const userRolesOptions = [
  { value: "ADMIN", label: "organization_user_role:admin" },
  { value: "EDITOR", label: "organization_user_role:editor" },
  { value: "READ_ONLY", label: "organization_user_role:read_only" },
];

export const ownerRole = {
  value: "OWNER",
  label: "organization_user_role:owner",
};
