import gql from "graphql-tag";

export const GET_API_INFO = gql`
  query getApiKeys {
    api_keys {
      id
      key
      name
    }
  }
`;
export const CREATE_API_KEY = gql`
  mutation ($apiKeyName: String = null) {
    insert_api_keys(objects: { name: $apiKeyName }) {
      affected_rows
    }
  }
`;
export const REMOVE_API_KEY = gql`
  mutation ($key: uuid!) {
    delete_api_keys(where: { key: { _eq: $key } }) {
      affected_rows
    }
  }
`;

export const GET_API_KEY_DETAILS = gql`
  query getApiKeyDetails($apiKeyId: uuid!) {
    api_keys(where: { id: { _eq: $apiKeyId } }) {
      id
      key
      name
      origins {
        id
        origin
      }
    }
  }
`;

export const UPDATE_API_KEY_DETAILS = gql`
  mutation updateApiKeyDetails(
    $apiKeyId: uuid!
    $apiKeyName: String!
    $origins: [api_key_origins_insert_input!]!
  ) {
    update_api_keys(
      where: { id: { _eq: $apiKeyId } }
      _set: { name: $apiKeyName }
    ) {
      affected_rows
    }
    delete_api_key_origins(where: { api_key_id: { _eq: $apiKeyId } }) {
      affected_rows
    }
    insert_api_key_origins(objects: $origins) {
      affected_rows
    }
  }
`;
