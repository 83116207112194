export const COMPANY_NAME_INPUT_ID = "company-name-input";

export const getDefaultAccountContact = () => ({
  first_name: "",
  last_name: "",
  company: "",
  country: null,
  website: "",
  street: "",
  phone: "",
  city: "",
  zip: "",
});
