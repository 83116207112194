import gql from "graphql-tag";

export const GET_QR_CODE_DESIGN = gql`
  query getQrCodeDesign($key: String!) {
    qr_code_designs(where: { qr_code: { key: { _eq: $key } } }) {
      id
      background_color
      foreground_color
      foreground_color_2

      is_gradient
      gradient_angle
      is_radial_gradient

      #      patterns
      version

      # frame
      frame_name
      frame_text
      frame_color
      frame_svg
      frame_color_2
      frame_font

      # marker
      custom_marker_color
      marker_left_inner_color
      marker_right_inner_color
      marker_bottom_inner_color
      marker_left_outer_color
      marker_right_outer_color
      marker_bottom_outer_color
      marker_left_template
      marker_right_template
      marker_bottom_template
      #      marker_left_svg
      #      marker_right_svg
      #      marker_bottom_svg

      # images
      #      image_url
      #      background_image_url
      image_key
      background_image_key

      # shapes
      #      shapes

      # logo
      #      logo_name
      logo_type
      #      logo_url
      logo_key

      qr_code {
        id
        content
        data_format
        preview_id
        status
        type
        key
      }
    }
  }
`;

export const UPDATE_QR_CODE_KEY = gql`
  mutation updateQrCodeKey($id: uuid!, $key: String!) {
    update_qr_codes(where: { id: { _eq: $id } }, _set: { key: $key }) {
      affected_rows
    }
  }
`;

export const UPDATE_QR_CODE_DESIGN = gql`
  mutation updateQrCodeDesign(
    $id: uuid!
    $oldPreviewId: uuid = null
    $qrCodePreviewId: uuid!
    $newDesign: qr_code_designs_set_input!
  ) {
    remove_old_preview: update_files(
      where: { id: { _eq: $oldPreviewId } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    update_qr_codes(
      where: { design_id: { _eq: $id } }
      _set: { preview_id: $qrCodePreviewId }
    ) {
      affected_rows
    }
    update_qr_code_designs(where: { id: { _eq: $id } }, _set: $newDesign) {
      affected_rows
    }
  }
`;
