import {
  GET_TICKETS_LIST,
  GET_TICKET_DETAILS,
  GET_TICKET_REPLIES,
  DELETE_OLD_TICKET_REPLIES,
  SAVE_TICKET,
  DELETE_TICKETS,
  ASSIGN_TICKETS,
  REPLY_TICKETS,
  sendReplyTicketEmails,
} from "@/services/backoffice/helpdesk";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import { v4 as uuidv4 } from "uuid";
// import { uploadHTMLImages } from "@/utils/files";
import { GET_FILES_BY_KEYS } from "@/services/backoffice/files";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

// todo add order by column
const serializeTickets = (getters, filters) => {
  const where = {};
  const limit = getters["limit"];
  const offset = getters["offset"];

  const { ticket, email, status, type, assigned_to_id } = filters;

  if (email) where.email = { _ilike: `%${email}%` };
  if (ticket) where.ticket = { _eq: ticket };
  if (status) where.status = { _eq: status };
  if (type) where.type = { _eq: type };
  if (assigned_to_id === "not assigned")
    where.assigned_to_id = { _is_null: true };
  else if (assigned_to_id) where.assigned_to_id = { _eq: assigned_to_id };

  return {
    where,
    limit,
    offset,
  };
};

const serializeReplyObjects = async (rootGetters, dispatch, reply, tickets) => {
  const manager_id = rootGetters["backofficeAccount/account"]?.owner?.id;
  reply.description = reply.editor.root.innerHTML;
  const filesKeys = rootGetters["backofficeFiles/filesKeysToAdd"];

  const { data } = await apollo.query({
    query: GET_FILES_BY_KEYS,
    context: MANAGER_APOLLO_CONTEXT,
    variables: { filesKeys },
  });
  const imageFiles = data.files || [];
  const files = { data: imageFiles.map((f) => ({ file_id: f.id })) };

  return tickets.map((ticket) => ({
    id: uuidv4(),
    created_at: new Date().toISOString(),
    content: reply.description,
    ticket: ticket.ticket,
    type: "response",
    manager_id,
    files,
  }));
};

const serializeTicketToSave = (getters) => ({
  ticket: getters.ticketDetails?.ticket,
  status: getters.ticketDetails?.status,
  assigned_to_id: getters.ticketDetails?.assigned_to_id,
});

export const initState = () => ({
  limit: 50,
  offset: 0,
  loadedAll: false,
  tickets: [],
  ticketsLoading: false,
  ticketsMoreLoading: false,
  ticketsError: null,

  isLoadingTicketDetails: false,
  ticketDetails: null,
  ticketReplies: [],

  selectedTickets: [],
  repliesToDelete: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    limit: ({ limit }) => limit,
    offset: ({ offset }) => offset,
    loadedAll: ({ loadedAll }) => loadedAll,
    tickets: ({ tickets }) => tickets,
    ticketsLoading: ({ ticketsLoading }) => ticketsLoading,
    ticketsMoreLoading: ({ ticketsMoreLoading }) => ticketsMoreLoading,
    ticketsError: ({ ticketsError }) => ticketsError,

    selectedTickets: ({ selectedTickets }) => selectedTickets,
    repliesToDelete: ({ repliesToDelete }) => repliesToDelete,

    ticketDetails: ({ ticketDetails }) => ticketDetails,
    ticketReplies: ({ ticketReplies }) => ticketReplies,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    selectTickets(state, tickets = []) {
      state.selectedTickets = tickets;
    },

    // get tickets list
    getTicketsListStart(state) {
      state.loadedAll = false;
      state.offset = 0;

      state.ticketsLoading = true;
      state.ticketsError = null;
      state.tickets = [];
    },
    getTicketsListSuccess(state, tickets) {
      state.ticketsLoading = false;
      state.tickets = tickets;
    },
    getTicketsListFailure(state, e) {
      state.ticketsLoading = false;
      state.ticketsError = e;
    },
    // get more tickets list
    getMoreTicketsListStart(state) {
      state.ticketsMoreLoading = true;
      state.ticketsError = null;
      state.offset += state.limit;
    },
    getMoreTicketsListSuccess(state, tickets) {
      state.ticketsMoreLoading = false;
      state.tickets = [...state.tickets, ...(tickets ?? [])];
      if (tickets.length < state.limit) state.loadedAll = true;
    },
    getMoreTicketsListFailure(state, e) {
      state.offset = Math.max(0, state.offset - state.limit);
      state.ticketsMoreLoading = false;
      state.ticketsError = e;
    },
    // ticket details
    getTicketDetailsStart(state) {
      state.isLoadingTicketDetails = true;
    },
    getTicketDetailsSuccess(state, payload) {
      state.isLoadingTicketDetails = false;
      state.ticketDetails = payload;
    },
    getTicketDetailsFailure(state) {
      state.isLoadingTicketDetails = false;
      state.ticketDetails = null;
    },
    getTicketReplies(state, ticketReplies) {
      state.ticketReplies = ticketReplies;
    },
    setRepliesToDelete(state, replies = []) {
      state.repliesToDelete = replies;
      if (!state.ticketReplies) return;
      state.ticketReplies = state.ticketReplies.filter(
        (c) => !replies.some((reply) => reply.id === c.id)
      );
    },
    setTicketDetails(state, { prop, value } = {}) {
      if (!prop) return;
      state.ticketDetails[prop] = value;
    },
    deleteSelectedTicketsSuccess(state) {
      state.tickets = state.tickets.filter((d) => {
        return !state.selectedTickets.some((s) => s.ticket === d.ticket);
      });
      state.selectedTickets = [];
    },
    deleteSelectedTicketsFailure(state) {
      state.selectedTickets = [];
    },
    assignSelectedTickets(state, assignToId) {
      state.selectedTickets.forEach((d) => (d.assigned_to_id = assignToId));
    },
    replySelectedTickets(state, replies) {
      if (state.ticketReplies) state.ticketReplies.push(...replies);
    },
  },
  actions: {
    async getTicketsList({ commit, getters }, { filters = {} } = {}) {
      try {
        commit("getTicketsListStart");
        const { data } = await apollo.query({
          query: GET_TICKETS_LIST,
          context: MANAGER_APOLLO_CONTEXT,
          variables: serializeTickets(getters, filters),
        });
        commit("getTicketsListSuccess", data.help_center_tickets);
      } catch (e) {
        console.log(e);
        commit("getTicketsListFailure", e);
      }
    },
    async getMoreTicketsList({ commit, getters }, { filters = {} } = {}) {
      try {
        if (getters["loadedAll"]) return;
        commit("getMoreTicketsListStart");
        const { data } = await apollo.query({
          query: GET_TICKETS_LIST,
          context: MANAGER_APOLLO_CONTEXT,
          variables: serializeTickets(getters, filters),
        });
        commit("getMoreTicketsListSuccess", data.help_center_tickets);
      } catch (e) {
        commit("getMoreTicketsListFailure", e);
      }
    },
    async getTicketDetails({ commit }, variables = {}) {
      commit("getTicketDetailsStart");
      try {
        const { data } = await apollo.query({
          query: GET_TICKET_DETAILS,
          context: MANAGER_APOLLO_CONTEXT,
          variables,
        });
        const ticketDetails = data.help_center_tickets[0];
        if (!ticketDetails) throw Error("The ticket doesn't exist");
        commit("getTicketDetailsSuccess", ticketDetails);
      } catch (result) {
        commit("getTicketDetailsFailure");
        console.log(result);
        return Promise.reject(result);
      }
    },
    async getTicketReplies({ commit }, variables = {}) {
      try {
        const { data } = await apollo.query({
          query: GET_TICKET_REPLIES,
          context: MANAGER_APOLLO_CONTEXT,
          variables,
        });
        const ticketReplies = data.help_center_messages;
        if (!ticketReplies) throw Error("The ticket doesn't exist");
        commit("getTicketReplies", ticketReplies);
      } catch (result) {
        commit("getTicketReplies");
        console.log(result);
        return Promise.reject(result);
      }
    },
    async saveTicket({ getters }) {
      try {
        await apollo.mutate({
          mutation: SAVE_TICKET,
          context: MANAGER_APOLLO_CONTEXT,
          variables: serializeTicketToSave(getters),
        });
      } catch (result) {
        console.log(result);
      }
    },
    async deleteOldTicketReplies({ commit, getters }) {
      try {
        const repliesToDeleteIds = getters.repliesToDelete.map((c) => c.id);

        await apollo.mutate({
          mutation: DELETE_OLD_TICKET_REPLIES,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { repliesToDeleteIds },
        });
        commit("setRepliesToDelete");
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async deleteTicket({ getters }) {
      try {
        await apollo.mutate({
          mutation: DELETE_TICKETS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            tickets: [getters.ticketDetails.ticket],
          },
        });
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async deleteSelectedTickets({ commit, getters }) {
      try {
        const tickets = getters.selectedTickets.map((ticket) => ticket.ticket);
        await apollo.mutate({
          mutation: DELETE_TICKETS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { tickets },
        });
        commit("deleteSelectedTicketsSuccess");
      } catch (result) {
        commit("deleteSelectedTicketsFailure");
        console.log(result);
        throw Error(result.message);
      }
    },
    async assignSelectedTickets({ commit, getters }, assignedToId) {
      try {
        const tickets = getters.selectedTickets.map((ticket) => ticket.ticket);
        await apollo.mutate({
          mutation: ASSIGN_TICKETS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { tickets, assignedToId },
        });
        commit("assignSelectedTickets", assignedToId);
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async replySelectedTickets(
      { rootGetters, commit, dispatch },
      { reply, tickets }
    ) {
      try {
        const replyObjects = await serializeReplyObjects(
          rootGetters,
          dispatch,
          reply,
          tickets
        );

        // save email reply
        await apollo.mutate({
          mutation: REPLY_TICKETS,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { replyObjects },
        });
        // save images
        await dispatch("backofficeFiles/updateFileParams", {}, { root: true });

        // send email
        await sendReplyTicketEmails(replyObjects.map((r) => r.id));
        commit("replySelectedTickets", replyObjects);
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
  },
};
