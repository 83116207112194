import shortid from "shortid";
import moment from "moment/moment";
import {
  businessPageHeaderImages,
  SocMediaHeaderImages,
  vcardHeaderImages,
  couponsHeaderImages,
  eventHeaderImages,
  feedbackHeaderImages,
  ratesHeaderImages,
  menuHeaderImages,
} from "@/constants/page-header-images";

export const getStaticQrContent = () => ({
  URL: { url: "" },
  vcard: {
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    mobilePhone: "",
    fax: "",
    email: "",
    url: "",
  },
  email: {
    email: "",
    subject: "",
    message: "",
  },
  sms: {
    phone: "",
    message: "",
  },
  text: {
    text: "",
  },
  wifi: {
    type: "WPA",
    network: "",
    password: "",
    hidden: false,
  },
});
export const contentLoader = {
  image_key: null,
  background_color: "#ffffff",
  image_width: 50,
};
export const contentAddress = {
  street: "",
  city: "",
  state: "",
  zip: "",
  country: "",
};

// todo loading preview
// todo dialogs
export const getDynamicQrContent = (context) => ({
  URL: { url: "" },
  soc_media: {
    template: "1",
    customizations: {
      primary_color: "#CAD5F3",
      button_color: "#FF6996",
      header_image: {
        image_key: null,
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: SocMediaHeaderImages[0],
    },
    basic: {
      title: context.$t("soc_media:def:headline"),
      text: context.$t("soc_media:def:text"),
    },
    soc_media_channels: [
      // { type: "website", value: "", text: "", id: shortid.generate() },
      { type: "facebook", value: "", text: "", id: shortid.generate() },
      { type: "youtube", value: "", text: "", id: shortid.generate() },
    ],
    loader: contentLoader,
    advanced: {
      has_share_button: true,
    },
  },
  coupons: {
    template: "1",
    customizations: {
      primary_color: "#401941",
      button_color: "#FF6996",
      header_image: {
        // todo low quality (ask to create replace def images)
        image_key: "pages_images/def_coupon.png",
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: couponsHeaderImages[0],
    },
    basic: {
      company: context.$t("coupon:def:company"),
      title: context.$t("coupon:def:headline"),
      text: context.$t("coupon:def:text"),
      sale: context.$t("coupon:def:sale"),
    },
    coupon: {
      name: "SALE50OFF",
      use_barcode: false,
      barcode_image: {
        image_key: null,
        background_color: "#ffffff",
        image_width: 100,
      },
      expired_at: moment().add(1, "day").toDate(),
      terms_and_conditions: "",
      website_url: "https://link-to-qr.com",
      website_name: context.$t("coupon:def:button_name"),
      address: contentAddress,
    },
    loader: contentLoader,
    advanced: {
      has_share_button: true,
    },
  },
  vcard_plus: {
    template: "1",
    customizations: {
      button_color: "#000000",
      header_image: {
        image_key: null,
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: vcardHeaderImages[0],
    },
    vcard: {
      avatar: {
        image_key: "pages_images/def_vcard_avatar.jpg",
        background_color: "#ffffff",
        image_width: 100,
      },
      firstName: context.$t("vcard_plus:def:first_name"),
      lastName: context.$t("vcard_plus:def:last_name"),
      company: "",
      jobTitle: context.$t("vcard_plus:def:job_title"),
      phone: "",
      mobilePhone: context.$t("vcard_plus:def:mobile"),
      fax: "",
      email: context.$t("vcard_plus:def:email"),
      address: contentAddress,
      website: context.$t("vcard_plus:def:website"),
      about: context.$t("vcard_plus:def:about"),
    },
    soc_media_channels: [
      // {type: 'website', value: '', text: '', id: shortid.generate()},
    ],
    loader: contentLoader,
    // advanced: {
    //   has_share_button: true,
    // },
  },
  business_page: {
    template: "1",
    customizations: {
      // primary_color: '#127003',
      button_color: "#127003",
      header_image: {
        image_key: "pages_images/business_page/v1/background-img.jpg",
        // image_key: 'pages_images/def_business_page.png',
        // image_key: 'pages_images/business_page/business_page1.jpg',
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: false,
      header_template_image_key: businessPageHeaderImages[0],
    },
    basic: {
      company: context.$t("business_page:def:company"),
      title: context.$t("business_page:def:title"),
      text: context.$t("business_page:def:text"),
    },
    button: {
      button_text: context.$t("qr_manage:business_page:button_name1"),
      button_url: "",
      has_button: true,
      has_button_file: false,
      button_file_key: null,
    },
    opening_hours: {
      Mon: {
        is_open: true,
        hours: [
          { start: "09:00", end: "14:00" },
          { start: "15:00", end: "18:00" },
        ],
      },
      Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
      Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
      Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
      Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
      Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
      Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
    },
    address: contentAddress,
    features: [],
    contacts: {
      about: "",
      name: "",
      phone: "",
      email: "",
      website: "",
    },
    soc_media_channels: [
      // {type: 'website', value: '', text: '', id: shortid.generate()},
    ],
    loader: contentLoader,
  },
  feedback: {
    template: "1",
    customizations: {
      primary_color: "#BB89FC",
      header_image: {
        image_key: "pages_images/def_feedback.png",
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: feedbackHeaderImages[0],
    },
    basic: {
      name: context.$t("feedback:def:name"),
      title: context.$t("feedback:def:headline"),
      website: "",
      email: "",
    },
    categories: [
      { icon: "circle", value: "", subcategories: [], id: shortid.generate() },
    ],
    loader: contentLoader,
  },
  rating: {
    template: "1",
    customizations: {
      primary_color: "#8433C3",
      button_color: "#FF9A02",
      header_image: {
        image_key: "pages_images/def_rating.png",
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: ratesHeaderImages[0],
    },
    rating: {
      question: context.$t("rating:def:question"),
      type: "star",
    },
    basic: {
      company: context.$t("rating:def:company"),
      website: context.$t("rating:def:website"),
      email: "",
    },
    soc_media_channels: [],
    loader: contentLoader,
  },
  video: {
    template: "1",
    customizations: {
      primary_color: "#772400",
      button_color: "#FF9A02",
    },
    basic: {
      company: context.$t("video:def:company"),
      title: context.$t("video:def:headline"),
      text: context.$t("video:def:text"),
    },
    button: {
      button_text: context.$t("qr_manage:video:button_name1"),
      button_url: "",
      has_button: true,
      has_button_file: false,
      button_file_key: null,
    },
    video: {
      highlight_the_first_video: false,
      videos: [
        // {
        // video_id: '',
        // description: '',
        // preview_url: '',
        // id: shortid.generate()
        // },
        // {
        //   video_id: 'YCGIj7KmwyI',
        //   description: 'Disturbed - Hey You [Official Music Video]',
        //   preview_url: 'https://i.ytimg.com/vi/YCGIj7KmwyI/mqdefault.jpg',
        //   id: 1,
        // },
      ],
    },
    soc_media_channels: [
      // {type: 'website', value: '', text: '', id: shortid.generate()},
    ],
    loader: contentLoader,
    advanced: {
      has_share_button: true,
    },
  },
  event: {
    template: "1",
    customizations: {
      // primary_color: '#0A4701',
      button_color: "#FF9A02",
      header_image: {
        image_key: "pages_images/def_event.jpg",
        background_color: "#ffffff",
        image_width: 100,
      },
      is_used_template_image: true,
      header_template_image_key: eventHeaderImages[0],
    },
    button: {
      button_text: context.$t("qr_manage:event:button_name1"),
      button_url: "",
      has_button: true,
      has_button_file: false,
      button_file_key: null,
    },
    basic: {
      company: "",
      title: context.$t("event:def:headline"),
      text: context.$t("event:def:text"),
      features: [],
      about: "",
      all_day: true,
      start_date: moment(),
      start_time: "00:00",
      end_date: moment(),
      end_time: "00:00",
      time_format: "",
      // todo realize time zone and format
      timezone: null,
      venue_name: "",
      address: contentAddress,
    },
    contacts: {
      name: "",
      phone: "",
      email: "",
      website: "",
    },
    loader: contentLoader,
    // advanced: {
    //   has_share_button: true,
    // },
  },
  pdf: {
    template: "1",
    customizations: {
      primary_color: "#083800",
      button_color: "#FF8F0F",
    },
    pdf: {
      direct_link: false,
      pdf_key: null,
      preview_image: {
        image_key: "pages_images/def_pdf.png",
      },
    },
    basic: {
      company: "",
      title: "",
      text: "",
      website: "",
      button_text: context.$t("pdf:def:button_name"),
    },
    loader: contentLoader,
  },
  // facebook: {
  // template: '1',
  //   customizations: {
  //     primary_color: '#0E0065',
  //     button_color: '#82B1FF',
  //     header_image: {
  //       image_key: 'pages_images/def_facebook.png',
  //       background_color: '#ffffff',
  //       image_width: 100,
  //     },
  //   },
  //   basic: {
  //     facebook_url: '',
  //     name: '',
  //     title: 'Click on the Like button to follow us on Facebook',
  //     website: '',
  //   },
  //   loader: contentLoader,
  // },
  app: {
    template: "1",
    customizations: {
      primary_color: "#BB89FC",
    },
    basic: {
      title: "",
      text: "",
      website: "",
    },
    button: {
      button_text: context.$t("qr_manage:app:button_name1"),
      button_url: "",
      has_button: true,
      has_button_file: false,
      button_file_key: null,
    },
    app: {
      name: "",
      developer: "",
      app_image: {
        image_key: "pages_images/def_app.png",
      },
    },
    app_links: {
      app_store: { url: "" },
      google_play: { url: "" },
    },
    loader: contentLoader,
  },
  // mp3: {
  // template: '1',
  //   customizations: {
  //     primary_color: '#BB89FC',
  //     button_color: '#BB89FC',
  //     header_image: {
  //       image_key: 'pages_images/def_mp3.png',
  //       background_color: '#ffffff',
  //       image_width: 100,
  //     },
  //   },
  //   basic: {
  //     title: 'Absorption',
  //     text: 'Jane Cooper',
  //     website: '',
  //   },
  // button: {
  //   button_text: 'Buy now',
  //   button_url: '',
  //   has_button: true,
  //   has_button_file: false,
  //   button_file_key: null,
  // },
  //   mp3: {
  //     mp3_url: '',
  //     can_download: true,
  //   },
  //   loader: contentLoader,
  // },
  images: {
    template: "1",
    customizations: {
      primary_color: "#3C261F",
    },
    basic: {
      title: "",
      text: "",
      website: "",
    },
    button: {
      button_text: context.$t("qr_manage:images:button_name1"),
      button_url: "",
      has_button: true,
      has_button_file: false,
      button_file_key: null,
    },
    images: {
      shown_in_grid: true,
    },
    images_links: [
      // { id: shortid.generate(), caption: 'caption1', key: 'pages_images/def_app.png' },
    ],
    loader: contentLoader,
  },
  instagram: { username: "" },
  menu: {
    template: "1",
    customizations: {
      button_color: "#FF9A02",
      header_image: {
        image_key: "pages_images/def_menu.png",
      },
      is_used_template_image: true,
      header_template_image_key: menuHeaderImages[0],
    },
    basic: {
      title: "",
      text: "",
      website: "",
    },
    categories: [
      {
        id: shortid.generate(),
        visible: true,
        title: context.$t("menu:def:breakfast"),
        all_day: false,
        start_time: "07:00",
        end_time: "11:00",
        all_week: true,
        week_days: [],
        pdf_key: null,
      },
      {
        id: shortid.generate(),
        visible: true,
        title: context.$t("menu:def:lunch"),
        all_day: false,
        start_time: "12:00",
        end_time: "17:00",
        all_week: true,
        week_days: [],
        pdf_key: null,
      },
      {
        id: shortid.generate(),
        visible: true,
        title: context.$t("menu:def:dinner"),
        all_day: false,
        start_time: "18:00",
        end_time: "23:00",
        all_week: true,
        week_days: [],
        pdf_key: null,
      },
    ],
    soc_media_channels: [],
    loader: contentLoader,
  },
});

export const templateRefs = {
  soc_media: "socialMediaTemplates",
  coupons: "couponTemplates",
  vcard_plus: "vCardTemplates",
  business_page: "businessPageTemplates",
  feedback: "feedbackTemplates",
  rating: "ratingTemplates",
  video: "videoTemplates",
  event: "eventTemplates",
  pdf: "pdfTemplates",
  app: "appTemplates",
  images: "imagesTemplates",
  menu: "menuTemplates",
};

export const dynamicTemplates = (context) => ({
  URL: { url: "" },
  soc_media: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#CAD5F3",
          header_image: {
            image_key: "pages_images/social-media/v1/background-img.jpg",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: SocMediaHeaderImages[0],
        },
        basic: {
          title: "Connect with us on Social Media",
          text: "Follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          {
            type: "facebook",
            value: "",
            text: "Facebook",
            id: shortid.generate(),
          },
          {
            type: "youtube",
            value: "",
            text: "Youtube",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#010313",
          text_color: "#fff301",
          // button_color: '#FF6996',
          header_image: {
            image_key: null,
            image_width: 90,
          },
          is_used_template_image: null,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          title: "connect with us",
          text: "Follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          {
            type: "instagram",
            value: "",
            text: "Instagram",
            id: shortid.generate(),
          },
          {
            type: "telegram",
            value: "",
            text: "Telegram",
            id: shortid.generate(),
          },
          {
            type: "youtube",
            value: "",
            text: "Youtube",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#E2EBDE",
          text_color: "#335029",
          header_image: {
            image_key: null,
            image_width: 90,
          },
          is_used_template_image: null,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          title: "connect with us.",
          text: "follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          {
            type: "instagram",
            value: "",
            text: "Instagram",
            id: shortid.generate(),
          },
          {
            type: "telegram",
            value: "",
            text: "Telegram",
            id: shortid.generate(),
          },
          {
            type: "youtube",
            value: "",
            text: "Youtube",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#F7EFD6",
          text_color: "#463A3B",
          header_image: {
            image_key: null,
            image_width: 90,
          },
          is_used_template_image: null,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          title: "Connect with us on Social Media",
          text: "Follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          {
            type: "instagram",
            value: "",
            text: "Instagram",
            id: shortid.generate(),
          },
          {
            type: "telegram",
            value: "",
            text: "Telegram",
            id: shortid.generate(),
          },
          {
            type: "youtube",
            value: "",
            text: "Youtube",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#4D3E67",
          text_color: "#fff",
          header_image: {
            image_key: null,
            image_width: 90,
          },
          is_used_template_image: null,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          title: "Connect with us",
          text: "Follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          {
            type: "instagram",
            value: "",
            text: "Instagram",
            id: shortid.generate(),
          },
          {
            type: "telegram",
            value: "",
            text: "Telegram",
            id: shortid.generate(),
          },
          {
            type: "youtube",
            value: "",
            text: "Youtube",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#ffffff",
          text_color: "#000000",
          header_image: {
            image_key: "pages_images/menu/template_menu_4.png",
            background_color: "#ffffff",
            image_width: 90,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          title: "Connect with us on social media",
          text: "Follow us and get updates delivered to your favorite social networks",
        },
        soc_media_channels: [
          { type: "tiktok", value: "", text: "Tiktok", id: shortid.generate() },
          {
            type: "telegram",
            value: "",
            text: "Telegram",
            id: shortid.generate(),
          },
          {
            type: "instagram",
            value: "",
            text: "Instagram",
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
    ],
  },
  coupons: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#401941",
          button_color: "#FF6996",
          // button_color: '#66ff66',
          header_image: {
            image_key: "pages_images/coupons/coupons3.jpg",
            background_color: "#aaffaa",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% Off",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#FFDC27",
          button_color: "#DCC2FF",
          // button_color: '#66ff66',
          header_image: {
            image_key: null,
            background_color: "#aaffaa",
            image_width: 70,
          },
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% Off",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#FF9A02",
          text_color: "#ffffff",
          // button_color: '#66ff66',
          header_image: {
            image_key: "pages_images/coupons/v3/background-img.jpg",
            background_color: "#000200",
            image_width: 70,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/coupons/coupons3.jpg",
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% OFF",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#FF8E0E",
          button_color: "#f6f6f6",
          text_color: "#ffffff",
          // button_color: '#66ff66',
          header_image: {
            image_key: null,
            background_color: "#aaffaa",
            image_width: 70,
          },
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% OFF",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#363636",
          button_color: "#FFDC27",
          // button_color: '#66ff66',
          text_color: "#ffffff",
          secondary_color: "#FFDC27",
          header_image: {
            image_key: null,
            background_color: "#aaffaa",
            image_width: 70,
          },
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% OFF",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          background_color: "#434343",
          primary_color: "#EA4956",
          button_color: "#B12934",
          text_color: "#ffffff",
          secondary_color: "#B12934",
          // button_color: '#66ff66',
          header_image: {
            image_key: null,
            background_color: "#aaffaa",
            image_width: 70,
          },
        },
        basic: {
          company: "Mary`s shop",
          title: "Black Friday",
          text: "Only the best discounts of up to 50% on absolutely all products for you this Black Friday, take your friends and give yourself the best day, hurry up and go shopping.",
          sale: "50% OFF",
        },
        coupon: {
          name: "SALE50OFF",
          use_barcode: false,
          barcode_image: {
            image_key: "pages_images/coupons/default_barcode.png",
            background_color: "#ffffff",
            image_width: 70,
          },
          expired_at: new Date(),
          terms_and_conditions:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad fuga id, ipsa molestias nam natus perferendis quibusdam. Adipisci, at distinctio facilis hic magni maxime mollitia, perspiciatis placeat possimus quia repellendus.",
          website_url: "https://link-to-qr.com",
          website_name: "Show now",
          address: contentAddress,
        },
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
    ],
  },
  feedback: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#BB89FC",
          header_image: {
            image_key: "pages_images/feedback/v1/background-img.jpg",
            background_color: "#000000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/feedback/feedback1.jpg",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "Hotel Blange",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "gym",
            value: "Gym",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "bar",
            value: "Bar",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#BB89FC",
          header_image: {
            image_key: "pages_images/feedback/v2/background-img.jpg",
            background_color: "#000000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/feedback/feedback1.jpg",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "Hotel Blange",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "gym",
            value: "Gym",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "bar",
            value: "Bar",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#DB627D",
          star_color: "#f6c503",
          header_image: {
            image_key: "pages_images/feedback/v3/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/feedback/feedback1.jpg",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "Hotel Blange",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "gym",
            value: "Gym",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "bar",
            value: "Bar",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          star_color: "#f6c503",
          header_image: {
            image_key: "pages_images/feedback/v4/background-img.jpg",
            background_color: "#000000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/feedback/feedback1.jpg",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "Hotel Blange",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "gym",
            value: "Gym",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "bar",
            value: "Bar",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#282536",
          star_color: "#f6c503",
          header_image: {
            image_key: null,
            background_color: "#ffffff",
            image_width: 100,
          },
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "Hotel Blange",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "gym",
            value: "Gym",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "bar",
            value: "Bar",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#010313",
          secondary_color: "#EDC7FD",
          star_color: "#FFF301",
          header_image: {
            image_key: null,
            background_color: "#ffffff",
            image_width: 100,
          },
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          name: "meri tattoo",
          title: "Give us your feedback",
          text: "Please select the category you want to leave feedback in",
          second_step_title: "Please evaluate using the stars",
          second_step_placeholder: "Add a comment...",
          save_button_text: "Save and rate more",
          send_button_text: "Send feedback",
          gratitude_title: "Thank you!",
          third_step_title: "Your feedback has been submitted",
          third_step_text:
            "Thank you for taking the time to share your opinion",
          // popup_title: 'popup-title',
          website: "google.com",
        },
        categories: [
          {
            icon: "restaurant",
            value: "Restaurant",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "room",
            value: "Room",
            subcategories: [],
            id: shortid.generate(),
          },
          {
            icon: "spa",
            value: "Spa",
            subcategories: [],
            id: shortid.generate(),
          },
        ],
        loader: contentLoader,
      },
    ],
  },
  rating: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#8433C3",
          button_color: "#FF9A02",
          header_image: {
            image_key: "pages_images/rating/v1/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: ratesHeaderImages[0],
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#F7C558",
          button_color: "#FF9A02",
          star_color: "#8433C3",
          header_image: {
            image_key: null,
            background_color: "#F7C558",
            image_width: 100,
          },
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#FF1744",
          button_color: "#C2002D",
          header_image: {
            image_key: "pages_images/rating/v3/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: ratesHeaderImages[0],
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#C8B39C",
          header_image: {
            image_key: "pages_images/rating/v4/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: ratesHeaderImages[0],
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#010313",
          secondary_color: "#EDC7FD",
          star_color: "#FFF301",
          header_image: {
            image_key: null,
            background_color: "#F7C558",
            image_width: 100,
          },
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#010313",
          secondary_color: "#80CE00",
          star_color: "#FFF301",
          header_image: {
            image_key: null,
            background_color: "#F7C558",
            image_width: 100,
          },
        },
        rating: {
          question: "How would you rate your time in our hotel?",
          placeholder: "Add a comment...",
          send_button_text: "Send rating",
          gratitude_title: "Thank you!",
          title: "Your feedback has been submitted",
          text: "Thank you for taking the time to share your opinion",
          type: "star",
        },
        dialog_basic: {
          title:
            "Please enter your contact information if you want us to get back to you.",
          email_placeholder: "Email",
          phone_placeholder: "Phone",
          send_button: "Send now",
        },
        basic: {
          company: "Hotel Blange",
          website: "www.hotelblange.com",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
      },
    ],
  },
  event: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#0A4701',
          button_color: "#FF9A02",
          header_image: {
            image_key: "pages_images/event/v1/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: eventHeaderImages[3],
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: true,
        },
        basic: {
          company: "company",
          title: "Personal growth training",
          text: "By attending our training you will become more confident, learn to be leaders and lead people",
          button_text: "Get tickets",
          button_url: "https://gogole.com",
          has_button: true,
          features: ["wifi", "cocktails", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#0A4701',
          button_color: "#101A20",
          header_image: {
            image_key: "pages_images/event/v2/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: eventHeaderImages[2],
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        basic: {
          company: "company",
          title: "Personal growth training",
          text: "By attending our training you will become more confident, learn to be leaders and lead people",
          button_text: "Get tickets",
          button_url: "https://gogole.com",
          has_button: true,
          features: ["wifi", "cocktails", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#FADDBF",
          button_color: "#393A3F",
          header_image: {
            image_key: null,
            background_color: "#ffffff",
            image_width: 100,
          },
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        basic: {
          company: "company",
          title: "Personal growth training",
          text: "By attending our training you will become more confident, learn to be leaders and lead people",
          button_text: "Get tickets",
          button_url: "https://gogole.com",
          has_button: true,
          features: ["wifi", "cocktails", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#9B64FE",
          text_color: "#F69462",
          header_image: {
            image_key: "pages_images/event/v4/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: eventHeaderImages[1],
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        basic: {
          company: "company",
          title: "Personal growth training",
          text: "By attending our training you will become more confident, learn to be leaders and lead people",
          button_text: "Get tickets",
          button_url: "https://gogole.com",
          has_button: true,
          features: ["wifi", "food", "hot_drinks", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#ffffff",
          text_color: "#E8EBF4",
          header_image: {
            image_key: "pages_images/event/v5/background-img.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: eventHeaderImages[3],
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        basic: {
          company: "company",
          title: "POOL PARTY",
          text: "Clean pool, sun, delicious cocktails and loud music - it's all about our pool party",
          button_text: "Get tickets",
          button_url: "https://google.com",
          has_button: true,
          features: ["wifi", "food", "cocktails", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#DE544C",
          header_image: {
            image_key: "pages_images/event/v6/background-img.jpg",
            background_color: "#030000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: eventHeaderImages[3],
        },
        button: {
          button_text: "Get Tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        basic: {
          company: "company",
          title: "Dance festival",
          text: "We have gathered the best dancers from all over the world in order for you to experience unforgettable emotions and sensations. Enjoy bright performances and take part in our competitions. It's an event you won't want to miss!",
          button_text: "Get tickets",
          button_url: "https://gogole.com",
          has_button: true,
          features: ["wifi", "food", "cocktails", "parking"],
          about: "about about, about about about.",
          all_day: false,
          start_date: new Date(),
          start_time: "03:30",
          multiple_days: false,
          end_date: new Date(),
          end_time: "04:30",
          time_format: "",
          timezone: null,
          venue_name: "venue name",
          address: contentAddress,
        },
        contacts: {
          name: "contact name",
          phone: "0 (000) 000-0000",
          email: "some@email.com",
          website: "example.com",
        },
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
    ],
  },
  vcard_plus: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#000000",
          header_image: {
            image_key: "pages_images/vcard/v1/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: true,
          },
          is_used_template_image: false,
          header_template_image_key: vcardHeaderImages[1],
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v1/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: context.$t("vcard_plus:def:first_name"),
          lastName: "Howard",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_text: "Save contact",
          button_color: "#000000",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: true,
          },
          is_used_template_image: false,
          header_template_image_key: vcardHeaderImages[1],
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v2/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Esther",
          lastName: "Howard",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_text: "Save contact",
          button_color: "#145509",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/coupons/coupons2.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v3/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Esther",
          lastName: "Howard",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#6A0A5B",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/coupons/coupons2.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v4/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Esther",
          lastName: "Howard",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#BB89FC",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/menu/menu1.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v5/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Darlene",
          lastName: "Robertson",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#6A0A5B",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/menu/menu1.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v6/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Darlene",
          lastName: "Robertson",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "7",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#FFC6D7",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/menu/menu1.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v7/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Kathryn",
          lastName: "Murphy",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
      {
        id: "8",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#FF9494",
          header_image: {
            image_key: "pages_images/vcard/v2/header-img.jpg",
            background_color: "#e3ffb6",
            image_width: 100,
            is_header_image: false,
          },
          is_used_template_image: false,
          header_template_image_key: "pages_images/menu/menu1.jpg",
        },
        vcard: {
          avatar: {
            image_key: "pages_images/vcard/v8/avatar.jpg",
            background_color: "#ffffff",
            image_width: 100,
          },
          firstName: "Kathryn",
          lastName: "Murphy",
          company: "",
          jobTitle: "UI/UX designer",
          phone: "",
          mobilePhone: "(603) 555-0123",
          fax: "",
          email: "easterhowarduiux@gmail.com",
          address: contentAddress,
          website: "",
          about:
            "Experience of more than three years, I will make you the best visual",
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        // advanced: {
        //   has_share_button: true,
        // },
      },
    ],
  },
  business_page: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#F299B4",
          header_image: {
            image_key: "pages_images/bussines-page/v1/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#000000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[0],
        },
        basic: {
          company: "Curly Chris",
          title: "Salon for curly hair",
          text: "It's time to put your curls in order, and our sorceress fairies will help you with this",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#F299B4",
          header_image: {
            image_key: "pages_images/bussines-page/v2/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#000000",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[4],
        },
        basic: {
          company: "Curly Chris",
          title: "Salon for curly hair",
          text: "It's time to put your curls in order, and our sorceress fairies will help you with this",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#000000",
          text_color: "#000000",
          secondary_color: "#818181",
          header_image: {
            image_key: "pages_images/bussines-page/v3/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[2],
        },
        basic: {
          company: "Smile House",
          title: "Salon for curly hair",
          text: "Only modern and high-quality equipment and the best specialists who will make your smile incredible",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#F65774",
          text_color: "#F65774",
          secondary_color: "#F65774",
          header_image: {
            image_key: "pages_images/bussines-page/v4/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[3],
        },
        basic: {
          company: "MANICURE HOUSE",
          title: "Salon for curly hair",
          text: "Only modern and high-quality equipment and the best specialists who will make your smile incredible",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#A45615",
          text_color: "#FFF",
          secondary_color: "#DDD",
          background_color: "#1A1A1A",
          header_image: {
            image_key: "pages_images/bussines-page/v5/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[4],
        },
        basic: {
          company: "The best burgers",
          title: "Salon for curly hair",
          text: "Only modern and high-quality equipment and the best specialists who will make your smile incredible",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          // primary_color: '#127003',
          button_color: "#e3e3e3",
          text_color: "#FFF",
          secondary_color: "#DDD",
          header_image: {
            image_key: "pages_images/bussines-page/v6/background-img.jpg",
            // image_key: 'pages_images/def_business_page.png',
            // image_key: 'pages_images/business_page/business_page1.jpg',
            background_color: "#ffffff",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[5],
        },
        basic: {
          company: "Warm bread",
          title: "Salon for curly hair",
          text: "Only modern and high-quality equipment and the best specialists who will make your smile incredible",
        },
        button: {
          button_text: context.$t("qr_manage:business_page:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        opening_hours: {
          Mon: {
            is_open: true,
            hours: [
              { start: "09:00", end: "14:00" },
              { start: "15:00", end: "18:00" },
            ],
          },
          Tue: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Wen: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Thu: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Fri: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sat: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
          Sun: { is_open: false, hours: [{ start: "09:00", end: "18:00" }] },
        },
        address: contentAddress,
        features: [],
        contacts: {
          about: "",
          name: "",
          phone: "",
          email: "",
          website: "",
        },
        soc_media_channels: [
          // {type: 'website', value: '', text: '', id: shortid.generate()},
        ],
        loader: contentLoader,
      },
    ],
  },
  pdf: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#083800",
          button_color: "#FFDC27",
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/def_pdf.png",
            background_color: "transparent",
          },
        },
        basic: {
          company: "company",
          title: "This summer's bestseller",
          text: "A book that will tell you a life story. A story that will touch you to the core and motivate you to work on yourself",
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#000000",
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/pdf/v2/background-img.jpg",
            background_color: "transparent",
          },
        },
        basic: {
          company: "company",
          title: "Secrets of space",
          text: "Inside, you'll find fascinating information about unknown planets, stars, black holes, asteroids, and other objects in the universe that still remain a mystery to scientists. This file is intended for anyone who is interested in the cosmos and wants to learn more about its mysteries.",
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#000000",
          button_color: "#ffffff",
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/pdf/v3/background-img.jpg",
            background_color: "transparent",
            image_width: 100,
          },
        },
        basic: {
          company: "Tattoo care rules",
          title: "From Inkline Tattoo Studio",
          text: "This file contains the rules that should be followed before, as well as after the session for better healing of your tattoo",
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#6257FF",
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/pdf/v4/background-img.jpg",
            background_color: "transparent",
            image_width: 100,
          },
        },
        basic: {
          company: "Anatomy Exam \n" + "Preparation",
          title: "Anatomy Exam Preparation",
          text:
            "Anatomy Exam \n" +
            "Preparation contains a large amount of theoretical material, articles on anatomy, detailed images and diagrams that will help you in preparing for the exam.",
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#ffffff",
          text_color: "#000000",
          header_image: {
            image_key: "pages_images/menu/template_menu_4.png",
            background_color: "transparent",
            image_width: 100,
          },
          is_used_template_image: false,
          header_template_image_key: businessPageHeaderImages[5],
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/pdf/v5/background-img.jpg",
            background_color: "transparent",
            image_width: 100,
          },
        },
        basic: {
          company: "Guide to cooking macaroons",
          title: "From Marie Gilbert",
          text:
            "In this guide, you'll find step-by-step instructions for making dough and fillings, as well as recommendations for choosing ingredients and preparing tools. In addition, the guide also contains useful tips and tricks to help you achieve perfect results.\n" +
            "\n" +
            'The PDF file "Guide to cooking macaroons" can be useful for both novice cooks and experienced professionals who want to improve their skills in cooking macaroons.',
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          text_color: "#F69462",
          button_color: "#9B64FE",
        },
        pdf: {
          direct_link: false,
          pdf_key: "pages/test-pdf.pdf",
          preview_image: {
            image_key: "pages_images/pdf/v6/background-img.jpg",
            background_color: "transparent",
            image_width: 100,
          },
        },
        basic: {
          company: "Step-by-step drawings",
          title: "Detailed instructions for pictures",
          text: 'Can\'t draw? Not a problem! With our step-by-step guide, you or your child will be able to learn to draw in one evening, you will only need a piece of paper, a pencil and our "Step-by-step drawings"',
          website: "google.com",
          button_text: "View PDF",
        },
        loader: contentLoader,
      },
    ],
  },
  images: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#3C261F",
        },
        basic: {
          title: "New collection of knitted clothes",
          text: "Hurry up and pre-order the sweaters from our new collection",
          website: "google.com",
        },
        button: {
          button_text: "Shop Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: true,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v1/image1.jpg",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v1/image2.jpg",
          },
          {
            id: 3,
            caption: "caption3",
            key: "pages_images/images/v1/image3.jpg",
          },
          {
            id: 4,
            caption: "caption4",
            key: "pages_images/images/v1/image4.jpg",
          },
          {
            id: 5,
            caption: "caption5",
            key: "pages_images/images/v1/image5.jpg",
          },
          {
            id: 6,
            caption: "caption6",
            key: "pages_images/images/v1/image6.jpg",
          },
        ],
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#056068",
        },
        basic: {
          title: "Ceramic Artisan",
          text: "Welcome to Ceramic Artisan — a handmade shop where every piece of ceramic tableware is unique and lovingly created. Our craftsmen use only high-quality materials to create functional and beautiful tableware for your home.",
          website: "google.com",
        },
        button: {
          button_text: "Shop Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: true,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v2/image1.jpg",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v2/image2.jpg",
          },
          {
            id: 3,
            caption: "caption3",
            key: "pages_images/images/v2/image3.jpg",
          },
          {
            id: 4,
            caption: "caption4",
            key: "pages_images/images/v2/image4.jpg",
          },
          {
            id: 5,
            caption: "caption5",
            key: "pages_images/images/v2/image5.jpg",
          },
          {
            id: 6,
            caption: "caption6",
            key: "pages_images/images/v2/image6.jpg",
          },
        ],
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#000000",
          button_color: "#ffffff",
        },
        basic: {
          title: "inkline tattoo studio",
          text: "Introducing our catalog of free thumbnails, browse and choose your",
          website: "google.com",
        },
        button: {
          button_text: "I want a tattoo",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: false,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v3/image1.png",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v3/image2.png",
          },
          {
            id: 3,
            caption: "caption3",
            key: "pages_images/images/v3/image3.png",
          },
        ],
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#72F6E9",
        },
        basic: {
          title: "3D Mastermind School",
          text: "Do you want to learn the same way?",
          website: "google.com",
        },
        button: {
          button_text: "Get started",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: true,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v4/image1.png",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v4/image2.png",
          },
          {
            id: 3,
            caption: "caption3",
            key: "pages_images/images/v4/image3.png",
          },
          {
            id: 4,
            caption: "caption4",
            key: "pages_images/images/v4/image4.png",
          },
          {
            id: 5,
            caption: "caption5",
            key: "pages_images/images/v4/image5.png",
          },
          {
            id: 6,
            caption: "caption6",
            key: "pages_images/images/v4/image6.png",
          },
          {
            id: 7,
            caption: "caption7",
            key: "pages_images/images/v4/image7.png",
          },
          {
            id: 8,
            caption: "caption8",
            key: "pages_images/images/v4/image8.png",
          },
          {
            id: 9,
            caption: "caption9",
            key: "pages_images/images/v4/image9.png",
          },
        ],
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#F7EFD6",
          button_color: "#463A3B",
        },
        basic: {
          title: "Playtopia",
          text: '"Playtopia" is a unique toy store that offers a wide selection of educational games and toys for children of all ages. Here you will find everything from classic board games to the latest innovations in the world of toys. The Playtopia store is designed to allow children to enjoy the game and develop their skills and imagination.',
          website: "google.com",
        },
        button: {
          button_text: "Shop Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: false,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v5/image1.png",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v5/image2.png",
          },
        ],
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#E2EBDE",
          button_color: "#335029",
        },
        basic: {
          title: "green haven.",
          text: '"green haven" is a houseplant store where you will find a large selection of green plants that will help you transform your home into a beautiful corner of nature. Here you will find many different types of plants - from miniature shrubs to large ficus and palm trees',
          website: "google.com",
        },
        button: {
          button_text: "go to the store",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        images: {
          shown_in_grid: false,
        },
        images_links: [
          {
            id: 1,
            caption: "caption1",
            key: "pages_images/images/v6/image1.png",
          },
          {
            id: 2,
            caption: "caption2",
            key: "pages_images/images/v6/image2.png",
          },
          {
            id: 3,
            caption: "caption3",
            key: "pages_images/images/v6/image3.png",
          },
        ],
        loader: contentLoader,
      },
    ],
  },
  video: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#772400",
          button_color: "#FFDC27",
        },
        basic: {
          company: "Juicy burgers",
          title: "The aesthetics of our burgers",
          text: "Enjoy the process of making juicy burgers and their aesthetics",
        },
        button: {
          button_text: "Order Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: false,
          videos: [
            // {
            // video_id: '',
            // description: '',
            // preview_url: '',
            // id: shortid.generate()
            // }
            {
              video_id: "dA0VGEbbw4g",
              description: "Food Film | Cinematic Burger",
              preview_url: "https://i.ytimg.com/vi/dA0VGEbbw4g/mqdefault.jpg",
              id: 1,
            },
            {
              video_id: "V5w1OGknhlc",
              description: "The Heavy Hitting Burger - Promo Video",
              preview_url: "https://i.ytimg.com/vi/V5w1OGknhlc/mqdefault.jpg",
              id: 2,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#000000",
        },
        basic: {
          company: "Juicy burgers",
          title: "The aesthetics of our burgers",
          text: "Enjoy the process of making juicy burgers and their aesthetics",
        },
        button: {
          button_text: "Order Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: false,
          videos: [
            {
              video_id: "dA0VGEbbw4g",
              description: "Food Film | Cinematic Burger",
              preview_url: "https://i.ytimg.com/vi/dA0VGEbbw4g/mqdefault.jpg",
              id: 1,
            },
            {
              video_id: "V5w1OGknhlc",
              description: "The Heavy Hitting Burger - Promo Video",
              preview_url: "https://i.ytimg.com/vi/V5w1OGknhlc/mqdefault.jpg",
              id: 2,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#000000",
        },
        basic: {
          company: "",
          title: "White Tiger",
          text: "The video covers topics such as the origin of this subspecies, their behavior in the wild, the modern threats that white tigers face, and measures to preserve their numbers in nature.",
        },
        button: {
          button_text: "Order Now",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: true,
          videos: [
            {
              video_id: "dOMAT8fOr0Q",
              description: "Tigers 101 | Nat Geo Wild",
              preview_url: "https://i.ytimg.com/vi/dOMAT8fOr0Q/mqdefault.jpg",
              id: 1,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#000000",
          button_color: "#EDC7FD",
          text_color: "#FFF301",
        },
        basic: {
          company: "Meri tattoo",
          title: "The clearest lines from our masters",
          text: "Welcome to Meri Tattoo - your best choice for high-quality and reliable tattoos. In our video, we will show you our tattoo parlor and tell you about our services, our talented masters, as well as show examples of our works.",
        },
        button: {
          button_text: "I want a tattoo",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: true,
          videos: [
            {
              video_id: "oyn3rTo_gVs",
              description: "Aesthetic tattoos | Small aesthetic tattoos",
              preview_url: "https://i.ytimg.com/vi/oyn3rTo_gVs/mqdefault.jpg",
              id: 1,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#DE544C",
        },
        basic: {
          company: "Danse festival",
          title: "The result of the dance festival",
          text: "This is a fascinating report from an event where dance groups from different parts of the world gathered to showcase their talents and skills. The video shows the unique atmosphere of the festival, bright costumes, energetic movements and unforgettable performances",
        },
        button: {
          button_text: "View results table",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: true,
          videos: [
            {
              video_id: "HjBXLCjvE0Q",
              description:
                "THE ROYAL FAMILY - Nationals 2018 (Guest Performance)",
              preview_url: "https://i.ytimg.com/vi/HjBXLCjvE0Q/mqdefault.jpg",
              id: 1,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#9B64FE",
        },
        basic: {
          company: "Children's show",
          title:
            "We invite you to a children's master class on making figurines from balloons",
          text: "This is a fascinating report from an event where dance groups from different parts of the world gathered to showcase their talents and skills. The video shows the unique atmosphere of the festival, bright costumes, energetic movements and unforgettable performances",
        },
        button: {
          button_text: "Get tickets",
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        video: {
          highlight_the_first_video: false,
          videos: [
            {
              video_id: "dmDTQe0h7Sk",
              description: "How to Make a Balloon Flower",
              preview_url: "https://i.ytimg.com/vi/dmDTQe0h7Sk/mqdefault.jpg",
              id: 1,
            },
          ],
        },
        soc_media_channels: [
          { type: "instagram", value: "", text: "", id: shortid.generate() },
          { type: "telegram", value: "", text: "", id: shortid.generate() },
          { type: "youtube", value: "", text: "", id: shortid.generate() },
          { type: "facebook", value: "", text: "", id: shortid.generate() },
        ],
        loader: contentLoader,
        advanced: {
          has_share_button: true,
        },
      },
    ],
  },
  menu: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          header_image: {
            image_key: "pages_images/menu/menu1.jpg",
          },
          primary_color: "#BB89FC",
          is_used_template_image: false,
          header_template_image_key: menuHeaderImages[0],
        },
        basic: {
          title: "Maria's Vegetarian Restaurant",
          text: "Good food and caring for animals",
          description: "Only the most delicious vegan food",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          // color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
      {
        id: "2",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          header_image: {
            image_key: "pages_images/coupons/coupons3.jpg",
          },
          primary_color: "#BB89FC",
          is_used_template_image: true,
          header_template_image_key: menuHeaderImages[0],
        },
        basic: {
          title: "Maria's Vegetarian Restaurant",
          text: "Good food and caring for animals",
          description: "Only the most delicious vegan food",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          // color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
      {
        id: "3",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          template_basic_color: "#00AE11",
          primary_color: "#BB89FC",
          background_color: "#ffffff",
        },
        basic: {
          title: "Maria's Vegetarian Restaurant",
          text: "Good food and caring for animals",
          description:
            "Welcome to Maria's restaurant - the perfect place for vegetarians and everyone who loves delicious and healthy food! Our kitchen offers a wide range of dishes, exclusively from natural ingredients, without the use of meat and fish. Try our fresh salads, delicious pizzas and pasta, our soups and side dishes will not leave you indifferent either. Enjoy your taste and health at Maria's Vegetarian Restaurant!",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#ffffff",
            is_all_background_color: false,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#00AE11",
            is_all_background_color: false,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#ffffff",
            is_all_background_color: false,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
      {
        id: "4",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          button_color: "#FF9A02",
          header_image: {
            image_key: "pages_images/menu/template_menu_4.png",
            background_color: "#ffffff",
            image_width: 100,
          },
          primary_color: "#BB89FC",
          is_used_template_image: false,
          header_template_image_key: menuHeaderImages[0],
        },
        basic: {
          title: "Sweet House",
          text: "The best goodies in your city",
          description:
            "Welcome to sweet house confectionery! We are ready to please you with the most delicious sweets that have been created with love and care.\n" +
            "\n" +
            "Here you will find a large selection of cakes, pastries, biscuits and other confectionery, each of which is freshly prepared and original to taste. We use only the finest ingredients to provide our customers with high quality products.",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#ffffff",
            is_all_background_color: false,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#ffffff",
            is_all_background_color: false,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#ffffff",
            is_all_background_color: false,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
      {
        id: "5",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          header_image: {
            image_key: "pages_images/menu/template_menu_5.png",
            background_color: "#000000",
            image_width: 100,
          },
          primary_color: "#BB89FC",
        },
        basic: {
          title: "Sea breeze",
          text: "Seafood restaurant on the waterfront",
          description:
            "Welcome to sea breeze restaurant – an oasis of fresh seafood in the heart of the city!\n" +
            "\n" +
            "Our menu is rich in a variety of dishes prepared in accordance with the highest quality standards and care for every detail. Enjoy an exquisite range of fresh seafood such as oysters, mussels, shrimps, langoustines, as well as a variety of dishes that emphasize their unique taste.",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#dddddd",
            is_all_background_color: false,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#dddddd",
            is_all_background_color: false,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
            background_color: "#dddddd",
            is_all_background_color: false,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
      {
        id: "6",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          template_basic_color: "#57527E",
          primary_color: "#E58923",
        },
        basic: {
          title: "Sea breeze",
          text: "Seafood restaurant on the waterfront",
          description:
            "Welcome to sea breeze restaurant – an oasis of fresh seafood in the heart of the city!\n" +
            "\n" +
            "Our menu is rich in a variety of dishes prepared in accordance with the highest quality standards and care for every detail. Enjoy an exquisite range of fresh seafood such as oysters, mussels, shrimps, langoustines, as well as a variety of dishes that emphasize their unique taste.",
          website: "google.com",
        },
        categories_options: {
          id_color_category: 1,
          is_color_all_categories: false,
          // color_all_categories: "#000000",
        },
        categories: [
          {
            id: 1,
            visible: true,
            title: "Breakfast",
            all_day: false,
            start_time: "07:00",
            end_time: "11:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 2,
            visible: true,
            title: "Lunch",
            all_day: false,
            start_time: "12:00",
            end_time: "17:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
          {
            id: 3,
            visible: true,
            title: "Dinner",
            all_day: false,
            start_time: "18:00",
            end_time: "23:00",
            all_week: true,
            week_days: [],
            pdf_key: null,
          },
        ],
        soc_media_channels: [],
        loader: contentLoader,
      },
    ],
  },
  app: {
    template: "1",
    templates: [
      {
        id: "1",
        logo_image: {
          image_key: "project_files/generator/default_company_logo.jpeg",
          visible_logo: true,
        },
        customizations: {
          primary_color: "#BB89FC",
        },
        basic: {
          title: "",
          text: "",
          website: "",
        },
        button: {
          button_text: context.$t("qr_manage:app:button_name1"),
          button_url: "",
          has_button: true,
          has_button_file: false,
          button_file_key: null,
        },
        app: {
          name: "",
          developer: "",
          app_image: {
            image_key: "pages_images/def_app.png",
          },
        },
        app_links: {
          app_store: { url: "" },
          google_play: { url: "" },
        },
        loader: contentLoader,
      },
    ],
  },
  instagram: { username: "" },
});

export const proTemplates = () => ({
  soc_media: ["2", "3", "4", "5", "6"],
  coupons: ["2", "3", "4", "5", "6"],
  feedback: ["2", "3", "4", "5", "6"],
  rating: ["2", "3", "4", "5", "6"],
  event: ["2", "3", "4", "5", "6"],
  vcard_plus: ["2", "3", "4", "5", "6", "7", "8"],
  business_page: ["2", "3", "4", "5", "6"],
  pdf: ["2", "3", "4", "5", "6"],
  images: ["2", "3", "4", "5", "6"],
  video: ["2", "3", "4", "5", "6"],
  menu: ["2", "3", "4", "5", "6"],
  app: [],
});
