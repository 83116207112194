import {
  GET_QR_CODE_DETAILS,
  UPDATE_QR_CAMPAIGN_INFO,
  RESET_QR_CODE_SCANS,
  GET_QR_CODE_SCANS,
} from "@/services/qrCodeDetails";
import moment from "moment";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeScansFilter = (getters) => {
  return {
    created_at: {
      _gte: getters.filters.start,
      _lte: getters.filters.end,
    },
  };
};

const initState = () => ({
  qrCode: null,
  loading: false,
  errors: false,
  filters: {
    start: moment().subtract(14, "days").format("MM-DD-YYYY"),
    end: moment().add(1, "day").format("MM-DD-YYYY"),
    periodType: "day",
  },
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    qrCode: ({ qrCode }) => qrCode,
    loading: ({ loading }) => loading,
    errors: ({ errors }) => errors,
    filters: ({ filters }) => filters,
    allScans: ({ qrCode }) => qrCode?.all_scans?.aggregate?.count ?? 0,
    uniqueScans: ({ qrCode }) => qrCode?.unique_scans?.aggregate?.count ?? 0,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    setFilters(state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
    setQrCodeParams(state, { prop, value }) {
      if (!prop) return;
      state.qrCode[prop] = value;
    },
    getQrCodeDetailsStart(state) {
      state.loading = true;
      state.error = null;
      state.qrCode = null;
    },
    getQrCodeDetailsSuccess(state, qrCode) {
      state.loading = false;
      state.qrCode = qrCode;
    },
    getQrCodeDetailsFailure(state, e) {
      state.loading = false;
      state.error = e;
      state.qrCode = null;
    },
    updateQrCampaignInfo(state, qrCode) {
      Object.assign(state.qrCode, qrCode);
    },
    resetQrCodeScans(state) {
      Object.assign(state.qrCode, {
        unique_scans: { aggregate: { count: 0 } },
        all_scans: { aggregate: { count: 0 } },
        scans: [],
      });
    },
    getQrCodeScans(state, scans) {
      state.qrCode.scans = scans ?? [];
    },
  },
  actions: {
    async getQrCodeDetails({ commit, getters }, key) {
      try {
        commit("getQrCodeDetailsStart");
        const { data } = await apollo.query({
          query: GET_QR_CODE_DETAILS,
          variables: { key, scansFilter: serializeScansFilter(getters) },
        });

        const qrCode = data.qr_codes?.[0];
        if (!qrCode) throw Error("Qr code is not found");
        if (qrCode.type === "static") throw Error("Qr code is not found");

        commit("getQrCodeDetailsSuccess", qrCode);
      } catch (e) {
        commit("getQrCodeDetailsFailure", e);
        console.log(e);
        return Promise.reject(e);
      }
    },
    async updateQrCampaignInfo({ commit }, variables) {
      try {
        await apollo.mutate({
          mutation: UPDATE_QR_CAMPAIGN_INFO,
          variables,
        });

        commit("updateQrCampaignInfo", variables);
      } catch (e) {
        console.log(e);
        return Promise.reject("Whoops... Something went wrong.");
      }
    },
    async resetQrCodeScans({ commit, getters }, variables) {
      try {
        const qrCodeId = getters["qrCode"]?.id;
        if (!qrCodeId) throw Error();

        await apollo.mutate({
          mutation: RESET_QR_CODE_SCANS,
          variables: { qrCodeId },
        });

        commit("resetQrCodeScans", variables);
      } catch (e) {
        console.log(e);
        return Promise.reject("notifications:error:something_wrong");
      }
    },
    async getQrCodeScans({ commit, getters }) {
      try {
        const { data } = await apollo.query({
          query: GET_QR_CODE_SCANS,
          variables: {
            scansFilter: {
              ...serializeScansFilter(getters),
              qr_code: { key: { _eq: getters.qrCode?.key } },
            },
          },
        });

        const scans = data.qr_code_scans;

        commit("getQrCodeScans", scans);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
