import gql from "graphql-tag";

export const GET_NEWS = gql`
  query getNews($accountId: uuid!, $limit: Int = 10, $offset: Int = 0) {
    news(
      where: {
        _or: [{ account_id: { _eq: $accountId } }, { is_public: { _eq: true } }]
      }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      content
      link_title
      link_url
      is_public
      viewed_at
    }
  }
`;

export const MARK_AS_VIEWED_NEWS = gql`
  mutation markAsViewedNews {
    update_news(where: {}, _set: { viewed_at: now }) {
      affected_rows
    }
  }
`;
