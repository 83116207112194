export default {
  namespaced: true,
  state: {
    timeoutId: null,
    isShown: false,
    type: null,
    message: "",
  },
  getters: {
    type: ({ type }) => type,
    message: ({ message }) => message,
    isShown: ({ isShown }) => isShown,
  },
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    setVisibility(state, isShown) {
      state.isShown = isShown;
    },
    setType(state, type) {
      state.type = type;
    },
    setTimeoutId(state, timeoutId) {
      state.timeoutId = timeoutId;
    },
  },
  actions: {
    hideNotification({ commit }) {
      commit("setVisibility", false);
      setTimeout(() => {
        commit("setMessage", "");
      }, 500);
    },
    showNotification({ commit, getters }, { type = null, message = "" } = {}) {
      if (getters.isShown) {
        commit("setVisibility", false);
        if (getters.timeoutId) clearTimeout(getters.timeoutId);
        const timeoutId = setTimeout(() => {
          commit("setMessage", message);
          commit("setType", type);
          commit("setVisibility", true);
        }, 1000);
        commit("setTimeoutId", timeoutId);
      } else {
        commit("setMessage", message);
        commit("setType", type);
        commit("setVisibility", true);
      }
    },
  },
};
