import gql from "graphql-tag";
import axios from "@/plugins/axios";

export const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations(
      where: { is_default: { _neq: true } }
      order_by: { created_at: desc }
    ) {
      id
      short_id
      name
      organization_limits {
        vcards_limit
        qr_codes_limit
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization(
    $accountId: uuid!
    $name: String!
    $qr_codes_limit: Int = 0
    $vcards_limit: Int = 0
  ) {
    insert_organizations(
      objects: {
        account_id: $accountId
        name: $name
        organization_limits: {
          data: { vcards_limit: $vcards_limit, qr_codes_limit: $qr_codes_limit }
        }
      }
    ) {
      affected_rows
      returning {
        id
        short_id
        name
        organization_limits {
          vcards_limit
          qr_codes_limit
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: uuid!
    $name: String!
    $qr_codes_limit: Int = 0
    $vcards_limit: Int = 0
  ) {
    update_organization_limits(
      where: { organization_id: { _eq: $id } }
      _set: { qr_codes_limit: $qr_codes_limit, vcards_limit: $vcards_limit }
    ) {
      affected_rows
    }
    update_organizations(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
      returning {
        id
        short_id
        name
        organization_limits {
          vcards_limit
          qr_codes_limit
        }
      }
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($organizationId: uuid!) {
    remove_organization_users: delete_users(
      where: {
        organizations_roles: { organization_id: { _eq: $organizationId } }
      }
    ) {
      affected_rows
    }
    delete_with_qr_codes: update_files(
      where: { qr_code_id: { _eq: $organizationId } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    delete_qr_code_scans(
      where: { qr_code: { organization_id: { _eq: $organizationId } } }
    ) {
      affected_rows
    }
    delete_qr_code_designs(
      where: { qr_code: { organization_id: { _eq: $organizationId } } }
    ) {
      affected_rows
    }
    delete_qr_codes(where: { organization_id: { _eq: $organizationId } }) {
      affected_rows
    }
    delete_organization_limits(
      where: { organization_id: { _eq: $organizationId } }
    ) {
      affected_rows
    }
    delete_organizations_roles_users(
      where: { organization_id: { _eq: $organizationId } }
    ) {
      affected_rows
    }
    delete_organizations(where: { id: { _eq: $organizationId } }) {
      affected_rows
    }
  }
`;

export const GET_ORGANIZATION_USERS = gql`
  query getOrganizationUsers($shortOrganizationId: Int!) {
    accounts {
      owner {
        id
        email
      }
    }
    organizations_roles_users(
      where: { organization: { short_id: { _eq: $shortOrganizationId } } }
    ) {
      id
      organization {
        id
        name
      }
      role_id
      user {
        id
        email
      }
    }
  }
`;

export const inviteOrganizationUser = (credentials) => {
  return axios.post("/invite-organization-user", credentials);
};
export const setOrganizationUserPassword = (credentials) => {
  return axios.post("/set-organization-user-password", credentials);
};

export const UPDATE_ORGANIZATION_USER = gql`
  mutation updateOrganizationUser($id: uuid!, $roleId: String!) {
    update_organizations_roles_users(
      where: { id: { _eq: $id } }
      _set: { role_id: $roleId }
    ) {
      affected_rows
      returning {
        id
        organization {
          id
          name
        }
        role_id
        user {
          id
          email
        }
      }
    }
  }
`;
export const DELETE_ORGANIZATION_USER = gql`
  mutation deleteOrganizationUser($id: uuid!) {
    delete_users(where: { organizations_roles: { id: { _eq: $id } } }) {
      affected_rows
    }
    delete_organizations_roles_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
