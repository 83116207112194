import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  GET_FOLDERS,
  GET_QR_CODES_LIST,
  RENAME_FOLDER,
  UPDATE_QR_CODE_NAME,
  CHANGE_QR_CODES_STATUS,
  MOVE_QR_CODES_TO_FOLDER,
  REMOVE_QR_CODES,
} from "@/services/manage";
import { v4 as uuidv4 } from "uuid";
import { qrCodesOrders } from "@/constants/manage";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const serializeFolders = (folders) => {
  return {
    allQrCodesCount: folders.all_qr_codes?.aggregate?.count || 0,
    activeQrCodesCount: folders.active_qr_codes?.aggregate?.count || 0,
    pausedQrCodesCount: folders.paused_qr_codes?.aggregate?.count || 0,
    folders: folders.folders.map((f) => ({
      ...f,
      count: f?.qr_codes_aggregate?.aggregate?.count || 0,
    })),
  };
};

const serializeQrCodesListVariables = (rootGetters, getters) => {
  const accountId = rootGetters["account/account"]?.id;
  const { qrCodeNameFilter, folderIdFilter, statusFilter } = getters;

  const where = { account_id: { _eq: accountId } };
  const orderBy = [qrCodesOrders[getters["orderBy"]]?.query ?? {}];
  const limit = getters["limit"];
  const offset = getters["offset"];

  if (qrCodeNameFilter) where.name = { _ilike: `%${qrCodeNameFilter}%` };
  if (folderIdFilter) where.folder_id = { _eq: folderIdFilter };
  if (statusFilter && statusFilter !== "all")
    where.status = { _eq: statusFilter };

  return { where, orderBy, limit, offset };
};

const initState = () => ({
  // folders
  allQrCodesCount: 0,
  activeQrCodesCount: 0,
  pausedQrCodesCount: 0,
  foldersLoading: false,
  foldersError: null,
  folders: [],

  folderCreatingLoading: false,
  folderCreatingError: null,
  folderRenamingLoading: false,
  folderRenamingError: null,
  folderDeletingLoading: false,
  folderDeletingError: null,
  updatingQrCodeError: null,

  // qr codes list
  currentFolderQrCodesCount: 0,
  qrCodes: [],
  loadedAll: false,
  qrCodesLoading: false,
  qrCodesMoreLoading: false,
  qrCodesError: null,
  qrCodeNameFilter: "",
  folderIdFilter: null,
  statusFilter: "all",
  orderBy: "last_created",
  offset: 0,
  limit: 20,

  isOpenSidebar: false,

  selectedQrCodes: [],
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    allQrCodesCount: ({ allQrCodesCount }) => allQrCodesCount,
    activeQrCodesCount: ({ activeQrCodesCount }) => activeQrCodesCount,
    pausedQrCodesCount: ({ pausedQrCodesCount }) => pausedQrCodesCount,
    foldersLoading: ({ foldersLoading }) => foldersLoading,
    foldersError: ({ foldersError }) => foldersError,
    folders: ({ folders }) => folders,
    qrCodes: ({ qrCodes }) => qrCodes,
    loadedAll: ({ loadedAll }) => loadedAll,
    qrCodesLoading: ({ qrCodesLoading }) => qrCodesLoading,
    qrCodesMoreLoading: ({ qrCodesMoreLoading }) => qrCodesMoreLoading,
    qrCodesError: ({ qrCodesError }) => qrCodesError,
    qrCodeNameFilter: ({ qrCodeNameFilter }) => qrCodeNameFilter,
    folderIdFilter: ({ folderIdFilter }) => folderIdFilter,
    statusFilter: ({ statusFilter }) => statusFilter,
    orderBy: ({ orderBy }) => orderBy,
    activeFolder: ({ folderIdFilter, folders }) =>
      folders?.find?.((f) => f.id === folderIdFilter),
    folderCreatingLoading: ({ folderCreatingLoading }) => folderCreatingLoading,
    folderRenamingLoading: ({ folderRenamingLoading }) => folderRenamingLoading,
    isOpenSidebar: ({ isOpenSidebar }) => isOpenSidebar,
    foldersNames: ({ folders }) => folders.map((f) => f.name),
    selectedQrCodes: ({ selectedQrCodes }) => selectedQrCodes,
    currentFolderQrCodesCount: ({ currentFolderQrCodesCount }) =>
      currentFolderQrCodesCount,
    offset: ({ offset }) => offset,
    limit: ({ limit }) => limit,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    updateQrCodeListItem(state, qrCode) {
      const qrCodeIdx = state.qrCodes.findIndex((qr) => qr.id === qrCode.id);
      if (qrCodeIdx === -1) return;
      state.qrCodes.splice(qrCodeIdx, 1, {
        ...state.qrCodes[qrCodeIdx],
        ...qrCode,
      });
    },
    toggleSidebar(state, bool = false) {
      state.isOpenSidebar = bool;
    },
    setSelectedQrCodes(state, list = []) {
      state.selectedQrCodes = list;
    },
    setQrCodeNameFilter(state, name) {
      state.qrCodeNameFilter = name;
    },
    setFolderIdFilter(state, folderId) {
      state.folderIdFilter = folderId;
    },
    setStatusFilter(state, status) {
      console.log(status);
      state.statusFilter = status;
    },
    setOrderBy(state, orderBy) {
      state.orderBy = orderBy;
    },
    // get folders
    getFoldersStart(state) {
      state.foldersLoading = true;
      state.allQrCodesCount = 0;
      state.activeQrCodesCount = 0;
      state.pausedQrCodesCount = 0;
      state.foldersError = null;
      state.folders = [];
    },
    getFoldersSuccess(state, payload) {
      state.foldersLoading = false;
      state.allQrCodesCount = payload.allQrCodesCount;
      state.activeQrCodesCount = payload.activeQrCodesCount;
      state.pausedQrCodesCount = payload.pausedQrCodesCount;
      state.folders = payload.folders;
    },
    getFoldersFailure(state, e) {
      state.foldersLoading = false;
      state.foldersError = e;
    },
    // get qr codes list
    getQrCodesListStart(state) {
      state.loadedAll = false;
      state.offset = 0;

      state.qrCodesLoading = true;
      state.qrCodesError = null;
      state.qrCodes = [];
    },
    getQrCodesListSuccess(state, payload) {
      state.qrCodesLoading = false;
      state.qrCodes = payload.qr_codes;
      state.currentFolderQrCodesCount =
        payload.qr_codes_aggregate?.aggregate?.count ?? 0;
    },
    getQrCodesListFailure(state, e) {
      state.qrCodesLoading = false;
      state.qrCodesError = e;
    },
    // get more qr code list
    getMoreQrCodesListStart(state) {
      state.qrCodesMoreLoading = true;
      state.qrCodesError = null;
      state.offset += state.limit;
    },
    getMoreQrCodesListSuccess(state, qrCodes) {
      state.qrCodesMoreLoading = false;
      state.qrCodes = [...state.qrCodes, ...(qrCodes ?? [])];
      if (qrCodes.length < state.limit) state.loadedAll = true;
    },
    getMoreQrCodesListFailure(state, e) {
      state.offset = Math.max(0, state.offset - state.limit);
      state.qrCodesMoreLoading = false;
      state.qrCodesError = e;
    },
    // create folder
    createFolderStart(state) {
      state.folderCreatingLoading = true;
      state.folderCreatingError = null;
    },
    createFolderSuccess(state, newFolder) {
      state.folderCreatingLoading = false;
      state.folders.push(newFolder);
    },
    createFolderFailure(state, e) {
      state.folderCreatingLoading = false;
      state.folderCreatingError = e;
    },
    // rename folder
    renameFolderStart(state) {
      state.folderRenamingLoading = true;
      state.folderRenamingError = null;
    },
    renameFolderSuccess(state, { id, name }) {
      state.folders.forEach((f) => f.id === id && (f.name = name));
      state.qrCodes.forEach(
        (qr) => qr?.folder?.id === id && (qr.folder.name = name)
      );
      state.folderRenamingLoading = false;
    },
    renameFolderFailure(state, e) {
      state.folderRenamingLoading = false;
      state.folderRenamingError = e;
    },
    // delete folder
    deleteFolderStart(state) {
      state.folderDeletingLoading = true;
      state.folderDeletingError = null;
    },
    deleteFolderSuccess(state, { id }) {
      state.folders = state.folders.filter((f) => f.id !== id);
      state.folderDeletingLoading = false;
    },
    deleteFolderFailure(state, e) {
      state.folderDeletingLoading = false;
      state.folderDeletingError = e;
    },
    selectQrCode(state, qrCode) {
      const qrCodeIdx = state.selectedQrCodes.findIndex(
        (code) => code === qrCode
      );
      if (qrCodeIdx === -1) state.selectedQrCodes.push(qrCode);
      else
        state.selectedQrCodes = state.selectedQrCodes.filter(
          (code) => code !== qrCode
        );
    },
    setQrCodeParams(state, { qrCode, prop, value }) {
      if (!qrCode || !prop) return;
      const qr = state.qrCodes.find((code) => code === qrCode);
      qr[prop] = value;
    },
    updateQrCodeNameStart(state) {
      state.updatingQrCodeError = null;
    },
    updateQrCodeNameFailure(state, e) {
      state.updatingQrCodeError = e;
    },
    moveQrCodesToFolderSuccess(state, { qrCodes, folder }) {
      qrCodes.forEach((qr) => {
        const prevFolder = qr.folder?.id || null;
        const nextFolder = folder?.id || null;
        state.folders.forEach((f) => {
          if (f.id === prevFolder) f.count -= 1;
          if (f.id === nextFolder) f.count += 1;
        });
        qr.folder = folder;
      });

      if (state.folderIdFilter && state.folderIdFilter !== folder?.id) {
        state.qrCodes = state.qrCodes.filter((qr) => !qrCodes.includes(qr));
        state.currentFolderQrCodesCount -= qrCodes.length;
        state.selectedQrCodes = state.selectedQrCodes.filter(
          (qr) => !qrCodes.includes(qr)
        );
      }
    },
  },
  actions: {
    async getFolders({ commit, rootGetters }) {
      try {
        commit("getFoldersStart");
        const accountId = rootGetters["account/account"]?.id;
        const { data } = await apollo.query({
          query: GET_FOLDERS,
          variables: { accountId: accountId },
        });
        commit("getFoldersSuccess", serializeFolders(data));
      } catch (e) {
        commit("getFoldersFailure", e);
      }
    },
    async getQrCodesList({ commit, rootGetters, getters }) {
      try {
        commit("getQrCodesListStart");
        const { data } = await apollo.query({
          query: GET_QR_CODES_LIST,
          variables: serializeQrCodesListVariables(rootGetters, getters),
        });
        commit("getQrCodesListSuccess", data);
      } catch (e) {
        console.log(e);
        commit("getQrCodesListFailure", e);
      }
    },
    async getMoreQrCodesList({ commit, rootGetters, getters }) {
      try {
        if (getters["loadedAll"]) return;
        commit("getMoreQrCodesListStart");
        const { data } = await apollo.query({
          query: GET_QR_CODES_LIST,
          variables: serializeQrCodesListVariables(rootGetters, getters),
        });
        commit("getMoreQrCodesListSuccess", data.qr_codes);
      } catch (e) {
        commit("getMoreQrCodesListFailure", e);
      }
    },
    async createFolder({ commit, rootGetters }, newFolder) {
      try {
        commit("createFolderStart");
        newFolder.accountId = rootGetters["account/account"]?.id;
        newFolder.id = uuidv4();

        await apollo.query({
          query: CREATE_FOLDER,
          variables: newFolder,
        });

        newFolder.count = 0;
        commit("createFolderSuccess", newFolder);
      } catch (e) {
        console.log(e);
        commit("createFolderFailure", e);
      }
    },
    async renameFolder({ commit }, folderToRename) {
      try {
        commit("renameFolderStart");
        await apollo.query({
          query: RENAME_FOLDER,
          variables: folderToRename,
        });

        commit("renameFolderSuccess", folderToRename);
      } catch (e) {
        console.log(e);
        commit("renameFolderFailure", e);
      }
    },
    async deleteFolder({ commit }, folderToDelete) {
      try {
        commit("deleteFolderStart");

        await apollo.mutate({
          mutation: DELETE_FOLDER,
          variables: folderToDelete,
        });

        commit("deleteFolderSuccess", folderToDelete);
      } catch (e) {
        console.log(e);
        commit("deleteFolderFailure", e);
      }
    },
    async updateQrCodeName({ commit }, qrCode) {
      try {
        commit("updateQrCodeNameStart");

        await apollo.mutate({
          mutation: UPDATE_QR_CODE_NAME,
          variables: { id: qrCode.id, name: qrCode.name },
        });
      } catch (e) {
        console.log(e);
        commit("updateQrCodeNameFailure", e);
      }
    },

    async removeQrCodes({ dispatch, commit }, qrCodes) {
      const ids = qrCodes?.map((qr) => qr.id) ?? [];

      await apollo.query({
        query: REMOVE_QR_CODES,
        variables: { ids },
      });

      dispatch("getFolders");
      dispatch("getQrCodesList");
      commit("setSelectedQrCodes");
    },
    async moveQrCodesToFolder(
      { dispatch, commit },
      { qrCodes = [], folder } = {}
    ) {
      const ids = qrCodes?.map((qr) => qr.id) ?? [];

      await apollo.query({
        query: MOVE_QR_CODES_TO_FOLDER,
        variables: { ids, folderId: folder?.id || null },
      });

      dispatch("getFolders");
      dispatch("getQrCodesList");
      commit("setSelectedQrCodes");
    },
    async changeQrCodesStatus({ dispatch, commit }, { qrCodes = [], status }) {
      const ids = qrCodes?.map((qr) => qr.id) ?? [];

      await apollo.query({
        query: CHANGE_QR_CODES_STATUS,
        variables: { ids, status },
      });
      dispatch("getFolders");
      dispatch("getQrCodesList");
      commit("setSelectedQrCodes");
    },
  },
};
