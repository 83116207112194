import gql from "graphql-tag";

export const GET_ORGANIZATIONS = gql`
  fragment org on organizations {
    id
    name
    is_default
    organization_resources {
      available_qr_codes
      available_users
      available_vcards
      used_vcards
      used_qr_codes
      used_users
    }
  }

  query getAccountOrganizations($organizationId: uuid!) {
    organizations_by_pk(id: $organizationId) {
      ...org
      roles_users {
        role_id
        user_id
      }
    }
    organizations {
      ...org
    }
    organization_roles {
      id
      create_qr
      delete_qr
      manage_organization
      update_qr
      read_qr
    }
  }
`;
