export const businessPageHeaderImages = [
  "pages_images/business_page/business_page1.jpg",
  "pages_images/business_page/business_page2.jpg",
  "pages_images/business_page/business_page3.jpg",
  "pages_images/business_page/business_page4.jpg",
  "pages_images/business_page/business_page5.jpg",
  "pages_images/business_page/business_page6.jpg",
  "pages_images/business_page/business_page7.jpg",
];

export const couponsHeaderImages = [
  "pages_images/coupons/coupons1.jpg",
  "pages_images/coupons/coupons2.jpg",
  "pages_images/coupons/coupons3.jpg",
  "pages_images/coupons/coupons4.jpg",
  "pages_images/coupons/coupons5.jpg",
  "pages_images/coupons/coupons6.jpg",
  "pages_images/coupons/coupons7.jpg",
  "pages_images/coupons/coupons8.jpg",
  "pages_images/coupons/coupons9.jpg",
  "pages_images/coupons/coupons10.jpg",
  "pages_images/coupons/coupons11.jpg",
];

export const eventHeaderImages = [
  "pages_images/event/event1.jpg",
  "pages_images/event/event2.jpg",
  "pages_images/event/event3.jpg",
  "pages_images/event/event4.jpg",
  "pages_images/event/event5.jpg",
  "pages_images/event/event6.jpg",
];

export const feedbackHeaderImages = [
  "pages_images/feedback/feedback1.jpg",
  "pages_images/feedback/feedback2.jpg",
  "pages_images/feedback/feedback3.jpg",
  "pages_images/feedback/feedback4.jpg",
  "pages_images/feedback/feedback5.jpg",
  "pages_images/feedback/feedback6.jpg",
  "pages_images/feedback/feedback7.jpg",
  "pages_images/feedback/feedback8.jpg",
  "pages_images/feedback/feedback9.jpg",
  "pages_images/feedback/feedback10.jpg",
  "pages_images/feedback/feedback11.jpg",
];

export const ratesHeaderImages = [
  "pages_images/rates/rates1.jpg",
  "pages_images/rates/rates2.jpg",
  "pages_images/rates/rates3.jpg",
  "pages_images/rates/rates4.jpg",
  "pages_images/rates/rates5.jpg",
  "pages_images/rates/rates6.jpg",
  "pages_images/rates/rates7.jpg",
  "pages_images/rates/rates8.jpg",
  "pages_images/rates/rates9.jpg",
  "pages_images/rates/rates10.jpg",
  "pages_images/rates/rates11.jpg",
];

export const menuHeaderImages = [
  "pages_images/menu/menu1.jpg",
  "pages_images/menu/menu2.jpg",
  "pages_images/menu/menu3.jpg",
  "pages_images/menu/menu4.jpg",
  "pages_images/menu/menu5.jpg",
  "pages_images/menu/menu6.jpg",
];

export const vcardHeaderImages = [
  "pages_images/vcard/vcard1.jpg",
  "pages_images/vcard/vcard2.jpg",
  "pages_images/vcard/vcard3.jpg",
  "pages_images/vcard/vcard4.jpg",
  "pages_images/vcard/vcard5.jpg",
  "pages_images/vcard/vcard6.jpg",
];

export const SocMediaHeaderImages = [
  "pages_images/social_media/social_media1.svg",
  "pages_images/social_media/social_media2.svg",
  "pages_images/social_media/social_media3.svg",
  "pages_images/social_media/social_media4.svg",
  "pages_images/social_media/social_media5.svg",
  "pages_images/social_media/social_media6.svg",
];
