import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import {
  GET_API_INFO,
  CREATE_API_KEY,
  REMOVE_API_KEY,
  GET_API_KEY_DETAILS,
  UPDATE_API_KEY_DETAILS,
} from "@/services/settings/api";

const serializeApiKeyDetailsToUpdate = (apiKeyDetails) => {
  if (!apiKeyDetails) return {};
  console.log(apiKeyDetails.name, apiKeyDetails);
  const origins = apiKeyDetails.origins.map((o) => ({
    ...o,
    api_key_id: apiKeyDetails.id,
  }));
  return {
    apiKeyId: apiKeyDetails.id,
    apiKeyName: apiKeyDetails.name,
    origins,
  };
};

const initState = () => ({
  apiKeysLoading: false,
  apiKeysError: null,
  apiKeys: null,
  apiKeyDetails: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    apiKeysLoading: ({ apiKeysLoading }) => apiKeysLoading,
    apiKeysError: ({ apiKeysError }) => apiKeysError,
    apiKeys: ({ apiKeys }) => apiKeys,
    apiKeyDetails: ({ apiKeyDetails }) => apiKeyDetails,
    apiKeyOrigins: ({ apiKeyDetails }) => apiKeyDetails?.origins ?? [],
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    getApiSettingsStart(state) {
      state.apiKeysLoading = true;
      state.apiKeysError = null;
      state.apiKeys = null;
    },
    getApiSettingsSuccess(state, apiKeys) {
      state.apiKeysLoading = false;
      state.apiKeysError = null;
      state.apiKeys = apiKeys;
    },
    getApiSettingsFailure(state, error) {
      state.apiKeysLoading = false;
      state.apiKeysError = error;
      state.apiKeys = null;
    },
    setApiKeyDetails(state, apiKeyDetails = null) {
      state.apiKeyDetails = apiKeyDetails;
    },
  },
  actions: {
    async getApiSettings({ commit }) {
      try {
        commit("getApiSettingsStart");
        const { data } = await apollo.query({
          query: GET_API_INFO,
        });

        commit("getApiSettingsSuccess", data.api_keys);
      } catch (e) {
        commit("getApiSettingsFailure", e);
      }
    },
    async createApiKey({ commit, dispatch }, { apiKeyName }) {
      try {
        await apollo.mutate({
          mutation: CREATE_API_KEY,
          variables: { apiKeyName },
        });
        return dispatch("getApiSettings");
      } catch (e) {
        commit("getApiSettingsFailure", e);
      }
    },
    async removeApiKey({ commit, dispatch }, key) {
      try {
        await apollo.mutate({
          mutation: REMOVE_API_KEY,
          variables: { key },
        });
        return dispatch("getApiSettings");
      } catch (e) {
        commit("getApiSettingsFailure", e);
      }
    },
    async getApiKeyDetails({ commit }, apiKeyId) {
      try {
        const { data } = await apollo.query({
          query: GET_API_KEY_DETAILS,
          variables: { apiKeyId },
        });

        commit("setApiKeyDetails", data.api_keys[0]);
      } catch (e) {
        console.log(e);
        commit("setApiKeyDetails");
        throw Error(e.message);
      }
    },
    async updateApiKeyOrigin({ commit, dispatch, getters }) {
      try {
        const apiKeyDetails = getters["apiKeyDetails"];
        console.log("apiKeyDetails", apiKeyDetails);
        await apollo.mutate({
          mutation: UPDATE_API_KEY_DETAILS,
          variables: serializeApiKeyDetailsToUpdate(apiKeyDetails),
        });
        return dispatch("getApiKeyDetails", apiKeyDetails.id);
      } catch (e) {
        console.log(e);
        commit("getApiKeyDetails");
        throw Error(e.message);
      }
    },
  },
};
