import {
  qrCodeFrames,
  qrCodeMarkers,
} from "l2qr-generator-api/src/constants/assets";
import { getFileUrl, urlToDataUrl } from "@/utils/files";
import qrCodeVersions from "l2qr-generator-api/src/constants/versions";

export const DEFAULT_IMAGE_KEY = "project_files/generator/def_image-min.png";

export const getFrameSvg = async (frameName) => {
  const frameSvgName = qrCodeFrames.find(
    (f) => f.value === frameName
  )?.template;

  if (!frameSvgName) return null;

  return (
    await import(`l2qr-generator-api/src/assets/img/frames/${frameSvgName}?raw`)
  ).default;
};

export const getMarkersSvg = async (markersName) => {
  const markerSvgName = qrCodeMarkers.find(
    (m) => m.value === markersName
  )?.template;

  if (!markerSvgName) return null;

  return (
    await import(
      `l2qr-generator-api/src/assets/img/markers/${markerSvgName}?raw`
    )
  ).default;
};

export const getQrDesignImageUrl = async (key) => {
  try {
    if (!key) return null;
    return urlToDataUrl(getFileUrl(key));
  } catch (e) {
    return null;
  }
};

export const hasBgImage = (version) => {
  return [qrCodeVersions.IMAGE].includes(version);
};

export const allowedImage = (version) => {
  return qrCodeVersions.IMAGE !== version;
};

export const allowedGradient = (version) => {
  return qrCodeVersions.IMAGE !== version;
};

export const getColorType = (qrCodeDesign) => {
  if (qrCodeDesign.is_gradient) return "gradient";
  if (qrCodeDesign.image_key) return "image";
  return "solid";
};
