import Vue from "vue";
import VueApollo from "vue-apollo";
import { anonymousClient } from "./apollo-anonymous";
import { defaultClient } from "./apollo-account";

Vue.use(VueApollo);

export default new VueApollo({
  defaultClient,
  clients: {
    anonymousClient,
  },
});
