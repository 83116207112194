export const qrCodesOrders = {
  last_created: {
    name: "manage:filters:stats:order:last_created",
    query: { created_at: "desc" },
  },
  name: {
    name: "manage:filters:stats:order:name",
    query: { name: "asc" },
  },
  most_scanned: {
    name: "manage:filters:stats:order:most_scans",
    query: { scans_aggregate: { count: "asc" } },
  },
};

export const qrCodesStatuses = {
  all: {
    name: "manage:filters:stats:all_qr_codes",
  },
  active: {
    name: "manage:filters:stats:active_qr_codes",
  },
  paused: {
    name: "manage:filters:stats:paused_qr_codes",
  },
};
