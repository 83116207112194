import axios from "@/plugins/axios";
import gql from "graphql-tag";
export const copyFiles = (credentials) => {
  return axios.post("/backoffice-copy", credentials);
};
const uploadFile = (formData, onUploadProgress) => {
  const configs = {};
  if (onUploadProgress) configs.onUploadProgress = onUploadProgress;
  return axios.post("/backoffice-upload", formData, configs);
};

const deleteFile = (variables) => {
  return axios.post("/backoffice-delete", variables);
};

export const UPDATE_FILES_PARAMS = gql`
  mutation updateFilesParams(
    $filesKeysToRemove: [String!] = []
    $filesKeysToAdd: [String!] = []
  ) {
    saved_files: update_files(
      where: { key: { _in: $filesKeysToAdd } }
      _set: { unsaved: false }
    ) {
      affected_rows
    }
    unsaved_files: update_files(
      where: { key: { _in: $filesKeysToRemove } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
  }
`;

export const GET_FILES_BY_KEYS = gql`
  query getFilesByKeys($filesKeys: [String!] = []) {
    files(where: { key: { _in: $filesKeys } }) {
      id
      key
    }
  }
`;

export default {
  uploadFile,
  deleteFile,
};
