import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";
import {
  DELETE_ARTICLES,
  GET_ARTICLE_DETAILS,
  GET_ARTICLES_LIST,
  SAVE_ARTICLE,
  GET_TRANSLATED_ARTICLES_BY_PATH,
} from "@/services/backoffice/articles";
import { MANAGER_APOLLO_CONTEXT } from "@/constants/backoffice/apollo-client";
import { GET_FILES_BY_KEYS } from "@/services/backoffice/files";
import { v4 as uuidv4 } from "uuid";
import shortid from "shortid";
import {
  getCopiedArticleImages,
  getTranslatedArticle,
  serializeArticleImages,
  serializeArticleToTranslate,
} from "@/utils/blog-translations";

// todo add order by column
const serializeArticles = (getters, filters) => {
  const where = {};
  const limit = getters["limit"];
  const offset = getters["offset"];

  const { visibility, meta_path, category, author_id, language } = filters;

  if (meta_path) where.meta_path = { _ilike: `%${meta_path}%` };
  if (visibility !== null) where.visibility = { _eq: visibility };
  // if (status) where.status = { _eq: status };
  if (category) where.category = { _eq: category };
  // if (author_id === "not assigned") where.author_id = { _is_null: true };
  // else
  if (author_id) where.author_id = { _eq: author_id };
  if (language) where.meta_language_code = { _eq: language };

  return {
    where,
    limit,
    offset,
  };
};

const serializeArticleDetails = async (filesKeys, articleDetails) => {
  const { data } = await apollo.query({
    query: GET_FILES_BY_KEYS,
    context: MANAGER_APOLLO_CONTEXT,
    variables: { filesKeys },
  });
  const files = data.files || [];
  const article_files = { data: files.map((f) => ({ file_id: f.id })) };

  return {
    id: articleDetails.id || uuidv4(),
    visibility: articleDetails.visibility,
    meta_title: articleDetails.meta_title,
    meta_description: articleDetails.meta_description,
    meta_image_key: articleDetails.meta_image_key,
    meta_language_code: articleDetails.meta_language_code,
    meta_category: articleDetails.meta_category,
    meta_path: articleDetails.meta_path,
    // meta_keywords: articleDetails.meta_keywords,
    card_title: articleDetails.card_title,
    card_description: articleDetails.card_description,
    card_image_key: articleDetails.card_image_key,
    content_header_title: articleDetails.content_header_title,
    content_header_description: articleDetails.content_header_description,
    content_header_background_image_key:
      articleDetails.content_header_background_image_key,
    content_header_button_text: articleDetails.content_header_button_text,
    content_header_button_type: articleDetails.content_header_button_type,
    article_files,
  };
};

const serializeArticleDetailsBlocks = (object, articleDetails) => {
  return articleDetails.blocks?.map((b) => ({
    id: b.id || uuidv4(),
    article_id: object.id,
    anchor_enabled: b.anchor_enabled,
    anchor_value: b.anchor_value,
    anchor_key: b.anchor_key,
    content: b.content,
    type: b.type,
    order: b.order,
    show: b.show,
  }));
};

const serializeDuplicateArticleDetails = (articleDetails) => ({
  id: uuidv4(),
  visibility: articleDetails.visibility,
  meta_title: articleDetails.meta_title,
  meta_description: articleDetails.meta_description,
  meta_image_key: null,
  meta_language_code: articleDetails.meta_language_code,
  meta_category: articleDetails.meta_category,
  meta_path: shortid(),
  // meta_keywords: articleDetails.meta_keywords,
  card_title: articleDetails.card_title,
  card_description: articleDetails.card_description,
  card_image_key: null,
  content_header_title: articleDetails.content_header_title,
  content_header_description: articleDetails.content_header_description,
  content_header_background_image_key: null,
  content_header_button_text: articleDetails.content_header_button_text,
  content_header_button_type: articleDetails.content_header_button_type,
  article_files: { data: [] },
});

const serializeArticleDuplicateDetailsBlocks = (object, articleDetails) => {
  return articleDetails.blocks?.map((b) => ({
    id: uuidv4(),
    article_id: object.id,
    anchor_enabled: b.anchor_enabled,
    anchor_value: b.anchor_value,
    anchor_key: b.anchor_key,
    content: b.type === "images" ? [] : b.content,
    type: b.type,
    order: b.order,
    show: b.show,
  }));
};

const serializeArticleVariablesToSave = async (filesKeys, articleDetails) => {
  const object = await serializeArticleDetails(filesKeys, articleDetails);
  const blocks = serializeArticleDetailsBlocks(object, articleDetails);
  return { blocks, object };
};

const serializeArticleVariablesToDuplicate = (articleDetails) => {
  const object = serializeDuplicateArticleDetails(articleDetails);
  const blocks = serializeArticleDuplicateDetailsBlocks(object, articleDetails);
  return { object, blocks };
};

const initState = () => ({
  limit: 50,
  offset: 0,
  loadedAll: false,
  articles: [],
  articlesLoading: false,
  articlesMoreLoading: false,
  articlesError: null,

  isLoadingArticleDetails: false,
  articleDetails: null,

  selectedArticles: [],
  articleBlocksToDelete: [],

  duplicatedPath: null,

  languagesToTranslate: [],

  translatedArticles: [],
  translatedArticlesLoading: false,
  translatedArticlesError: null,
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    limit: ({ limit }) => limit,
    offset: ({ offset }) => offset,
    loadedAll: ({ loadedAll }) => loadedAll,
    articles: ({ articles }) => articles,
    articlesLoading: ({ articlesLoading }) => articlesLoading,
    articlesMoreLoading: ({ articlesMoreLoading }) => articlesMoreLoading,
    articlesError: ({ articlesError }) => articlesError,
    isLoadingArticleDetails: ({ isLoadingArticleDetails }) =>
      isLoadingArticleDetails,
    articleDetails: ({ articleDetails }) => articleDetails,
    selectedArticles: ({ selectedArticles }) => selectedArticles,
    articleBlocksToDelete: ({ articleBlocksToDelete }) => articleBlocksToDelete,
    duplicatedPath: ({ duplicatedPath }) => duplicatedPath,

    languagesToTranslate: ({ languagesToTranslate }) => languagesToTranslate,
    translatedArticles: ({ translatedArticles }) => translatedArticles,
    translatedArticlesLoading: ({ translatedArticlesLoading }) =>
      translatedArticlesLoading,
    translatedArticlesError: ({ translatedArticlesError }) =>
      translatedArticlesError,
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    selectArticles(state, articles = []) {
      state.selectedArticles = articles;
    },

    // get articles list
    getArticlesListStart(state) {
      state.loadedAll = false;
      state.offset = 0;

      state.articlesLoading = true;
      state.articlesError = null;
      state.articles = [];
    },
    getArticlesListSuccess(state, articles) {
      state.articlesLoading = false;
      state.articles = articles;
    },
    getArticlesListFailure(state, e) {
      state.articlesLoading = false;
      state.articlesError = e;
    },
    // get more articles list
    getMoreArticlesListStart(state) {
      state.articlesMoreLoading = true;
      state.articlesError = null;
      state.offset += state.limit;
    },
    getMoreArticlesListSuccess(state, articles) {
      state.articlesMoreLoading = false;
      state.articles = [...state.articles, ...(articles ?? [])];
      if (articles.length < state.limit) state.loadedAll = true;
    },
    getMoreArticlesListFailure(state, e) {
      state.offset = Math.max(0, state.offset - state.limit);
      state.articlesMoreLoading = false;
      state.articlesError = e;
    },
    // article details
    getArticleDetailsStart(state) {
      state.isLoadingArticleDetails = true;
    },
    getArticleDetailsSuccess(state, payload) {
      state.isLoadingArticleDetails = false;
      state.articleDetails = payload;
    },
    getArticleDetailsFailure(state) {
      state.isLoadingArticleDetails = false;
      state.articleDetails = null;
    },

    // delete selected articles
    deleteSelectedArticlesSuccess(state) {
      state.articles = state.articles.filter((d) => {
        return !state.selectedArticles.some((s) => s.id === d.id);
      });
      state.selectedArticles = [];
    },
    deleteSelectedArticlesFailure(state) {
      state.selectedArticles = [];
    },
    setArticleBlocksToDelete(state, ids = []) {
      state.articleBlocksToDelete = ids;
    },
    setDuplicatedPath(state, ids = []) {
      state.duplicatedPath = ids;
    },
    setLanguagesToTranslate(state, languagesToTranslate = []) {
      state.languagesToTranslate = languagesToTranslate;
    },
    // get translated articles
    getTranslatedArticlesStart(state) {
      state.translatedArticlesLoading = true;
      state.translatedArticlesError = null;
      state.translatedArticles = [];
    },
    getTranslatedArticlesSuccess(state, articles) {
      state.translatedArticlesLoading = false;
      state.translatedArticles = articles;
    },
    getTranslatedArticlesFailure(state, e) {
      state.translatedArticlesLoading = false;
      state.translatedArticlesError = e;
    },
  },
  actions: {
    async getArticlesList({ commit, getters }, { filters = {} } = {}) {
      try {
        commit("getArticlesListStart");
        const { data } = await apollo.query({
          query: GET_ARTICLES_LIST,
          context: MANAGER_APOLLO_CONTEXT,
          variables: serializeArticles(getters, filters),
        });
        commit("getArticlesListSuccess", data.articles);
      } catch (e) {
        console.log(e);
        commit("getArticlesListFailure", e);
      }
    },
    async getMoreArticlesList({ commit, getters }, { filters = {} } = {}) {
      try {
        if (getters["loadedAll"]) return;
        commit("getMoreArticlesListStart");
        const { data } = await apollo.query({
          query: GET_ARTICLES_LIST,
          context: MANAGER_APOLLO_CONTEXT,
          variables: serializeArticles(getters, filters),
        });
        commit("getMoreArticlesListSuccess", data.articles);
      } catch (e) {
        commit("getMoreArticlesListFailure", e);
      }
    },
    async getArticleDetails({ commit }, variables = {}) {
      commit("getArticleDetailsStart");
      try {
        const { data } = await apollo.query({
          query: GET_ARTICLE_DETAILS,
          context: MANAGER_APOLLO_CONTEXT,
          variables,
        });
        const articleDetails = data.articles[0];
        if (!articleDetails) throw Error("The article doesn't exist");
        commit("getArticleDetailsSuccess", articleDetails);
      } catch (result) {
        commit("getArticleDetailsFailure");
        console.log(result);
        return Promise.reject(result);
      }
    },
    async deleteSelectedArticles({ commit, getters }) {
      try {
        const ids = getters.selectedArticles.map((article) => article.id);
        await apollo.mutate({
          mutation: DELETE_ARTICLES,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { ids },
        });
        commit("deleteSelectedArticlesSuccess");
      } catch (result) {
        commit("deleteSelectedArticlesFailure");
        console.log(result);
        throw Error(result.message);
      }
    },
    async deleteArticle(context, { id }) {
      try {
        await apollo.mutate({
          mutation: DELETE_ARTICLES,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            ids: [id],
          },
        });
      } catch (result) {
        console.log(result);
        throw Error(result.message);
      }
    },
    async saveArticle({ rootGetters, dispatch, getters, commit }) {
      try {
        const articleBlocksToDelete = getters["articleBlocksToDelete"];
        const filesKeys = rootGetters["backofficeFiles/filesKeysToAdd"];
        const articleFilesToDelete =
          rootGetters["backofficeFiles/filesKeysToRemove"];

        const articleDetails = getters["articleDetails"];
        const { object, blocks } = await serializeArticleVariablesToSave(
          filesKeys,
          articleDetails
        );

        // save email reply
        const { error } = await apollo.mutate({
          mutation: SAVE_ARTICLE,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            articleFilesToDelete,
            articleBlocksToDelete,
            object,
            blocks,
          },
        });

        if (error) return Promise.reject(error);
        // save images
        commit("setArticleBlocksToDelete");
        await dispatch("backofficeFiles/updateFileParams", {}, { root: true });
      } catch (result) {
        console.log(result);
        return Promise.reject(result);
      }
    },
    async duplicateArticle({ getters, commit }) {
      try {
        const articleDetails = getters["articleDetails"];
        const { object, blocks } =
          serializeArticleVariablesToDuplicate(articleDetails);

        // save email reply
        const { error } = await apollo.mutate({
          mutation: SAVE_ARTICLE,
          context: MANAGER_APOLLO_CONTEXT,
          variables: {
            articleFilesToDelete: [],
            articleBlocksToDelete: [],
            object,
            blocks,
          },
        });

        if (error) return Promise.reject(error);
        // save images
        commit("setDuplicatedPath", object.meta_path);
      } catch (result) {
        console.log(result);
        return Promise.reject(result);
      }
    },

    async getTranslatedArticles({ commit }, { path, languageCode }) {
      try {
        commit("getTranslatedArticlesStart");
        const { data } = await apollo.query({
          query: GET_TRANSLATED_ARTICLES_BY_PATH,
          context: MANAGER_APOLLO_CONTEXT,
          variables: { path, languageCode },
        });
        commit("getTranslatedArticlesSuccess", data.articles);
      } catch (e) {
        console.log(e);
        commit("getTranslatedArticlesFailure", e);
      }
    },
    async createTranslatedArticles(
      { dispatch },
      { articleDetails, languages } // filesKeys articleBlocks
    ) {
      try {
        // serialize to translate
        const dataToTranslate = serializeArticleToTranslate(articleDetails);
        const textsToTranslate = dataToTranslate.map((i) => i.value);

        const filesToCopy = serializeArticleImages(articleDetails);
        const sourceKeys = filesToCopy.map((i) => i.value);

        // translate
        const { translatedTexts } = await dispatch(
          "backofficeInternationalization/translateTexts",
          { texts: textsToTranslate, languages },
          { root: true }
        );

        // articles by languages
        for (const l of languages) {
          // clone files
          const { files: copiedFiles } = await dispatch(
            "backofficeFiles/copyFiles",
            {
              sourceKeys,
              unsaved: true,
            },
            { root: true }
          );

          const copiedFilesKeys = copiedFiles.map((i) => i.key);

          // map translated text
          const translatedData = dataToTranslate.map((i, idx) => ({
            ...i,
            value: translatedTexts[l][idx],
          }));

          const translatedArticle = getTranslatedArticle(
            articleDetails,
            translatedData
          );

          // update images
          const copiedFilesToUpdate = filesToCopy.map((i, idx) => ({
            ...i,
            value: copiedFilesKeys[idx],
          }));
          getCopiedArticleImages(translatedArticle, copiedFilesToUpdate);

          // update unique props
          translatedArticle.id = uuidv4();
          translatedArticle.meta_language_code = l;

          // map variables
          const { object, blocks } = await serializeArticleVariablesToSave(
            copiedFilesKeys,
            translatedArticle
          );

          blocks.forEach((b) => (b.id = uuidv4()));

          const { error } = await apollo.mutate({
            mutation: SAVE_ARTICLE,
            context: MANAGER_APOLLO_CONTEXT,
            variables: {
              articleFilesToDelete: [],
              articleBlocksToDelete: [],
              object,
              blocks,
            },
          });
          if (error) return Promise.reject(error);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
