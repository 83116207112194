import gql from "graphql-tag";

export const createSignature = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.post("/signature", variables);
};

export const createOrder = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.post("/order", variables);
};
export const createOrderLink = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.post("/order-link", variables);
};

export const createCheckoutSession = (variables) => {
  console.log(variables);
  const axios = require("@/plugins/axios").default;
  return axios.post("/subscriptions/create", variables);
};
export const updateSubscription = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.post("/subscriptions/upgrade", variables);
};
export const getUpcomingInvoice = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.get("/subscriptions/upcoming-invoice", {
    params: { priceId: variables.priceId },
  });
};
export const getBillingPortalSession = (variables) => {
  const axios = require("@/plugins/axios").default;
  return axios.post("/billing-portal", variables);
};

export const GET_PLANS = gql`
  query getPlans {
    plans(where: { visibility: { _eq: true } }) {
      id
      label
      prices {
        amount
        currency
        payment_frequency
        stripe_price_id
      }
      dynamic_qr_codes
      dynamic_business_card
      static_qr_codes
      scans
      qr_templates
      multiple_download_formats
      limit_space
      tracking_date
      tracking_device
      tracking_gps
      gdpr
      sso
      qr_design_customization
      page_templates
      knowledge_base
      email_support
      customer_manager
      stripe_product_id
    }
  }
`;

export const GET_ACCOUNT_BILLING_ADDRESS = gql`
  query getAccountBillingAddress {
    account_billing_address {
      email
      company_name
      vat
      country
      city
      zip_code
      address
      first_name
      last_name
    }
  }
`;
