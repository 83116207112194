import gql from "graphql-tag";

export const DELETE_ARTICLES = gql`
  mutation deleteArticles($ids: [uuid!]!) {
    update_files(
      where: { articles_files: { article_id: { _in: $ids } } }
      _set: { unsaved: true }
    ) {
      affected_rows
    }
    delete_article_blocks(where: { article_id: { _in: $ids } }) {
      affected_rows
    }
    delete_articles_files(where: { article_id: { _in: $ids } }) {
      affected_rows
    }
    delete_articles(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
export const GET_ARTICLE_DETAILS = gql`
  query getArticleDetails($id: uuid!) {
    articles(where: { id: { _eq: $id } }) {
      id
      created_at
      author_id
      blocks(order_by: { order: asc }) {
        id
        type
        created_at
        anchor_enabled
        anchor_key
        anchor_value
        content
        order
        show
      }
      visibility
      meta_title
      meta_description
      meta_image_key
      meta_language_code
      meta_category
      meta_path
      # meta_keywords
      card_title
      card_description
      card_image_key
      content_header_title
      content_header_description
      content_header_background_image_key
      content_header_button_text
      content_header_button_type
    }
  }
`;
export const GET_ARTICLES_LIST = gql`
  query getArticlesList(
    $where: articles_bool_exp!
    $limit: Int = 20
    $offset: Int = 0
  ) {
    articles(limit: $limit, offset: $offset, where: $where) {
      id
      meta_path
      author_id
      visibility
      meta_category
      meta_language_code
    }
  }
`;

export const GET_TRANSLATED_ARTICLES_BY_PATH = gql`
  query getTranslatedArticlesByPath($path: String!, $languageCode: String!) {
    articles(
      where: {
        meta_path: { _eq: $path }
        meta_language_code: { _neq: $languageCode }
      }
    ) {
      id
      meta_language_code
      meta_path
      meta_title
    }
  }
`;

export const SAVE_ARTICLE = gql`
  mutation saveArticle(
    $object: articles_insert_input!
    $blocks: [article_blocks_insert_input!]!
    $articleBlocksToDelete: [uuid!]!
    $articleFilesToDelete: [String!]!
  ) {
    insert_articles(
      objects: [$object]
      on_conflict: {
        constraint: articles_pkey
        update_columns: [
          visibility
          meta_title
          meta_description
          meta_image_key
          meta_language_code
          meta_category
          meta_path
          # meta_keywords
          card_title
          card_description
          card_image_key
          content_header_title
          content_header_description
          content_header_background_image_key
          content_header_button_text
          content_header_button_type
        ]
      }
    ) {
      affected_rows
    }
    insert_article_blocks(
      objects: $blocks
      on_conflict: {
        constraint: article_blocks_pkey
        update_columns: [
          anchor_enabled
          anchor_key
          anchor_value
          type
          content
          order
          show
        ]
      }
    ) {
      affected_rows
    }
    delete_article_blocks(where: { id: { _in: $articleBlocksToDelete } }) {
      affected_rows
    }
    delete_articles_files(
      where: { file: { key: { _in: $articleFilesToDelete } } }
    ) {
      affected_rows
    }
  }
`;
