import {
  GET_ACCOUNT_BILLING_ADDRESS,
  GET_PLANS,
  createSignature,
  createOrder,
  createOrderLink,
  createCheckoutSession,
  updateSubscription,
  getUpcomingInvoice,
  getBillingPortalSession,
} from "@/services/payment";
import { plansOrder } from "@/constants/plans";
import { defaultClient as apollo } from "@/plugins/apollo/apollo-account";

const initState = () => ({
  accountBillingAddress: {},
  paymentFrequency: "monthly",
  currency: "EUR",
  userSeats: 3,
  plans: [],
  isPlanUpgrading: false,
  planPrice: 0,
  discount: 0,
  totalPrice: 0,
  formCreditCard: {
    payment_system: "card",
    card_number: "",
    expiry_date: "",
    cvv2: "",
  },
  formBillingAddress: {
    email: "",
    phone: "",
    // notes: '',
    company_name: "",
    vat: "",
    country: "",
    city: "",
    zip_code: "",
    address: "",
    first_name: "",
    last_name: "",
  },
  formRef: null,
  orderDate: null,
  requestedPlanId: null,
  upcomingInvoice: null,
  exchangeRates: { EUR: 1 },
});

export default {
  namespaced: true,
  state: initState(),
  getters: {
    plans: ({ plans }) => plans,
    paymentFrequency: ({ paymentFrequency }) => paymentFrequency,
    currency: ({ currency }) => currency,
    userSeats: ({ userSeats }) => userSeats,
    accountBillingAddress: ({ accountBillingAddress }) => accountBillingAddress,
    isPlanUpgrading: ({ isPlanUpgrading }) => isPlanUpgrading,
    planPrice: ({ planPrice }) => planPrice,
    discount: ({ discount }) => discount,
    totalPrice: ({ totalPrice }) => totalPrice,
    orderDate: ({ orderDate }) => orderDate,
    requestedPlanId: ({ requestedPlanId }) => requestedPlanId,
    formCreditCard: ({ formCreditCard }) => formCreditCard,
    formBillingAddress: ({ formBillingAddress }) => formBillingAddress,
    formRef: ({ formRef }) => formRef,
    upcomingInvoice: ({ upcomingInvoice }) => upcomingInvoice,
    exchangeRates: ({ exchangeRates }) => exchangeRates,
    requestedPlan: ({ plans, requestedPlanId, currency }) => {
      return plans?.find?.(
        (p) =>
          p.id === requestedPlanId &&
          p.prices.some((pr) => pr.currency === currency)
      );
    },
    planLabel: (state, { requestedPlan }) => {
      return requestedPlan?.label;
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, initState());
    },
    getAccountBillingAddress(state, billingAddress) {
      state.accountBillingAddress = billingAddress;
    },
    getPlans(state, plans = []) {
      state.plans = plans.sort(
        (a, b) => plansOrder.indexOf(a.id) - plansOrder.indexOf(b.id)
      );
    },
    setPaymentFrequency(state, paymentFrequency = "yearly") {
      state.paymentFrequency = paymentFrequency;
    },
    setRequestedPlanId(state, requestedPlanId) {
      state.requestedPlanId = requestedPlanId;
    },
    setCurrency(state, currency = "EUR") {
      state.currency = currency;
    },
    setIsPlanUpgrading(state, bool) {
      state.isPlanUpgrading = bool;
    },
    setPlanPrice(state, planPrice) {
      state.planPrice = planPrice;
    },
    setDiscount(state, discount) {
      state.discount = discount;
    },
    setTotalPrice(state, totalPrice) {
      state.totalPrice = totalPrice;
    },
    setOrderDate(state, orderDate) {
      state.orderDate = orderDate;
    },
    updateFormBillingAddress(state, formBillingAddress) {
      Object.assign(state.formBillingAddress, formBillingAddress);
    },
    updateFormCreditCard(state, formCreditCard) {
      Object.assign(state.formCreditCard, formCreditCard);
    },
    initFormRef(state, formRef) {
      state.formRef = formRef;
    },
    setUpcomingInvoice(state, invoice) {
      state.upcomingInvoice = invoice;
    },
    setUserSeats(state, userSeats) {
      state.userSeats = userSeats;
    },
    setExchangeRates(state, exchangeRates) {
      state.exchangeRates = exchangeRates;
    },
  },
  actions: {
    async createOrder(context, variables) {
      try {
        const { data } = await createOrder(variables);
        return data;
      } catch (e) {
        console.log(e);
        return Promise.reject("Oops... Something went wrong.");
      }
    },
    async createOrderLink(context, variables) {
      try {
        const { data } = await createOrderLink(variables);
        console.log(data);
        return data;
      } catch (e) {
        console.log(e);
        return Promise.reject("Oops... Something went wrong.");
      }
    },
    async createSignature(context, variables) {
      try {
        const { data } = await createSignature(variables);
        return data;
      } catch (e) {
        console.log(e);
        return Promise.reject("Oops... Something went wrong.");
      }
    },
    async getAccountBillingAddress({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_ACCOUNT_BILLING_ADDRESS,
        });
        const billingAddress = data?.account_billing_address?.[0] ?? {};
        commit("getAccountBillingAddress", billingAddress);
      } catch (e) {
        console.log(e);
        return Promise.reject("Oops... Something went wrong.");
      }
    },
    async getPlans({ commit }) {
      try {
        const { data } = await apollo.query({
          query: GET_PLANS,
        });
        commit("getPlans", data?.plans);
      } catch (e) {
        console.log(e);
        return Promise.reject("Oops... Something went wrong.");
      }
    },
    getCheckoutSessionUrl(context, variables) {
      return createCheckoutSession(variables);
    },
    getUpdatingSubscriptionUrl(context, variables) {
      return updateSubscription(variables);
    },
    async getUpcomingInvoice({ commit }, priceId) {
      try {
        const { data } = await getUpcomingInvoice({ priceId });
        commit("setUpcomingInvoice", data);
      } catch (e) {
        console.log(e);
      }
    },
    async getBillingPortalSession(context, type) {
      return getBillingPortalSession(type);
    },
  },
};
