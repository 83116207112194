import axios from "@/plugins/axios";

const signup = (credentials) => {
  return axios.post("/register", credentials);
};
const login = (credentials) => {
  return axios.post("/login", credentials);
};
const resetPassword = (credentials) => {
  return axios.post("/reset_password", credentials);
};
const changePassword = (credentials) => {
  return axios.post("/change-password", credentials);
};
const refreshTokens = (credentials) => {
  return axios.post("/refresh_tokens", credentials);
};
const logout = () => {
  return axios.post("/logout");
};

export default {
  signup,
  login,
  logout,
  resetPassword,
  refreshTokens,
  changePassword,
};
