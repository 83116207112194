import gql from "graphql-tag";
import axios from "axios";

export const INSERT_QR_CODE = gql`
  mutation insertQrCode(
    $account_id: uuid!
    $organization_id: uuid = null
    $design_id: uuid!
    $preview_id: uuid = null
    $data_format: String!
    $type: String!
    $name: String!
    $status: String = "temporary"
    $content: jsonb!
    $key: String!
    $id: uuid!
  ) {
    insert_qr_codes(
      objects: {
        id: $id
        data_format: $data_format
        preview_id: $preview_id
        type: $type
        name: $name
        content: $content
        account_id: $account_id
        organization_id: $organization_id
        design_id: $design_id
        key: $key
        status: $status
      }
      on_conflict: {
        constraint: qr_codes_pkey
        update_columns: [content, name, preview_id, status]
      }
    ) {
      affected_rows
    }
    insert_qr_code_designs(
      objects: { id: $design_id, account_id: $account_id }
      on_conflict: { constraint: qr_code_designs_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_QR_CODE = gql`
  mutation updateQrCode(
    $name: String!
    $content: jsonb!
    $id: uuid! # $status: String = "paused"
  ) {
    update_qr_codes(
      where: { id: { _eq: $id } }
      _set: {
        content: $content
        name: $name
        # status: $status
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_QR_CODE_DETAILS = gql`
  query getQrCodeDetails($key: String!) {
    qr_codes(where: { key: { _eq: $key } }) {
      data_format
      id
      key
      content
      preview_id
      design_id
      type
      name
      # status
    }
    files(where: { qr_code: { key: { _eq: $key } } }) {
      key
      size
      original_name
    }
  }
`;

export const GET_ALL_QR_CODE_TYPES = gql`
  query getAllQrCodeTypes {
    qr_codes {
      type
    }
  }
`;
const getYouTubeVideoDetails = (videoId) => {
  const url = new URL("videos", "https://content.googleapis.com/youtube/v3/");
  url.searchParams.set("id", videoId);
  url.searchParams.set("part", "snippet,contentDetails,statistics");
  url.searchParams.set("key", process.env.VUE_APP_GOOGLE_API_KEY);

  return axios.get(url);
};

export default {
  getYouTubeVideoDetails,
};
